// import Constants from '../constants/Constants';


function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;
  
    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true; 
    }
  
    return false;
  }

function validateEmail(elementValue) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailPattern.test(elementValue);
}

function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


export const StringUtils = {
    validateEmail,
    toBase64,
    isHTML
};

// function validateEmail (strEmail) {

//     if (strEmail === null || strEmail.trim() === "") {
//         return false;
//     }

//     if (strEmail.trim().match(Constants.REGEX_EMAIL) === false) {
//         return false;
//     }

//     return true;
// };

export function isNullOrEmpty(input) {
    return input === undefined || input === null || input === '';
}

export function blobXlsxFromBase64String(base64String) {
    const byteArray = Uint8Array.from(
        atob(base64String)
            .split('')
            .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
}

export function blobPdfFromBase64String(base64String) {
    const byteArray = Uint8Array.from(
        atob(base64String)
            .split('')
            .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], {type: 'application/pdf'});
}


export function parseStringToNumber(str) {
    const number = +str;
    return isNaN(number) ? str : number;
}

export const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );