import React from 'react';

class Label extends React.Component {
    render() {
        return (
            <>
                <label style={this.props.style} className={this.props.className}>
                    {this.props.value}
                    {this.props.required ? <span className='errorMessage'>*</span> : null}
                </label>
            </>
        );
    }
}

export default Label;