import { Utils } from '../common/utils';
import {GET_ALL_IHRP, GET_ALL_IHRP_BY_NODE, GET_ALL_IHRP_ORG, TEMPLATE_GET_ATTRIBUTE, GET_IHRP_BY_CODE_AND_LEVEL} from '../../constants/AppPath';

class IhrpOrgService{
    getAllIhrp(payload){
        return Utils.post(GET_ALL_IHRP,payload)
    }

    getAllIhrpByNode(payload) {
        return Utils.post(GET_ALL_IHRP_BY_NODE, payload)
    }

    getCompanyList (payload) {
        return Utils.post(TEMPLATE_GET_ATTRIBUTE , payload , null , false)
    }

    getAllIhrpOrg(payload){
        return Utils.get(GET_ALL_IHRP_ORG, payload)
    }

    getAllIhrpByNodeAndLevel(payload) {
        return Utils.get(GET_IHRP_BY_CODE_AND_LEVEL, payload, null, true)
    }
}

export default IhrpOrgService;
