import { Select } from "antd";
import React, { Component } from 'react';
import { convertValue } from "./RenderSelectItem";

const {Option} = Select;

class SelectOption extends Component {
    static defaultProps = {
        mode: "single",
        showSearch: true,
        options: [],
        nullItem: true,
        header: true,
        keyField: "code",
        codeField: "code",
        labelField: "name",
        renderOption: ["code", "name"]
    }

    constructor(props) {
        super(props);
        this.state = {width: 25};
    }

    componentDidMount() {
        if (this.props.renderOption.length > 2) {
            this.setState({width: parseFloat(100 / this.props.renderOption.length)});
        }
    }

    filterSelectOption = (input, event) => {
        return String(event.key).toLowerCase().indexOf(input.toLowerCase()) >= 0 || String(event.props.label).toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    render() {
        return (
            <Select {...this.props} style={{width: "100%"}}
                    showSearch={this.props.showSearch} filterOption={this.filterSelectOption}
                    value={convertValue(this.props.value)}>
                {this.renderSelectComponent(this.props.options, this.props.keyField, this.props.codeField, this.props.labelField,
                    this.props.renderOption, this.props.nullItem, this.props.header)}
            </Select>
        );
    }

    renderSelectComponent = (data, id, code, name, renderOption, nullItem, header) => {
        let children = [];
        if (header) {
            children.push(
                <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label=" "
                        style={{
                            borderTop: "1px solid #a6a6a6",
                            backgroundColor: "#928e0a42",
                            textAlign: "center"
                        }}>
                    <div className="p-grid itemProp">
                        {this.renderHeader(renderOption)}
                    </div>
                </Option>
            );
        }
        if (nullItem) {
            children.push(
                <Option className="optionSelectItem" key={null} label={" "}>
                    <div className="p-grid itemProp">
                        {this.renderNullItem(renderOption)}
                    </div>
                </Option>
            );
        }
        if (data && data.length > 0) data.forEach(item => {
            children.push(
                <Option className="optionSelectItem" value={String(item[id])} key={String(item[code])}
                        label={item[name]}>
                    <div className="p-grid itemProp">
                        {this.renderItem(item, renderOption)}
                    </div>
                </Option>
            );
        });
        return children;
    };

    renderHeader = (renderOption) => {
        let header = [];
        for (let i = 0; i < renderOption.length; i++) {
            if (i === renderOption.length - 1) {
                header.push(
                    <div className={"noWrap"} style={{width: (100 - i * this.state.width) + '%'}}
                         key={renderOption[i] + i}
                         title={renderOption[i]}>
                        <b>{renderOption[i]}</b>
                    </div>
                )
            } else {
                header.push(
                    <div className={"borderRight noWrap"} style={{width: this.state.width + '%'}}
                         key={renderOption[i] + i}
                         title={renderOption[i]}>
                        <b>{renderOption[i]}</b>
                    </div>
                )
            }
        }
        return header;
    }

    renderItem = (data, renderOption) => {
        let item = [];
        for (let i = 0; i < renderOption.length; i++) {
            if (i === renderOption.length - 1) {
                item.push(
                    <div className={"noWrap"} style={{width: (100 - i * this.state.width) + '%'}}
                         key={renderOption[i] + i}
                         title={data[renderOption[i]]}>
                        {data[renderOption[i]]}
                    </div>
                )
            } else {
                item.push(
                    <div className={"borderRight noWrap"} style={{width: this.state.width + '%'}}
                         key={renderOption[i] + i}
                         title={data[renderOption[i]]}>
                        {data[renderOption[i]]}
                    </div>
                )
            }
        }
        return item;
    }

    renderNullItem = (renderOption) => {
        let nullItem = [];
        for (let i = 0; i < renderOption.length; i++) {
            if (i === renderOption.length - 1) {
                nullItem.push(
                    <div className={"noWrap"} style={{width: (100 - i * this.state.width) + '%'}}
                         key={renderOption[i] + i}>{""}</div>
                )
            } else {
                nullItem.push(
                    <div className={"borderRight noWrap"} style={{width: this.state.width + '%'}}
                         key={renderOption[i] + i}>{""}</div>
                )
            }
        }
        return nullItem;
    }
}

export default (SelectOption);