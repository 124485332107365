import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import IconSVG from './IconSVG';
import { Tooltip } from 'antd';
import Constants from '../../constants/Constants';
import MenuItem from "./MenuItem";

export default class AppLeftShowMenu extends Component {
    static defaultProps = {
        className: null,
        items: null,
    };

    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const listMenu = filterEmptyItems(this.props.items);

        return (
            <div className='leftnavbar'>
                <ul className='leftnavbar__list'>
                    <NavLink
                        to=''
                        className={`leftnavbar__list--item`}
                        onClick={this.props.onToggleMenu}
                    >
                        <li
                            style={{
                                height : "50%",
                                display : 'flex',
                                alignItems : 'center'
                            }}
                            >
                            {IconSVG.ArrowRightDirectionSVG}
                        </li>
                    </NavLink>
                    {listMenu &&
                        listMenu.map((element) => {
                            let text;
                              try {
                                let start_index = element.to.indexOf('/') + 1;
                                let end_index = element.to.indexOf('?');
                                text = element.to.substring(start_index, end_index);
                            } catch (error)     {
                                
                            }
                            let url = new URL(window.location.href);
                            let listpath = url.pathname.split('/')
                            let path = listpath.pop()
                            return (
                                <Tooltip
                                    placement='right'
                                    title={element.label}>
                                    <NavLink
                                        to={`/admin${element.to}`}
                                        className={`leftnavbar__list--item ${path === text ? 'active' : ''}`}>
                                            <MenuItem item={element} text={text}/>
                                    </NavLink>
                                </Tooltip>
                            );
                        })}
                </ul>
                <Tooltip
                    placement='right'
                    title={this.props.logoutText}
                 >
                    <div className="bottom__button" onClick={this.props.logout}>
                        {Constants.TABLE_ICON.Logout}
                    </div>
                </Tooltip>
            </div>
        );
    }
}

const filterEmptyItems = (arr) => {
    let result = [];

    arr.forEach((item) => {
        if (item.items.length === 0 && item.to) {
            result.push(item);
        } else {
            result = result.concat(filterEmptyItems(item.items));
        }
    });

    return result;
}
