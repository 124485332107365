import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import className from './SwitchAccount.module.scss';
import CustomLabel from '../../../components/CommonLabel';
import CustomInput from '../../../components/common/CommonInputText/CommonInputText';

import { FTUComponent } from '../../../components/FTUComponent';
import { ObjectUtils } from '../../../utils/ObjectUtils';

class Profile extends FTUComponent {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.user) {
            return <></>;
        }

        console.log(this.props)

        const jobtitle = this.props.user?.jobTitles
        return (
            <div className={`${className.container} ${this.props.user.status === 0 ? 'disabled-switch' : ''} `} id='profile_container'>
                <div className={className.header}>
                    <div className={className.headerLeft}>
                        <img
                            src='/assets/layout/images/avatar-svgrepo-com.svg'
                            alt=''
                        />
                    </div>
                    <div className={className.headerRight}>
                        <div className={className.fullName}>
                            {!Boolean(this.props.user.fullName) && <label>{this.trans("common:data.username")}&nbsp;:&nbsp;</label>}
                            {this.props.user.fullName
                                ? this.props.user.fullName
                                : this.props.user.userName}
                            {Boolean(this.props.user.fullName) && ` - ${this.props.user.userName}`}
                        </div>
                        <div className={className.email + ' ' + className.description}>
                            {this.trans('common:app.email')} : {this.props.user.email}
                        </div>
                        <div className={className.phone + ' ' + className.description}>
                            {this.trans('common:app.phone')} : {this.props.user.phone || "(Trống)"}
                        </div>
                        <div className={className.phone + ' ' + className.description}>
                            {this.trans('common:app.company')} : {!ObjectUtils.checkIsNullObject(jobtitle[0]) ? jobtitle[0]?.parentCode + ' ― ' + jobtitle[0]?.parentName : "(Trống)"}
                        </div>
                        <div className={className.phone + ' ' + className.description}>
                            {this.trans('common:app.jobtitle')} : {!ObjectUtils.checkIsNullObject(jobtitle[0]) ? jobtitle[0]?.jobTitleCode +' ― '+ jobtitle[0]?.jobTilteName : "(Trống)"}
                        </div>
                        <div className={className.phone + ' ' + className.description}>
                            {this.trans('common:app.positionCode')} : {!ObjectUtils.checkIsNullObject(jobtitle[0]) ? jobtitle[0]?.positionCode +' ― '+ jobtitle[0]?.positionName : "(Trống)"}
                        </div>
                    </div>
                </div>

                {this.props.details && (
                        <div
                            className={`${
                                this.props.border
                                    ? ' border-user ' + className.item
                                    : ''
                                }' flex-100'`}
                            id={'profile_container ' + className.body}>
                            <div
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                }}>
                                <div
                                    className='row col-md-auto'
                                    style={{
                                        alignContent: 'start',
                                        marginBottom: '10px',
                                    }}>
                                    <div className='col-md-6'>
                                        <CustomLabel>
                                            {this.trans('common:data.userName')}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                this.props.user
                                                    ? this.props.user.userName
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <CustomLabel>
                                            {this.trans(
                                                'common:data.createdBy'
                                            )}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                this.props.user
                                                    ? this.props.user.createdBy
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                               
                            </div>
                            <div
                                className='row col-md-auto'
                                style={{ alignContent: 'start' }}>
                                {this.props.user.address && (
                                    <div className='col-md-12'>
                                        <CustomLabel>
                                            {this.trans('common:data.address')}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                this.props.user
                                                    ? this.props.user.address
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </div>

                            <div
                                className='row col-md-auto'
                                style={{ alignContent: 'start' }}>
                                    {jobtitle && (
                                    <div className='col-md-12'>
                                        <CustomLabel>
                                            {this.trans('common:data.parentOrgCode')}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                jobtitle
                                                    && jobtitle[0]?.parentCode + ' ― ' + jobtitle[0]?.parentName
                                            }
                                        />
                                    </div>
                                    )}
                            </div>

                            <div
                                    className='row col-md-auto'
                                    style={{ alignContent: 'start' }}>
                                    <div className='col-md-6'>
                                        <CustomLabel>
                                            {this.trans('common:data.orgCode')}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                jobtitle && jobtitle[0]?.orgCode + ' ― ' + jobtitle[0]?.orgName
                                            }
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <CustomLabel>
                                            {this.trans('common:data.positionCode')}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                            jobtitle && jobtitle[0]?.positionCode + ' ― ' + jobtitle[0]?.positionName
                                            }
                                        />
                                    </div>
                            </div>

                            <div
                                    className='row col-md-auto'
                                    style={{ alignContent: 'start' }}>
                                    <div className='col-md-6'>
                                        <CustomLabel>
                                            {this.trans(
                                                this.props.user
                                                    .accountEndDate !==
                                                    undefined
                                                    ? 'common:data.accountEndDate'
                                                    : 'common:data.updatedBy'
                                            )}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                this.props.user
                                                    ? this.props.user
                                                          .accountEndDate !==
                                                      undefined
                                                        ? this.props.user
                                                              .accountEndDate
                                                        : this.props.user
                                                              .updatedAt
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <CustomLabel>
                                            {this.trans(
                                                this.props.user
                                                    .accountEndDate !==
                                                    undefined
                                                    ? 'common:data.accountStartDate'
                                                    : 'common:data.createdAt'
                                            )}{' '}
                                            :
                                        </CustomLabel>
                                        <CustomInput
                                            disabled={true}
                                            value={
                                                this.props.user
                                                    ? this.props.user
                                                          .accountStartDate !==
                                                      undefined
                                                        ? this.props.user
                                                              .accountStartDate
                                                        : this.props.user
                                                              .createdAt
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default withTranslation(['common'])(Profile);
