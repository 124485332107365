import React from "react";
import PropTypes from "prop-types";

class CommonLabel extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    return (
      <>
        <style>
          {`
          .customlabel {
            display: inline-block;
            margin-bottom: 0px;
          }
          `}
        </style>
        <label className="customlabel">
          {this.props.children}
          {this.props.required ? <b style={{color: 'red'}}>*</b> : null}
        </label>
      </>
    );
  }
}

export default CommonLabel;

CommonLabel.propTypes = {
  required: PropTypes.bool
};
