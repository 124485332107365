import {
  DELETE_NOTIFICATIONS,
  GET_CHANEL_PARENT_IS_NULL,
  GET_EXCLUDE_GROUP_CODE,
  NOTIFICATION_GET_ALL,
  SAVE_NOTIFICATION,
  SEND_NOTIFICATION,
  UPDATE_NOTIFICATION,
  COUNT_UN_READ, GET_ALL_NOTIFICATION, READ_ALL_NOTIFICATION
} from "../../constants/AppPath";
import { Utils } from "../common/utils";

export class NotificationService {
  getAllNotification(payload) {
    return Utils.get(`${GET_ALL_NOTIFICATION}`, payload, null, null, false);
  }

  getUnreadCount() {
    return Utils.get(COUNT_UN_READ, null, null, false);
  }

  readAll() {
    return Utils.post(READ_ALL_NOTIFICATION, null, null, false);
  }
  getAll(payload) {
    return Utils.post(NOTIFICATION_GET_ALL, payload, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }

  getServer() {
    return Utils.post(GET_CHANEL_PARENT_IS_NULL, null, null, true);
  }

  getChannelParent(url) {
    return Utils.post(url, null, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }

  getExcludeGroupCode(payload) {
    return Utils.post(GET_EXCLUDE_GROUP_CODE,  payload,  null, false , window.globalConfig.GATEWAY_DOMAIN);
  }

  saveNotification(payload) {
    return Utils.post(SAVE_NOTIFICATION, payload, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }

  updateNotification(payload) {
    return Utils.post(UPDATE_NOTIFICATION, payload, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }

  instanceNotification(payload) {
    return Utils.post(SEND_NOTIFICATION, payload, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }

  getDetailNotification(url) {
    return Utils.get(url, null, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }

  deleteNotification(payload) {
    return Utils.post(DELETE_NOTIFICATIONS, payload, null, true , window.globalConfig.GATEWAY_DOMAIN);
  }
}
