import { Menu, Switch, Modal as ModalAntd } from 'antd';
import Constants from 'constants/Constants';
import { Card } from 'primereact/card';
import React from 'react';
import { withTranslation } from 'react-i18next';
import SplitPane from 'react-split-pane';
import { ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { recursiveTree } from 'components/category/CommonUtils';
import Label from 'reactstrap/es/Label';
import Countdown from '../../../components/Countdown';
import { FTUComponent } from '../../../components/FTUComponent';
import LabelInput from '../../../components/LabelInput';
import {
    showErrorBox,
    showSuccessBox,
} from '../../../components/MessageBox';
import { focusFirstElement, stringNullOrEmpty, trimStringNoLowerCase } from '../../../components/category/CommonUtils';
import { CommonButton, CommonInputText } from '../../../components/common';
import {
    GET_CHANGEPASS_OTP,
    POST_LOG_ACCESS_PATH,
    THRID_PARTY_CHANGE_PASS,
    UPDATE_ALIAS,
    UPDATE_OTP,
} from '../../../constants/AppPath';
import { UserInfoService } from '../../../services/admin/UserInfoService';
import { Utils } from '../../../services/common/utils';
import Profile from '../../app/Profile';
import LoginSession from './LoginSession';
import './UserInfor.scss';
import JobTitleRoleService from '../../../services/admin/JobTitleRoleService';
import IhrpOrgService from '../../../services/admin/IhrpOrgService';
import { NavLink } from 'react-router-dom';
import { ApDomainService } from '../../../services/admin/ApDomainService';
import ImageManagement from './ImageManagement';
import { withRouter } from 'react-router-dom'
import { APP_CODE} from '../../../constants/AppProps';
import LinkedAccountPanel from './LinkedAccountPanel';
import { validatePassword } from './policy';

const {
    SERVICE_CODE: { SUCC_CODE, DUPLICATE_CODE },
    TABLE_ICON: { EditNoBackground, Checked, Cancel },
    CONVENTION: { TRUE, FALSE },
    ACTION: { UPDATE },
    OTP_LENGTH,
} = Constants;

const default_res = {
    userId: "",
    userName: "",
    password: "",
    passwordExpireStatus: "",
    fullName: "",
    firstName: "",
    priority: 0,
    status: 0,
    accountStartDate: "",
    accountEndDate: "",
    linkedAccountList: "",
    alias: "",
    phone: "",
    orgCode: "",
    companyCode: "",
    companyName: "",
    orgLevel: 0,
    telegramChatId: "",
    totpEmail: "",
    totpPhone: "",
    totpTelegram: "",
    fax: "",
    email: "",
    address: "",
    lastChangePassword: "",
    lastBlockDate: "",
    loginFailureCount: 0,
    deptId: 0,
    position: "",
    positionId: 0,
    staffCode: "",
    sex: "",
    mobile: "",
    userType: "employee",
    birthday: "",
    yearOfBirth: "",
    groups: [
      {
        groupId: 0,
        groupName: ""
      }
    ],
    referCode: "",
    orgId: 0,
    idNo: "",
    totalRecord: 0,
    createdAt: "",
    createdBy: "",
    updatedAt: "",
    updatedBy: "",
    emailChangePassword: "",
    isAdmin: 0,
    jobTitles: [
      {
        jobTitleId: 0,
        jobTitleName: ""
      }
    ],
    listOrg: [
      {
        orgId: 0,
        orgName: ""
      }
    ],
    isLoginThirdParty: false
} 

class UserInfo extends FTUComponent {
    constructor(props) {
        super(props);
        this.state = {
            model: [
                {
                    label: 'Tài khoản',
                    key: 'account',
                },
                {
                    label: 'Mật khẩu',
                    key: 'password',
                },
                {
                    label: 'Phiên đăng nhập',
                    key: 'loginSession',
                },
                {
                    label: 'Quản lý ảnh',
                    key: 'image-management',
                },
            ],
            hiddenItem: null,
            errors: [],
            dataChangePass: {
                oldPassword: '',
                newPassword: '',
                confirmPass: '',
            },
            dataUpdateUser: {
                userName: '',
                email: '',
                firstName: '',
                lastName: '',
            },
            data: default_res,
            linkedAccount: null,
            aliasStatus: true,
            otpPassword: true,
            smsStatus: true,
            emailStatus: true,
            teleStatus: true,
            isOTPCounting: false,

            defaultSelectedKeys: [],
        };
        this.userInfo = new UserInfoService();
        this.JobTitleRoleService = new JobTitleRoleService();
        this.IhrpOrgService = new IhrpOrgService();
        this.ApDomainService = new ApDomainService();
        this.passwordRef = React.createRef();
    }

    componentDidMount = async () => {

        document.title = "ADM - Thông tin người dùng"
        const params = new URLSearchParams(window.location.search);
        const password = params.get('password');
        if (password && String(password).toLowerCase() == 'true') {
            this.setState({
                showItem: 'password',
                defaultSelectedKeys: ['password'],
            });
        } else {
            this.setState({
                showItem: 'account',
                defaultSelectedKeys: ['account'],
            });
        }
        await this.getDataByToken();

        const pathName = '/admin/userInfo'
        await Utils.post(POST_LOG_ACCESS_PATH, {
            appCode: APP_CODE,
            path: pathName,
        })
    };

    getOtpCountdown = async () => {
        try {
            const res = await this.ApDomainService.getByCode('PASSWORD_OTP');
            
            if (res.data?.value) {
                this.setState({
                    otpCount: Number(res.data.value),
                });
            }
        } catch (error) {
            this.commonHandleError({
                message: this.trans('common:msg.API503'),
            });
        }
    };

    validateChangePass = (data) => {
        let isValid = true;
        if (stringNullOrEmpty(data.confirmPass)) {
            showErrorBox(
                this.trans('changePassword:msg.confirmPassIsEmpty'),
                this.trans('common:msg.title'),
                document.getElementById('confirmPass').focus()
            );
            isValid = false;
        } else if (data.newPassword !== data.confirmPass) {
            showErrorBox(
                this.trans('changePassword:msg.wrongConfirm'),
                this.trans('common:msg.title'),
                document.getElementById('confirmPass').focus()
            );
            isValid = false;
        } else if (!data.changePasssOtp || data.changePasssOtp.length !== OTP_LENGTH) {
            showErrorBox(
                this.trans('changePassword:msg.invalidOTP'),
                this.trans('common:msg.title'),
                document.getElementById('OTP').focus()
            );
            isValid = false;
        }
        return isValid;
    };
    validateUpdate = (data) => {
        let isValid = true;
        let errors = {};

        if (stringNullOrEmpty(data.userName)) {
            errors['userName'] = this.trans('common:msg.emptyMessage');
            isValid = false;
        }
        if (stringNullOrEmpty(data.firstName)) {
            errors['firstName'] = this.trans('common:msg.emptyMessage');
            isValid = false;
        }
        if (stringNullOrEmpty(data.fullName)) {
            errors['fullName'] = this.trans('common:msg.emptyMessage');
            isValid = false;
        }
        this.setState({ errors: errors });
        return isValid;
    };

    /**========================================================================
     **                           getDataByToken
     *? trở về API: toàn bộ detail của một user
     *========================================================================**/

    getDataByToken = async () => {
        
        let res = await this.userInfo.getInfoUser();
        if (res?.data && res?.data?.code === Constants.SERVICE_CODE.SUCC_CODE) {
            res.data.data.userADM.totpEmail =
                res.data.data.userADM.totpEmail === TRUE ? true : false;
            res.data.data.userADM.totpPhone =
                res.data.data.userADM.totpPhone === TRUE ? true : false;
            res.data.data.userADM.totpTelegram =
                res.data.data.userADM.totpTelegram === TRUE ? true : false;
            let data = res.data.data.userADM;
            data.aliasTemp = data.alias
            await this.promisedSetState({
                // email : res.data.data.email,
                // userName : res.data.data.userName,
                // firstName : res.data.data.firstName,
                // lastName : res.data.data.fullName,
                data: res.data.data.userADM,
                linkedAccount: res.data.data.userList,
            });
            await this.getNamesByCode();
        }else {
            await this.setState({
                  linkedAccount : []    
            })
        }
    };

    /**========================================================================
     **                           getNamesByCode
     *?  trở về API: lấy tên của "Chức vụ / Chức danh / Đơn vị"
     *========================================================================**/
    getNamesByCode = async () => {
        
        try {
            const jobTitles = this.state.data.jobTitles[0]?.jobTitleCode ?? '';
            
            if (jobTitles) {
                const res = await this.JobTitleRoleService.getJobTitle({});
                
                if (res?.data?.code === SUCC_CODE && Array.isArray(res?.data?.data)) {
                    recursiveTree(res.data.data, (node) => {
                        if (node?.jobTitleCode === jobTitles && node?.jobTitleNameVN) {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    jobTitleString: node.jobTitleNameVN,
                                },
                            });
                        }
                    });
                }
            }
        } catch (error) {
            this.commonHandleError(error);
        }
        try {
            const positionCode = this.state.data.jobTitles[0]?.positionCode ?? '';
            
            if (positionCode) {
                const res = await this.JobTitleRoleService.getJobPosition();
                
                if (res?.data?.code === SUCC_CODE && Array.isArray(res?.data?.data)) {
                    recursiveTree(res.data.data, (node) => {
                        if (node?.code === positionCode && node?.nameVn) {
                            this.setState({
                                data: {
                                    ...this.state.data,
                                    positionCodeString: node.nameVn,
                                },
                            });
                        }
                    });
                }
            }
        } catch (error) {
            this.commonHandleError(error);
        }
        try {
            const orgCode = String(this.state.data.orgCode);
            let orgCodeString = orgCode;
            let listOrg = [];
            let orgCodeParent = ' ';
            let orgCodeParentString = ' ';
            const data = this.state.data

            if (Array(data.jobTitles).length !== 0) {
                for (let index = 0; index < data?.jobTitles?.length || 0; index++) {
                    const element = data?.jobTitles[index];
                    if (element.type === 0) {
                        orgCodeParent = element.parentCode;
                        orgCodeParentString = element.parentName;
                        orgCodeString = element.parentName;
                    }
                    
                }
            } else  {
                let res = await this.IhrpOrgService.getAllIhrpByNode();
                
                if (res?.data?.code === SUCC_CODE && Array.isArray(res?.data?.data)) {
                    let listOrg = res.data.data;
                    recursiveTree(listOrg, (orgNode) => {
                        orgNode.codeName = orgNode.code + ' ― ' + orgNode.nameVn;
                    });
                    listOrg = res.data.data;
                    recursiveTree(res.data.data, (node) => {
                        if (node?.code === orgCode && node?.nameVn) {
                            orgCodeString = node.nameVn;
                            recursiveTree(res.data.data, (nodeParent) => {
                                if (node?.parentCode === nodeParent?.code) {
                                    orgCodeParent = nodeParent.code;
                                    orgCodeParentString = nodeParent.nameVn;
                                }
                            });
                        }
                    });
                }
            }
            await this.promisedSetState({
                data: {
                    ...this.state.data,
                    orgCodeString: orgCodeString,
                    listOrg: listOrg,
                    orgCodeParent: orgCodeParent,
                    orgCodeParentString: orgCodeParentString,
                },
            });

            
        } catch (error) {
            this.commonHandleError(error);
        }
    };

    onClickMenuItem = async (e) => {
        
        await this.setState({
            showItem: e.key,
            defaultSelectedKeys: [e.key],
        });
        
        
    };

    /**========================================================================
     **                           onSubmitAlias
     *?  Update alias ak Bí Danh field
     *@return Promise<void>
     *========================================================================**/
    onSubmitAlias = async () => {
        const regex = new RegExp(/^[a-zA-Z0-9]*$/);
        const newAlias = trimStringNoLowerCase(this.state.data.aliasTemp)
        if (this.state.data.aliasTemp !== this.state.data.alias) {
            if (
                regex.test(newAlias) &&
                // !stringNullOrEmpty(this.state.data.aliasTemp) &&
                newAlias.length <= 32
            ) {
                const url = UPDATE_ALIAS + newAlias;
                const res = await Utils.post(url, null, null, true);
                switch (res.data.code) {
                    case SUCC_CODE:
                        await showSuccessBox(this.trans('common:msg.success'));
                        this.setState({
                            aliasStatus: true,
                            data: {
                                ...this.state.data,
                                alias: newAlias,
                            },
                        });
                        break;
                    case DUPLICATE_CODE:
                        await showErrorBox(this.trans('userInfo:duplicateAlias'));
                        // await showWarningToaster(this.trans('userInfo:duplicateAlias'));
                        // document.getElementById("alias").focus();

                        break;
                    default:
                        await showErrorBox(res.data.message);
                        break;
                }
            } else {
                this.commonHandleError({ message: this.trans('invalidAlias') });
            }
        } else {
            this.commonHandleError({ message: this.trans('dulpidatedAlias') });
        }
    };

    onChangeSwitch = async (key) => {
        
        switch (key) {
            case 'SMS':
                this.onSubmitOtp({ totpPhone: !this.state.data.totpPhone }, true);
                break;
            case 'TELE':
                this.onSubmitOtp({ totpTelegram: !this.state.data.totpTelegram }, true);
                break;
            case 'EMAIL':
                this.onSubmitOtp({ totpEmail: !this.state.data.totpEmail }, true);
                break;
            default:
                break;
        }
    };

    onSubmitOtp = async (data, callback) => {
        try {
            const {
                userName,
                fullName,
                email,
                status,
                telegramChatId,
                phone,
                totpEmail,
                totpPhone,
                totpTelegram,
                userId,
                userType,
                emailChangePassword,
            } = this.state.data;
            const payload = {
                userName,
                fullName,
                email,
                status,
                telegramChatId,
                phone,
                totpEmail,
                totpPhone,
                totpTelegram,
                userId,
                userType,
                emailChangePassword,
                ...data,
            };
            payload.totpEmail = payload.totpEmail ? TRUE : FALSE;
            payload.totpPhone = payload.totpPhone ? TRUE : FALSE;
            payload.totpTelegram = payload.totpTelegram ? TRUE : FALSE;
            const mailRegEx = new RegExp(
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            );
            if (
                data.emailChangePassword !== undefined &&
                !(
                    data.emailChangePassword &&
                    !stringNullOrEmpty(data.emailChangePassword) &&
                    mailRegEx.test(data.emailChangePassword)
                )
            ) {
                showErrorBox(this.trans('invalidEmail'));
                return;
            }

            if (data) {
                if (!this.validate(data)) {
                    return;
                }
            }

            const res = await Utils.post(UPDATE_OTP, payload, null, true);

            if (res.data.code === SUCC_CODE) {
                await this.promisedSetState({ data: { ...this.state.data, ...data } });
                if (!callback) {
                    showSuccessBox(this.trans('common:msg.success'));
                }
                if (callback) {
                    // callback();
                    // await this.promisedSetState({ data: payload });
                }
            }
        } catch (error) {
            // this.commonHandleError(error);
        }
    };

    onSubmitChangePass = async () => {
        let data = this.state.data;
        if (this.validateChangePass(data)) {
            //     call api dooi mat khau
            let request = {
                password: data.newPassword,
                otp: data.changePasssOtp,
                oldPassword: data.oldPassword,
            };
            let res = await this.userInfo.changePasswordUser(request);
            if (res && res.data) {
                if (res.data.code === 'API-000') {
                    showSuccessBox(this.trans('common:msg.changePassSuccess') , null , async () => {
                        this.setState({
                            data: {
                                ...this.state.data,
                                newPassword: '',
                                confirmPass: '',
                                changePasssOtp: '',
                            },
                        });
                        this.props.history.push('/')
                    });
                } else {
                    showErrorBox(res.data.message, this.trans('common:msg.title'), () => {
                        setTimeout(() => {
                            focusFirstElement(document, 'detailForm');
                        }, 500);
                    });
                }
            }
        }
    };

    onChangeStatus = (field, data) => {
        this.setState({
            [field]: data !== undefined ? data : !this.state[field],
        });
    };

    endCountDown = () => {
        this.setState({
            isOTPCounting: false,
        });
    };

    takeOTP = async () => {
        // CALL API OTP SENDER
        this.getOtpCountdown();
        const payload = {
            email: this.state.data.emailChangePassword,
        };
        await Utils.post(GET_CHANGEPASS_OTP, payload, null, true);

        // setState disable button

        this.setState({
            isOTPCounting: true,
        });
    };

    validate = (data) => {
        this.setState({
            errors: this.isValid(data).errors,
        });
        return this.isValid(data).isValid;
    };

    componentDidUpdate = (preProps , prevState) => {
        if (this.state.showItem !== prevState.showItem && this.state.showItem === 'password') {     
           document.getElementById('newPassword').focus()
        }
    }

    render = () => {
        const { fullName, ...rest } = this.state.data;
        
        return (
            <div className='user-info-page'>
                <ModalHeader>
                    <NavLink to={'/admin/'}>
                        <img alt={'Logo'} src={'/assets/layout/images/logo_dxg.svg'} />
                    </NavLink>
                </ModalHeader>
                <ModalBody>
                    <SplitPane
                        split='vertical'
                        minSize={0}
                        defaultSize={'20%'}
                        style={{ width: '100%' }}
                        className='split-pane-without-footer no-gap'
                        pane1Style={{ overflow: 'auto' }}
                        pane2Style={{ overflow: 'auto', width: '100%' }}
                    >
                        <Card className='flex-100 userinfo-leftpanel'>
                            <Menu
                                mode='vertical'
                                items={this.state.model}
                                style={{ width: '100%' }}
                                onClick={this.onClickMenuItem}
                                selectedKeys={this.state.defaultSelectedKeys}
                            />
                        </Card>
                        <div className='flex-100 w-100 card-adjust'>
                            {/* Thông tin tài khoản */}
                            <div hidden={this.state.showItem === 'account' ? false : true}>
                                <Card
                                    className='body'
                                    header={<h3>{this.trans('common:menu.UserInfo')}</h3>}
                                >
                                    <Row className={'userGroup'} style={{ maxHeight: '100%' }}>
                                        <div className='row col-md-12' id='profile'>
                                            <Profile user={rest} details={true} userInfo={true}/>
                                        </div>
                                        <div className='row col-md-12'>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('common:data.firstName')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'firstName'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'firstName'
                                                    )}
                                                    defaultValue={this.state.data.fullName}
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('common:data.referCode')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'referCode'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'referCode'
                                                    )}
                                                    defaultValue={this.state.data.referCode}
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                        </div>
                                        <div className='row col-md-12'>
                                            <div className='col-md-2'>
                                                <Label>
                                                    {this.trans('common:data.positionCode')}
                                                </Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'lastName'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'positionCode'
                                                    )}
                                                    defaultValue={
                                                        this.state.data.jobTitles &&
                                                        this.state.data.positionCodeString &&
                                                        this.state.data.jobTitles[0].positionCode +
                                                            ' ― ' +
                                                            this.state.data.positionCodeString
                                                    }
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('position')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'jobTitle'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'jobTitle'
                                                    )}
                                                    defaultValue={
                                                        this.state.data.jobTitles &&
                                                        this.state.data.jobTitles[0]?.jobTilteName &&
                                                        this.state.data.jobTitles[0]?.jobTitleCode +
                                                            ' ― ' +
                                                            this.state.data.jobTitles[0]
                                                                .jobTilteName
                                                    }
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                        </div>
                                        <div className='row col-md-12'>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('accType')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'userType'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'userType'
                                                    )}
                                                    value={this.trans(
                                                        `common:userType.${this.state.data.userType}`
                                                    )}
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('nickname')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'alias'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'aliasTemp'
                                                    )}
                                                    className={`${((this.state.aliasStatus) ? "input_label " : "alias_active")}`}
                                                    disabled={this.state.aliasStatus}
                                                    defaultValue={this.state.data.alias}
                                                    value={this.state.data.aliasTemp}
                                                />

                                                <div
                                                    className='checkbox'
                                                    hidden={!this.state.aliasStatus}
                                                    onClick={() => {
                                                        this.setState({
                                                            data: {
                                                                ...this.state.data,
                                                                aliasTemp: this.state.data.alias,
                                                            },
                                                        });
                                                        document.getElementById('alias').value =
                                                            this.state.data.alias;
                                                        this.onChangeStatus('aliasStatus', false);
                                                    }}
                                                >
                                                    {EditNoBackground}
                                                </div>
                                                <div
                                                    className='checkbox'
                                                    style={{
                                                        transform: 'translate(-150% , -50%)',
                                                    }}
                                                    hidden={this.state.aliasStatus}
                                                    onClick={async () => {
                                                        await this.onSubmitAlias();
                                                        // document.getElementById('alias').value =
                                                        //     this.state.data.alias;
                                                        // this.onChangeStatus('aliasStatus', true);
                                                    }}
                                                >
                                                    {Checked}
                                                </div>
                                                <div
                                                    className='checkbox'
                                                    hidden={this.state.aliasStatus}
                                                    onClick={() => {
                                                        this.setState({
                                                            data: {
                                                                ...this.state.data,
                                                                aliasTemp: this.state.data.alias,
                                                            },
                                                        });
                                                        this.onChangeStatus('aliasStatus', true);
                                                    }}
                                                >
                                                    {Cancel}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row col-md-12'>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('orgCode')}</Label>
                                            </div>
                                            <div className='col-md-10'>
                                                <CommonInputText
                                                    id={'orgCode'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'orgCode'
                                                    )}
                                                    defaultValue={
                                                        this.state.data.orgCodeParentString &&
                                                        this.state.data.orgCodeParent &&
                                                        this.state.data.orgCodeParentString +
                                                            ' (' +
                                                            this.state.data.orgCodeParent +
                                                            ') ― ' +
                                                            this.state.data.orgCode &&
                                                        this.state.data.orgCodeString &&
                                                        this.state.data.orgCodeString +
                                                            ' (' +
                                                            this.state.data.orgCode +
                                                            ')'
                                                    }
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                        </div>
                                        <div className='row col-md-12'>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('status')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'status'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'status'
                                                    )}
                                                    value={
                                                        this.state.data.status > 0
                                                            ? this.trans('common:data.active')
                                                            : this.trans('common:data.inactive')
                                                    }
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('FAX')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'fax'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'fax'
                                                    )}
                                                    defaultValue={this.state.data.fax}
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div>
                                        </div>
                                        <div className='row col-md-12'>
                                            {/* <div className='col-md-2'>
                                                <Label>
                                                    {this.trans('deptId')}
                                                </Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'deptId'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'deptId'
                                                    )}
                                                    defaultValue={
                                                        this.state.data.deptId
                                                    }
                                                    disabled={true}
                                                    className={'input_label'}
                                                />
                                            </div> */}
                                            <div className='col-md-2'>
                                                <Label>{this.trans('otpPassword')}</Label>
                                            </div>
                                            <div className='col-md-4'>
                                                <CommonInputText
                                                    id={'otpPassword'}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={30}
                                                    onChange={this.onChangeTxtField.bind(
                                                        this,
                                                        'otpPassword'
                                                    )}
                                                    className={
                                                        this.state.otpPassword && 'input_label'
                                                    }
                                                    disabled={this.state.otpPassword}
                                                    defaultValue={
                                                        this.state.data.emailChangePassword
                                                    }
                                                />

                                                <div
                                                    className='checkbox'
                                                    hidden={!this.state.otpPassword}
                                                    onClick={() => {
                                                        this.setState({
                                                            data: {
                                                                ...this.state.data,
                                                                emailChangePasswordTemp:
                                                                    this.state.data
                                                                        .emailChangePassword,
                                                            },
                                                        });
                                                        document.getElementById(
                                                            'otpPassword'
                                                        ).value =
                                                            this.state.data.emailChangePassword;
                                                        this.onChangeStatus('otpPassword', false);
                                                    }}
                                                >
                                                    {EditNoBackground}
                                                </div>
                                                <div
                                                    className='checkbox'
                                                    style={{
                                                        transform: 'translate(-150% , -50%)',
                                                    }}
                                                    hidden={this.state.otpPassword}
                                                    onClick={async () => {
                                                        await this.onSubmitOtp({
                                                            emailChangePassword:
                                                                document.getElementById(
                                                                    'otpPassword'
                                                                ).value,
                                                        });
                                                        this.onChangeStatus('otpPassword', true);
                                                    }}
                                                >
                                                    {Checked}
                                                </div>
                                                <div
                                                    className='checkbox'
                                                    hidden={this.state.otpPassword}
                                                    onClick={() => {
                                                        this.onChangeStatus('otpPassword', true);
                                                        document.getElementById(
                                                            'otpPassword'
                                                        ).value =
                                                            this.state.data.emailChangePassword;
                                                    }}
                                                >
                                                    {Cancel}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'></div>
                                        <div className='row col-md-12'>
                                            {this.state.data.lastChangePassword && (
                                                <>
                                                    <div className='col-md-2'>
                                                        <Label>
                                                            {this.trans('lastChangePassword')}
                                                        </Label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <CommonInputText
                                                            id={'lastChangePassword'}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            maxLength={30}
                                                            onChange={this.onChangeTxtField.bind(
                                                                this,
                                                                'lastChangePassword'
                                                            )}
                                                            defaultValue={
                                                                this.state.data.lastChangePassword
                                                            }
                                                            disabled={true}
                                                            className={'input_label'}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {this.state.data.passwordExpireStatus && (
                                                <>
                                                    <div className='col-md-2'>
                                                        <Label>
                                                            {this.trans('passwordExpireStatus')}
                                                        </Label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <CommonInputText
                                                            id={'passwordExpireStatus'}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            maxLength={30}
                                                            onChange={this.onChangeTxtField.bind(
                                                                this,
                                                                'passwordExpireStatus'
                                                            )}
                                                            defaultValue={
                                                                this.state.data.passwordExpireStatus
                                                            }
                                                            disabled={true}
                                                            className={'input_label'}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className='row col-md-12'></div>
                                        {this.state.data.address && (
                                            <>
                                                <div className='row col-md-12'>
                                                    <div className='col-md-2'>
                                                        <Label>
                                                            {this.trans('common:data.address')}
                                                        </Label>
                                                    </div>
                                                    <div className='col-md-10 mt-1'>
                                                        <CommonInputText
                                                            id={'address'}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            maxLength={30}
                                                            onChange={this.onChangeTxtField.bind(
                                                                this,
                                                                'address'
                                                            )}
                                                            defaultValue={this.state.data.address}
                                                            disabled={true}
                                                            className={'input_label'}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div
                                            className='row col-md-12'
                                            // hidden={this.state.data.isLoginThirdParty}
                                            hidden
                                        >
                                            <div className='col-md-2'>
                                                <Label>{this.trans('OTP SMS')}</Label>
                                            </div>
                                            <Row
                                                className='col-md-4'
                                                hidden={this.state.data.isLoginThirdParty}
                                            >
                                                <div
                                                    className={`col-md ${
                                                        !this.state.data.totpPhone && 'd-none'
                                                    }`}
                                                    style={{
                                                        transition: '.5s all',
                                                    }}
                                                >
                                                    <CommonInputText
                                                        id={'phone'}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        type={'number'}
                                                        maxLength={30}
                                                        onChange={this.onChangeTxtField.bind(
                                                            this,
                                                            'phoneTemp'
                                                        )}
                                                        className={
                                                            this.state.smsStatus && 'input_label'
                                                        }
                                                        defaultValue={this.state.data.phone}
                                                        disabled={this.state.smsStatus}
                                                        hidden={!this.state.data.totpPhone}
                                                        // value={this.state.data.phoneTemp}
                                                    />
                                                    <div
                                                        className='checkbox'
                                                        hidden={!this.state.smsStatus}
                                                        onClick={() => {
                                                            this.setState({
                                                                data: {
                                                                    ...this.state.data,
                                                                    phoneTemp:
                                                                        this.state.data.phone,
                                                                },
                                                            });
                                                            document.getElementById('phone').value =
                                                                this.state.data.phone;
                                                            this.onChangeStatus('smsStatus', false);
                                                        }}
                                                    >
                                                        {EditNoBackground}
                                                    </div>
                                                    <div
                                                        className='checkbox'
                                                        style={{
                                                            transform: 'translate(-150% , -50%)',
                                                        }}
                                                        hidden={this.state.smsStatus}
                                                        onClick={async () => {
                                                            await this.onSubmitOtp({
                                                                phone: this.state.data.phoneTemp,
                                                            });
                                                            document.getElementById('phone').value =
                                                                this.state.data.phone;
                                                            this.onChangeStatus('smsStatus', true);
                                                        }}
                                                    >
                                                        {Checked}
                                                    </div>
                                                    <div
                                                        className='checkbox'
                                                        hidden={this.state.smsStatus}
                                                        onClick={() => {
                                                            this.onChangeStatus('smsStatus', true);
                                                            document.getElementById('phone').value =
                                                                this.state.data.phone;
                                                        }}
                                                    >
                                                        {Cancel}
                                                    </div>
                                                </div>
                                                <div
                                                    className='col-md-auto'
                                                    // hidden={this.state.data.isLoginThirdParty}
                                                    hidden
                                                >
                                                    <Switch
                                                        onClick={() => {
                                                            document.getElementById('phone').value =
                                                                this.state.data.phone;
                                                            this.onChangeSwitch('SMS');
                                                        }}
                                                        checked={this.state.data['totpPhone']}
                                                    ></Switch>
                                                </div>
                                            </Row>
                                            <div className='col-md-2'>
                                                <Label>{this.trans('OTP Telegram')}</Label>
                                            </div>
                                            <Row className='col-md-4'>
                                                <div
                                                    className={`col-md ${
                                                        !this.state.data.totpTelegram && 'd-none'
                                                    }`}
                                                    style={{
                                                        transition: '.5s all',
                                                    }}
                                                >
                                                    <CommonInputText
                                                        id={'telegramChatId'}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        maxLength={30}
                                                        onChange={this.onChangeTxtField.bind(
                                                            this,
                                                            'telegramChatIdTemp'
                                                        )}
                                                        className={
                                                            this.state.smsStatus && 'input_label'
                                                        }
                                                        defaultValue={
                                                            this.state.data.telegramChatId
                                                        }
                                                        disabled={this.state.teleStatus}
                                                        hidden={!this.state.data.totpTelegram}
                                                    />
                                                    <div
                                                        className='checkbox'
                                                        hidden={!this.state.teleStatus}
                                                        onClick={() => {
                                                            this.setState({
                                                                data: {
                                                                    ...this.state.data,
                                                                    telegramChatIdTemp:
                                                                        this.state.data
                                                                            .telegramChatId,
                                                                },
                                                            });
                                                            document.getElementById(
                                                                'telegramChatId'
                                                            ).value =
                                                                this.state.data.telegramChatId;
                                                            this.onChangeStatus(
                                                                'teleStatus',
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        {EditNoBackground}
                                                    </div>
                                                    <div
                                                        className='checkbox'
                                                        style={{
                                                            transform: 'translate(-150% , -50%)',
                                                        }}
                                                        hidden={this.state.teleStatus}
                                                        onClick={async () => {
                                                            await this.onSubmitOtp({
                                                                telegramChatId:
                                                                    this.state.data
                                                                        .telegramChatIdTemp,
                                                            });
                                                            document.getElementById(
                                                                'telegramChatId'
                                                            ).value =
                                                                this.state.data.telegramChatId;
                                                            this.onChangeStatus('teleStatus', true);
                                                        }}
                                                    >
                                                        {Checked}
                                                    </div>
                                                    <div
                                                        className='checkbox'
                                                        hidden={this.state.teleStatus}
                                                        onClick={() => {
                                                            this.onChangeStatus('teleStatus', true);
                                                            document.getElementById(
                                                                'telegramChatId'
                                                            ).value =
                                                                this.state.data.telegramChatId;
                                                        }}
                                                    >
                                                        {Cancel}
                                                    </div>
                                                </div>
                                                <div
                                                    className='col-md-auto'
                                                    // hidden={this.state.data.isLoginThirdParty}
                                                    hidden
                                                >
                                                    <Switch
                                                        onClick={() => {
                                                            document.getElementById(
                                                                'telegramChatId'
                                                            ).value =
                                                                this.state.data.telegramChatId;
                                                            this.onChangeSwitch('TELE');
                                                        }}
                                                        checked={this.state.data['totpTelegram']}
                                                    ></Switch>
                                                </div>
                                            </Row>
                                        </div>

                                        <div
                                            className='row col-md-12'
                                            // hidden={this.state.data.isLoginThirdParty}
                                        >
                                            <div className='col-md-2'>
                                                <Label>{this.trans('OTP Email')}</Label>
                                            </div>
                                            <Row className='col-md-4'>
                                                <div
                                                    className={`col-md ${'d-none'}`}
                                                    style={{
                                                        transition: '.5s all',
                                                    }}
                                                ></div>
                                                <div
                                                    className='col-md-auto'
                                                    hidden={this.state.data.isLoginThirdParty}
                                                >
                                                    <Switch
                                                        onClick={() => {
                                                            this.onChangeSwitch('EMAIL');
                                                        }}
                                                        checked={this.state.data['totpEmail']}
                                                    ></Switch>
                                                </div>
                                            </Row>
                                        </div>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        {this.state.linkedAccount && (
                                            <LinkedAccountPanel
                                                userName={this.state.data.userName}
                                                linkedAccount={this.state.linkedAccount}
                                                trigger={this.getDataByToken}
                                                isAdmin={this.state.data.isAdmin}
                                            ></LinkedAccountPanel>
                                        )}
                                    </Row>
                                </Card>
                            </div>
                            {/* Đổi mật khu */}
                            <div
                                className='w-100'
                                hidden={this.state.showItem === 'password' ? false : true}
                            >
                                <Card
                                    className={'body'}
                                    header={<h3>{this.trans('common:app.resetPassword')}</h3>}
                                >
                                    <ModalBody>
                                        <div
                                            hidden={this.state.data.isLoginThirdParty}
                                            className={'userGroup w-100'}
                                            style={{ maxHeight: '100%' }}
                                            ref={this.passwordRef}
                                        >
                                            <LabelInput
                                                className='row'
                                                labelValue={this.trans(
                                                    'changePassword:newPassword'
                                                )}
                                                required
                                                labelClass='col-md-12'
                                                inputClass='col-md-12'
                                                input={{
                                                    id: 'newPassword',
                                                    type: 'password',
                                                    feedback: false,
                                                    value: this.state.data.newPassword,
                                                    autoFocus : true,
                                                    autoComplete: 'off',
                                                    onChange: this.onChangeTxtField.bind(
                                                        this,
                                                        'newPassword'
                                                    ),
                                                }}
                                            />
                                            <LabelInput
                                                className='row'
                                                labelValue={this.trans(
                                                    'common:app:confirmPassword'
                                                )}
                                                required
                                                labelClass='col-md-12'
                                                inputClass='col-md-12'
                                                input={{
                                                    id: 'confirmPass',
                                                    type: 'password',
                                                    feedback: false,
                                                    value: this.state.data.confirmPass,

                                                    autoComplete: 'off',
                                                    onChange: this.onChangeTxtField.bind(
                                                        this,
                                                        'confirmPass'
                                                    ),
                                                }}
                                            />
                                            <hr></hr>
                                            <Row style={{
                                                justifyContent : 'space-between'
                                            }}>
                                                <div style={{
                                                    display : 'flex',
                                                    flexDirection : 'row'
                                                }} className='col-md-fixed w-100'>
                                                    <CommonInputText
                                                        onChange={this.onChangeTxtField.bind(
                                                            this,
                                                            'changePasssOtp'
                                                        )}
                                                        value={this.state.data.changePasssOtp}
                                                        id='OTP'
                                                    />
                                                    <CommonButton
                                                        id={'btn-otp'}
                                                        label={this.trans('userInfo:OTP')}
                                                        onClick={this.takeOTP}
                                                        disabled={
                                                            this.state.isOTPCounting &&
                                                            !(this.state.otpCount instanceof Number)
                                                        }
                                                        style={{
                                                            transition: '.5s all ease',
                                                            marginLeft : '5px'
                                                        }}
                                                        className='w-100'
                                                    >
                                                        {this.state.isOTPCounting &&
                                                        this.state.otpCount ? (
                                                            <Countdown
                                                                hitCountdown={this.endCountDown}
                                                                otpCount={this.state.otpCount}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </CommonButton>
                                                </div>

                                            

                                                <div className='col-md-fixed w-100'>
                                                    <CommonButton
                                                        className='col-md-fixed w-100'
                                                        hidden={this.state.data.isLoginThirdParty}
                                                        style={{ marginRight: '5px' }}
                                                        color='primary'
                                                        icon='pi pi-check'
                                                        disabled={validatePassword(this.state.data.newPassword ?? '' , this.state.data.userName).filter(e => e.check === false).length !== 0}
                                                        label={this.trans('listGroupUserNew:save')}
                                                        onClick={this.onSubmitChangePass}
                                                    />
                                                </div>
                                            </Row>
                                            <Row >
                                                
                                            <div className='col-md-fixed w-100 ' >
                                                    {validatePassword(this.state.data.newPassword ?? '' , this.state.data.userName).map(e => (
                                                        <div className={`col-md-fixed w-100 flex-100 text-animation ${e.check ? 'green' : 'red'}`} style={{flexDirection : 'row'}}>
                                                            {e.check && <i className='pi pi-check-circle mr-2' style={{ color: 'green' }}></i>}
                                                            {!e.check && <i className='pi pi-ban mr-2' style={{ color: 'red' }}></i>}
                                                            <div>{this.trans(`common:policy.${e.id}`)}</div>
                                                        </div>
                                                    ))}
                                            </div>
                                            </Row>
                                        </div>

                                        <CommonButton
                                            label={this.trans('changePassword:changePassword')}
                                            onClick={() =>
                                                window.location.replace(THRID_PARTY_CHANGE_PASS)
                                            }
                                            hidden={!this.state.data.isLoginThirdParty}
                                        />
                                    </ModalBody>
                                    <ModalFooter></ModalFooter>
                                </Card>
                            </div>
                            {/* Thông tin phiên đăng nhập */}
                            <div
                                hidden={this.state.showItem === 'loginSession' ? false : true}
                                className='h-100'
                            >
                                <Card
                                    className='body flex-100'
                                    header={
                                        <h3>
                                            {this.trans('listGroupUserNew:label.loginSessionList')}
                                        </h3>
                                    }
                                >
                                    <LoginSession />
                                </Card>
                            </div>
                            {/* Picture Managemnt */}
                            <div
                                hidden={this.state.showItem === 'image-management' ? false : true}
                                className='h-100'
                            >
                                <Card className='body flex-100'>
                                    <ImageManagement />
                                </Card>
                            </div>

                           
                        </div>
                    </SplitPane>
                </ModalBody>
            </div>
        );
    };
}

export default withRouter(withTranslation(['listGroupUserNew', 'common', 'changePassword', 'userInfo'])(
    UserInfo
));
