import moment from 'moment';
import { showMessageBox, showErrorBox } from 'components/MessageBox';
import Constants from 'constants/Constants';
import { FTUTrans } from '../FTUComponent';
import React from 'react';
import { Tooltip } from 'antd';
import { STATUS } from '../../constants/AppProps';
import IconSVG from '../../containers/app/IconSVG';

const _ = require('lodash');

// https://admin-s3.s3-sgn09.fptcloud.com/employee/2024/4/23/print.css

// export const htmlToPdf = (htmlAsText) => {
//     const doc = new jsPDF();
//     const pdf = document.createElement('div');
//     pdf.innerHTML = htmlAsText;
//     doc.fromHTML(pdf);
//     doc.save("GFG.pdf");
// }

export const convertTreeToArray = (treeNode, id = 'accountId') => {
    let stack = [],
        array = [],
        hashMap = {};
    stack.push(treeNode);

    while (stack.length !== 0) {
        let node = stack.pop();
        if (!node) break;
        if (node.children == null) {
            visitNode(node, hashMap, array, id);
        } else {
            visitNode(node, hashMap, array, id);
            for (let i = node.children.length - 1; i >= 0; i--) {
                stack.push(node.children[i]);
            }
        }
    }
    return array;
};

function visitNode(node, hashMap, array, id) {
    if (node[id] !== 'null' && !hashMap[node[id]]) {
        hashMap[node[id]] = true;
        array.push(node);
    }
}

//return true if object ={};
export const checkEmptyObject = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object;
};

//return true if object is undefined || null || ""
export const stringNullOrEmpty = (string) => {
    if (
        string === undefined ||
        string === null ||
        string === 'null'||
        string === '' ||
        string instanceof String && string.trim() === ''
    )
        return true;
    return false;
};

export const objectNullOrEmpty = (object) => {
    if (object === undefined || object === null) {
        return true;
    } else {
        for (let key in object) {
            if (object.hasOwnProperty(key)) return false;
        }
        return true;
    }
};

export const checkStringContainsSpecial = (str) => {
    var re = new RegExp(/([^A-Z0-9a-z\ _])+/, 'g');
    let strTrimmed = trimStringNonSpecial(str);
    const testResult = re.test(strTrimmed);

    return testResult;
};

export const checkStringContainsSpecial2 = (str) => {

    var re = new RegExp(/^[^!@#$%^&*()+=\[\]{};':\-"\\|,.<>\/?]+$/, 'g');
    let strTrimmed = trimStringNonSpecial(str);
    const testResult = re.test(strTrimmed);

    return !testResult;
};

// return date or null
export const stringToDate = (input, format) => {
    if (stringNullOrEmpty(input)) {
        return null;
    }
    return moment(input, format);
};

export const addThousandSeparator = (
    inputValue,
    thousandSeparator = Constants.THOUSAND_SEPARATOR,
    decimalSeparator = Constants.DECIMAL_SEPARATOR
) => {
    inputValue += '';
    inputValue = inputValue.split(decimalSeparator);

    if (inputValue[1]) {
        inputValue[1] = decimalSeparator + inputValue[1];
    }

    if (inputValue[0].length > 3) {
        inputValue[0] = inputValue[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandSeparator);
    }
    return inputValue.join('');
};

export const trimString = (string) => {
    try {
        return string.trim().replace(/\s+/g, ' ').toLowerCase();
    } catch (error) {

        return string;
    }
};

export const trimStringNoLowerCase = (string) => {
    try {
        return string.trim().replace(/\s+/g, ' ');
    } catch (error) {

        return string;
    }
};

export const trimStringNonSpecial = (string, trim = true, toLowerCase = true) => {
    try {
        var AccentsMap = [
            'aàảãáạăằẳẵắặâầẩẫấậ',
            'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
            'dđ',
            'DĐ',
            'eèẻẽéẹêềểễếệ',
            'EÈẺẼÉẸÊỀỂỄẾỆ',
            'iìỉĩíị',
            'IÌỈĨÍỊ',
            'oòỏõóọôồổỗốộơờởỡớợ',
            'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
            'uùủũúụưừửữứự',
            'UÙỦŨÚỤƯỪỬỮỨỰ',
            'yỳỷỹýỵ',
            'YỲỶỸÝỴ',
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            string = string.toString().replace(re, char);
        }
        if (trim) {
            string = string.trim().replace(/[\s—_-]+/g, ' ');
        }
        if (toLowerCase) {
            string = string.toLowerCase();
        }
        return string;
    } catch (error) {

        return string;
    }
};

export const focusFirstElement = (document, id) => {
    const form = document.getElementById(id);
    if (!form || !form.elements) return false;
    const elements = form.elements;
    for (let i = 0; i < elements.length; i++) {
        if (
            (elements[i].tagName === 'INPUT' || elements[i].tagName === 'TEXTAREA') &&
            elements[i].type !== 'hidden' &&
            !elements[i].disabled
        ) {
            elements[i].focus();
            break;
        }
    }
};

// hanx them cho form organization
export const getObjectbyValue = (lstData, name, value) => {
    let object = {};
    if (objectNullOrEmpty(lstData) || lstData.length < 1 || stringNullOrEmpty(value)) {
        return object;
    }
    lstData.map((item) => {
        if (item[name] === value) {
            object = item;
            return object;
        }
    });
    return object;
};
export const getDefaultValue = (value, defaultValue = '') => {
    return stringNullOrEmpty(value) ? defaultValue : value;
};

export const getDefaultList = (lstValues, defaultValue = []) => {
    return objectNullOrEmpty(lstValues) ? defaultValue : lstValues;
};

/*
tìm element trong một list
*/
export const recursiveTree = async (tree, cb) => {
    try {
        if (tree && Array.isArray(tree)) {
            tree.forEach((treeNode) => {
                cb(treeNode);

                const child = treeNode.children || treeNode.child;
                recursiveTree(child, cb);
            });
        }
    } catch (error) {

    }
};

export const removeEmptyFields = async (treeValueReplace, fieldName, searchString) => {
    try {
        for (let i = 0; i < 2; i++) {
            await recursiveTree(treeValueReplace, async (deleteNodeParent) => {
                try {
                    if (Array.isArray(deleteNodeParent.children)) {
                        //

                        if (!deleteNodeParent.fieldName) {
                            // delete deleteNodeParent.children;
                        } else {
                        }
                        try {
                            deleteNodeParent.children = await deleteNodeParent.children.filter(
                                (item) => item[fieldName] != null
                            );
                            if (!(deleteNodeParent.children.length > 0)) {
                                delete deleteNodeParent.children;
                            }
                        } catch (error) {}
                    } else if (deleteNodeParent[fieldName]) {
                        const searchField = await trimStringNonSpecial(
                            deleteNodeParent[fieldName]
                        );
                        //
                        let similar = await compareSimilarStr(
                            searchField,
                            searchString
                        );
                        if (
                            !similar ||
                            !!deleteNodeParent?.hidden
                            // !searchField.includes(searchString)
                        ) {
                            try {
                                // delete deleteNodeParent.children;
                                deleteNodeParent[fieldName] = null;
                                deleteNodeParent.label = null;
                                // delete deleteNodeParent;
                            } catch (error) {}
                        }
                    }
                } catch (error) {
                    //
                    //
                }
            });
        }
    } catch (error) {

    } finally {
        return treeValueReplace;
    }
};

export const compareSimilarStr = async (parentStr, searchStr) => {
    //
    let parentStrTrim = trimStringNonSpecial(parentStr);
    let searchStrTrim = trimStringNonSpecial(searchStr);

    // let parentStrWords = parentStrTrim.split(' ');
    // let searchStrWords = searchStrTrim.split(' ');

    //
    //

    let similar = false;
    // await searchStrWords.forEach(async (searchWord) => {
    //     //
    //     let checkWord = false;
    //     await parentStrWords.forEach((parentWord) => {
    //         //
    //         if (parentWord.includes(searchWord)) {
    //             checkWord = true;
    //         }
    //     });
    //     if (!checkWord) {
    //         similar = false;
    //     }
    // });

    if (parentStrTrim.includes(searchStrTrim)) {
        similar = true;
    }

    //
    return similar;
};

export const recursiveSearch = async (treeValueReplace, searchString, filterBy) => {
    try {
        //
        //
        //
        // Search parent trước, nếu ko match thì search từng con

        // while (true) {
        let oldTreeValueReplace = [];
        searchString = trimStringNonSpecial(searchString);
        for (let i = 0; i < 2; i++) {
            // eslint-disable-next-line no-loop-func
            await recursiveTree(treeValueReplace, async (parentNode) => {
                const parentNodeSearch = parentNode[filterBy]
                    ? trimStringNonSpecial(parentNode[filterBy])
                    : '';
                //
                //
                if (parentNode.children) {
                    if (Array.isArray(parentNode.children) && parentNode.children.length > 0) {
                        parentNode.children = await recursiveSearch(
                            parentNode.children,
                            searchString,
                            filterBy
                        );
                        let compare1 = await compareSimilarStr(parentNodeSearch, searchString);
                        // trong trường hợp node bị filter mất đi children, kiểm tra xem node mẹ có trùng với search ko
                        if (
                            parentNode.children.length === 0 &&
                            // !parentNodeSearch.includes(searchString)
                            (await !compare1)
                        ) {
                            //
                            parentNode.label = null;
                            parentNode[filterBy] = null;
                            parentNode.children = [];
                            // delete parentNode.children;
                        }
                    }
                } else {
                    // //
                    // let compare2 = await compareSimilarStr(
                    //     parentNodeSearch,
                    //     searchString
                    // );
                    // if (
                    //     compare2
                    //     // parentNodeSearch.includes(searchString)
                    // ) {
                    //     //
                    // } else {
                    //     //
                    //     //
                    //     // Xóa node đi
                    //     // await recursiveTree(
                    //     //     treeValueReplace,
                    //     //     async (deleteNodeParent) => {
                    //     //         const deleteNodeParentSearch = deleteNodeParent[
                    //     //             filterBy
                    //     //         ]
                    //     //             ? trimStringNonSpecial(
                    //     //                   deleteNodeParent[filterBy]
                    //     //               )
                    //     //             : '';
                    //     //         if (deleteNodeParent.children && Array.isArray(deleteNodeParent.children)) {
                    //     //             // deleteNodeParent.children.forEach(deleteNodeChild => {
                    //     //             // });
                    //     //                 deleteNodeParent.children =
                    //     //                     deleteNodeParent.children.filter(
                    //     //                         (item) =>
                    //     //                             item[filterBy] !==
                    //     //                             parentNode[filterBy]
                    //     //                     );
                    //     //                 // console.log('deleteNodeParent', deleteNodeParent);
                    //     //                 // trong trường hợp node bị filter mất đi children, kiểm tra xem node mẹ có trùng với search ko
                    //     //                 let compare3 = await compareSimilarStr(
                    //     //                     deleteNodeParentSearch,
                    //     //                     searchString
                    //     //                 );
                    //     //                 if (
                    //     //                     deleteNodeParent.children.length ===
                    //     //                         0 &&
                    //     //                     !compare3
                    //     //                     // !deleteNodeParentSearch.includes(searchString)
                    //     //                 ) {
                    //     //                     // console.log(
                    //     //                     //     'deleteNodeParent.label = null',
                    //     //                     //     _.cloneDeep(deleteNodeParent)
                    //     //                     // );
                    //     //                     /*--------- logic cu ---------*/
                    //     //                     deleteNodeParent.label = null;
                    //     //                     deleteNodeParent[filterBy] = null;
                    //     //                     // delete deleteNodeParent.children;
                    //     //                     deleteNodeParent.children = [];
                    //     //                     /*--------- logic moi ---------*/
                    //     //                 }
                    //     //         } else if (
                    //     //             deleteNodeParentSearch ===
                    //     //             parentNode[filterBy]
                    //     //         ) {
                    //     //             //
                    //     //             //
                    //     //             deleteNodeParent.label = null;
                    //     //             deleteNodeParent[filterBy] = null;
                    //     //         }
                    //     //     }
                    //     // );
                    // }
                }
            });

            await wait(1);

            let continueRecursive = false;

            await recursiveTree(treeValueReplace, async (node) => {
                try {
                    if (Array.isArray(node.children)) {
                        if (node.children.length === 0) {
                            continueRecursive = true;
                            //
                            delete node.children;
                            //
                        }
                    }
                } catch (error) {}
            });
            await wait(1);

            // if (!continueRecursive) {
            //

            //     return treeValueReplace;
            // } else {
            //     debugger;
            //     treeValueReplace = await recursiveSearch(
            //         _.cloneDeep(treeValueReplace),
            //         searchString,
            //         filterBy
            //     );
            // }

            await wait(1);

            // delete entry with empty label
            try {
                // treeValueReplace = await removeEmptyFields(treeValueReplace, filterBy);
                //     await recursiveTree(treeValueReplace, async (deleteNodeParent) => {
                //         if (Array.isArray(deleteNodeParent.children)) {
                //             //
                //             deleteNodeParent.children = deleteNodeParent.children.filter(
                //                 (item) => (item.label != null)
                //             );
                //         }
                //     });
                treeValueReplace = await removeEmptyFields(
                    treeValueReplace,
                    filterBy,
                    searchString
                );
            } catch (error) {

            }

            if (JSON.stringify(oldTreeValueReplace) === JSON.stringify(treeValueReplace)) {
                break;
            }
            if (continueRecursive) {
                oldTreeValueReplace = _.cloneDeep(treeValueReplace);
                continue;
            } else {
                break;
            }
        }
    } catch (error) {

    } finally {
        //
        return treeValueReplace;
    }
};

/*
partial load (hydrate) for performance reasons
*/
export const hydrateTreeNode = async (tree) => {
    recursiveTree(tree, (node) => {
        const { groupCode, groupName, status } = node;

        node.key =
            (node.data.appCode || node.data.appName) +
            Constants.SYMBOL.UNIQUE_APP_CHARACTOR +
            groupCode;
        node.label = (
            <>
                <span data-not-allow={node?.roleTypeId} data-status={node.status}>
                    {groupName}
                </span>
            </>
        );
        node.icon = status > 0 ? 'pi pi-unlock' : 'pi pi-lock';
        node.isCallMenu = false;
        node.data = {
            ...node.data,
            label: groupName,
        };
        if (node.groupName) {
            node.search = node.groupName;
        } else if (node.appName) {
            node.search = node.appName;
        }

        if (node.children) {
            hydrateTreeNode(node.children);
        }
    });
};
export const hydrateTreeNodeByNode = async (node) => {
    const { groupCode, groupName, status } = node;

    node.key =
        (node.data.appCode || node.data.appName) +
        Constants.SYMBOL.UNIQUE_APP_CHARACTOR +
        groupCode;
    node.label = <span data-status={node.status}>{groupName}</span>;
    node.icon = status > 0 ? 'pi pi-unlock' : 'pi pi-lock';
    node.isCallMenu = false;
    node.data = {
        ...node.data,
        label: groupName,
    };
    if (node.groupName) {
        node.search = node.groupName;
    } else if (node.appName) {
        node.search = node.appName;
    }
};

export const deleteDisabledNode = async (treeData) => {
  await recursiveTree(treeData, (node) => {
    if (node.children) {
        node.children = node?.children.filter(childNode => childNode?.status !== STATUS.INACTIVE);
    }
  });


};

export const deleteNodeByAttributeValue = async (
    treeData,
    attributeName,
    attributeValue,
    objectKeys = false
) => {

    await recursiveTree(treeData, (node) => {
        try {
            if (node.children) {
                node.children.forEach((childNode) => {
                    let comparisonValue = childNode[attributeName];
                    if (objectKeys) {
                        comparisonValue = Object.keys(childNode[attributeName]).length;
                    }

                    if (comparisonValue === attributeValue) {
                        try {
                            var index = node.children.indexOf(childNode);
                            if (index !== -1) {
                                node.children = node.children.filter((a, i) => i !== index);
                            }
                        } catch (error) {

                        }
                    }
                });
            }
        } catch (error) {

        }
    });

};

export const wait = (ms) => new Promise((res) => setTimeout(res, ms));

export const validateUploadFileParam = (file, fileList = [], state) => {
    try {
        const arrayignoreListFile = state.ignoreListFile.split(',');
        let maxFileSize = Number(state.maxFileSize) * 1024 * 1024;
        let isValid = true;

        if (fileList.length >= state.maxCountFile) {
            showMessageBox(
                <FTUTrans ns='common' name='error.fileExceededAmount' />,
                <FTUTrans ns='common' name='msg.message' />
            );
            isValid = false;
        }
        if (arrayignoreListFile.includes(file.type)) {
            showMessageBox(
                <FTUTrans ns='common' name='error.fileUnauthorized' />,
                <FTUTrans ns='common' name='msg.message' />
            );
            isValid = false;
        }
        if (file.size > maxFileSize) {
            showMessageBox(
                <FTUTrans ns='common' name='error.fileExceededLimit' />,
                <FTUTrans ns='common' name='msg.message' />
            );
            isValid = false;
        }

        return isValid;
    } catch (e) {

        showErrorBox(e, <FTUTrans ns='common' name='msg.error' />);
    }
};

export const validateUploadFile = (file, state , message) => {
    try {
        let fileList = state.fileList;
        const arrayignoreListFile = state.ignoreListFile.split(',');
        let maxFileSize = Number(state.maxFileSize) * 1024 * 1024;
        let isValid = true;

        if (fileList.length >= state.maxCountFile) {
            showMessageBox(
                <FTUTrans ns='common' name='error.fileExceededAmount' />,
                <FTUTrans ns='common' name='msg.message' />
            );
            isValid = false;
        }
        if (arrayignoreListFile.includes(file.type)) {
            showMessageBox(
                <FTUTrans ns='common' name='error.fileUnauthorized' />,
                <FTUTrans ns='common' name='msg.message' />
            );
            isValid = false;
        }
        console.log(maxFileSize)
        if (file.size > maxFileSize) {
            showMessageBox(
                <FTUTrans ns='common' name={`error.fileExceededLimit`} vari={{size : state.maxFileSize}}/> ,
                <FTUTrans ns='common' name='msg.message' />
            );
            isValid = false;
        }

        return isValid;
    } catch (e) {

        showErrorBox(e, <FTUTrans ns='common' name='msg.error' />);
    }
};

const removeEmptyTreeNode = (treeData) => {

    try {
        if (treeData) {
            treeData = treeData.filter(
                (treeNode) =>
                    treeNode &&
                    !(Array.isArray(treeNode) && treeNode?.length === 0)
            );
        }
    } catch (error) {

    }
    return treeData;
};

const removeDulplicatedTree = (tree, codeName) => {
    tree.forEach(async (item, index) => {
        tree.forEach(async (itemCheck, index2) => {
            if (item[codeName] === itemCheck[codeName] && index !== index2) {
                tree.splice(index2, 1);
                tree = removeDulplicatedTree(tree, codeName);
                return tree;
            }
        });
    });
    return tree;
};

export const buildTree = (data, codeName = 'code', labelName = 'nameVn') => {
    //
    let tree = [];
    try {
        if (data.length > 0) {
            for (const item of data) {
                // tìm xem có node parent ko. Nếu ko có thì build một node fake
                let parent = [];
                parent = buildTreeParent(data, item, [], {}, codeName, labelName);
                // data = buildTreeParent(data, item, null, {}, codeName, labelName).data;
                if (parent) {
                    tree.push(parent);
                }
            }
        }
        // delete dulpicated tree
        tree = removeDulplicatedTree(tree, codeName);
        tree = removeEmptyTreeNode(tree);
    } catch (error) {

    }
    return [
        {
            [labelName]: 'Tất cả',
            nameEn: 'All',
            treeSelectLabel: 'Tất cả',
            [codeName]: -1,
            children: tree,
            // disableCheckbox: true,
        },
    ];
};

export const treeSelectLabelGen = async (treeSelectData, label, code) => {
    await recursiveTree(treeSelectData, async (treeNode) => {
        try {
            treeNode.treeSelectLabel = treeNode[label] + ' — ' + treeNode[code];
        } catch (error) {

        }
    });
    return treeSelectData;
};

const buildTreeChildren = (data, parent, rootParentCode = {}, codeName, labelName) => {
    const tree = [];
    for (const item of data) {
        if (
            item.parentCode === parent[codeName] ||
            item.parentCode === rootParentCode.null ||
            item.parentCode === rootParentCode.empty
        ) {
            const children = buildTreeChildren(data, item[codeName], {}, codeName, labelName);
            if (children.length > 0) {
                item.children = children;
            }
            tree.push(item);
        }
    }
    return tree;
};

const buildTreeParent = (data, child, tree, rootParentCode = {}, codeName, labelName) => {
    let parent = null;
    if (child.level > 0) {
        for (const item of data) {
            item.search = item[labelName];
            item.treeSelectLabel = item[labelName] + ' — ' + item[codeName];
            if (
                item[codeName] === child?.parentCode ||
                item.parentCode === rootParentCode.null ||
                item.parentCode === rootParentCode.empty
            ) {
                const children = buildTreeChildren(data, item, {}, codeName, labelName);
                if (children.length > 0) {
                    item.children = children;
                }
                parent = item;
            }
        }
        if (!parent && child.level > 1) {
            // parent = axios.post(GET_IHRP_BY_CODE + '?code=' + child.parentCode);
            // parent = await ftuService.getIhrpByCode(child.parentCode);
            if (!parent) {
                parent = _.cloneDeep(child);
                parent[labelName] = '---';
                // parent[labelName] = child.parentCode + '_Parent';
                parent.nameEn = parent[labelName];
                parent[codeName] = child.parentCode;
                parent.parentCode = child.parentCode + '_Parent';
                parent.level = child.level - 1;
            }
            parent.disableCheckbox = true;
            // data.push(parent);
            parent.children = [child];
            tree = parent;
        } else if (child.level === 1) {
            tree = child;
        }
        if (parent) {
            tree = buildTreeParent(data, parent, tree, {}, codeName, labelName);
        }
    }
    return tree;
};

export const filterOutPartialChecked = async (selectionKeys) => {

    let newSelectionKeys = _.cloneDeep(selectionKeys);
    for (let item of Object.keys(selectionKeys)) {
        try {
            if (!newSelectionKeys[item].checked && newSelectionKeys[item].partialChecked) {
                delete newSelectionKeys[item];
            }
        } catch (error) {}
    }
    return newSelectionKeys;
};

export default function computedStyleToInlineStyle(element, options) {
    if (!element) {
        throw new Error('No element specified.');
    }

    if (options?.recursive) {
        Array.prototype.forEach.call(element.children, (child) => {
            computedStyleToInlineStyle(child, options);
        });
    }

    const computedStyle = getComputedStyle(element);
    Array.prototype.forEach.call(options?.properties || computedStyle, (property) => {
        element.style[property] = computedStyle.getPropertyValue(property);
    });
}
export const addStatusStringToDatalist = async (dataList, dataLabelList, dataName) => {
    await recursiveTree(dataList, async (node) => {
        try {
            dataLabelList.forEach((dataLabel) => {
                if (node[dataName] === dataLabel.value) {
                    node[dataName + 'String'] = dataLabel.value + ' ' + dataLabel.label;
                }
            });
        } catch (error) {

        }
    });
    return dataList;
};

export const getCompanyLogo = () => {
    let userStorage = JSON.parse(localStorage?.getItem('userBasicInfo'));

    return userStorage?.companyLogo;
};
