import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { showConfirm } from './MessageBox';
import { FTUTrans } from 'components/FTUComponent';
import IconSVG from '../containers/app/IconSVG';
import { Tooltip } from 'antd';
import { ACTION } from '../constants/AppProps';

const _ = require('lodash');
export var showDialog = function (_content, _title, _style = {}, _isZoom = false) {
    this.setState({
        open: true,
        title: _title,
        content: _content,
        style: _style,
        isZoom: _isZoom,
    });
};

export var hideDialog = function (_showConfirm, callback = () => {}) {
    const options = this.state.content?.props?.options;
    
    if (
        _showConfirm &&
        !_.isEqual(options?.data, options?.dataNew) &&
        options?.action !== ACTION.VIEW
    ) {
        showConfirm(
            <FTUTrans ns='common' name='msg.confirmClose' />,
            () => {
                this.setState({ open: false, isZoom: false }, () => callback());
            },
            <FTUTrans ns='common' name='msg.title' />
        );
    } else {
        this.setState({ open: false, isZoom: false }, () => callback());
    }
};

class Dialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        showDialog = showDialog.bind(this);
        hideDialog = hideDialog.bind(this);
    }
    toggleZoom = () => {
        this.setState({
            isZoom: !this.state.isZoom,
        });
    };
    render() {
        let urlPathId = new URLSearchParams(window.location.search).get('urlPathId');

        let documentLink = '';
        try {
            
            let documentItem = JSON.parse(localStorage.currentMenu).find((item) =>
                _.includes(item.to, 'documents')
            );
            documentLink = documentItem.to.substring(1);
            
            
        } catch (error) {}

        return (
            <div>
                <Modal
                    style={this.state.style}
                    isOpen={this.state.open}
                    toggle={hideDialog}
                    className={`modal-primary ${this.state.isZoom && 'zoom'}`}
                    backdrop='static'
                >
                    <ModalHeader toggle={hideDialog}>
                        <div className='center-row-y center-row-pi'>
                            {this.state.title}
                            {/* <Tooltip
                                placpement='leftBottom'
                                title={<FTUTrans ns='common' name='msg.toolTipDocument' />}
                            >
                                <a
                                    className='ml-2 center-row-y center-row-pi flex-100-row d-inline'
                                    href={documentLink + `&moduleId=` + urlPathId}
                                >
                                    <div className='m-auto d-inline'>{IconSVG.QuestionMark}</div>
                                </a>
                            </Tooltip> */}
                            <button className='zoomIn-btn close' onClick={this.toggleZoom}>
                                <img src='./assets/layout/images/expand.svg' />
                            </button>
                        </div>
                    </ModalHeader>
                    {this.state.content}
                </Modal>
            </div>
        );
    }
}

export default Dialog;
