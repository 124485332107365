import { Modal as ModalAntd } from 'antd';
import { hideDialog, showDialog } from 'components/Dialog';
import Datatable from 'components/category/Datatable';
import Constants from 'constants/Constants';
import { Column } from 'primereact/column';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Label from 'reactstrap/es/Label';
import { FTUComponent } from '../../../components/FTUComponent';
import {
    showConfirm,
    showSuccessBox,
    showWarningBox,
} from '../../../components/MessageBox';
import { CommonButton, CommonInputText } from '../../../components/common';
import { UserInfoService } from '../../../services/admin/UserInfoService';
import SearchForm from './SearchForm';
import './UserInfor.scss';
import ButtonLink from '../../../components/ButtonLink';
import { ListGroupUserService } from '../../../services/admin/ListGroupUserService';
import Icon from '../../../constants/Icon';
import CustomLabel from '../../../components/CustomLabel';



class LinkedAccountPanel extends FTUComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.linkedAccount,
      totalRecords: 0,
      rows: 10,
      resendTimeout: 60, // Timeout in seconds
      remainingTime: 60, // Remaining time in seconds
      intervalId: null, // Interval ID for the timer
    };
    this.UserService = new UserInfoService();
    this.GroupUserService = new ListGroupUserService();
  }

  /**========================================================================
   **                           componentDidMount
   *? Khởi động sau khi load
   *========================================================================**/
  componentDidMount = () => {};

  // status > 0 ? 'pi pi-unlock' : 'pi pi-lock'

  genStatusCol = (data, column) => {
    return (
      <div className={"flex-container flex-center"}>
        <i className={data.status > 0 ? "" : "pi pi-lock"}></i>
        {data.status > 0 ? "Hoạt động" : "Không hoạt động"}
      </div>
    );
  };

  genUserTypeCol = (data) => {
    // data = this.trans(`common:userType.${data}`);
    // return data || '';
    const userTypeStrings = {
      employee: "Nhân viên",
    };
    return userTypeStrings[data.userType] || "";
  };

  getSearchForm() {
    return (
      <SearchForm
        disableLink
        options={{
          hideDialog: () => hideDialog(true),
          trigger: () => this.props.trigger(),
          genStatusCol: this.genStatusCol,
          genUserTypeCol: this.genUserTypeCol,
          isAdmin: false,
          checkDuplicated : this.checkDuplicated
        }}
      ></SearchForm>
    );
  }


  checkDuplicated =  (selectedLinkedAccount) => {
    let tableDataLinkAccNew = this.props.linkedAccount;

    let accExisted = false;

    tableDataLinkAccNew.forEach((linkedAccount) => {
        if (linkedAccount.userId === selectedLinkedAccount.userId) {
            showWarningBox(this.trans('listGroupUserNew:errMess:accountAlreadyLinked'));
            accExisted = true;
            return true;
        }
    });

    return accExisted
};

  onOpenDialogInsertUser = () => {
    showDialog(this.getSearchForm(), "Thêm tài khoản liên kết mới");
  };

  updateTimer = () => {
    this.setState(
     (prevState) => ({
      remainingTime: prevState.remainingTime - 1,
     }),
     () => {
      if (this.state.remainingTime === 0) {
       clearInterval(this.state.intervalId);
      }
     }
    );
   };

  genHeader = (props) => {
    return (
      <div
        className="flex-100"
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Label>{this.trans("listGroupUserNew:linkedAccountList")}</Label>
        <CommonButton
          className="w-70 mt-2"
          label={this.trans("listGroupUserNew:addLinkedAccount")}
          icon="pi pi-plus"
          onClick={this.onOpenDialogInsertUser}
        ></CommonButton>
      </div>
    );
  };

  genActionColLinkedAccount = (data, props) => {
    return (
      <div className={"flex-container"}>
          <ButtonLink
            className="icon"
            title={this.trans("common:button.del")}
            onClick={() => this.onDeleteLinkedAccount(data, props)}
          >
            {Icon.Delete}
          </ButtonLink>
      </div>
    );
  };

  onDeleteLinkedAccount = async (deletedAccount, props) => {
    showConfirm(
      this.trans("common:msg.confirmDelete") +
        " ( " +
        deletedAccount.userName +
        " ) ?",
      async () => {
        // Gọi API để xóa tk liên kết

        try {

          this.handleLink(deletedAccount)

          
        } catch (error) {
          this.commonHandleError(error);
        }
      }
    );
  };

  handleOTPVerification = async () => {
    try {

      const payload = {
        userName: this.props.userName,
        subUsername: this.state.selectedLinkedAccount.userName,
        otp : this.state.otp
      };
      let isStateLinkedAccountListUpdated = false;
      const res = await this.GroupUserService.unlinkAccount(payload);

          if (res?.data?.code === Constants.SERVICE_CODE.SUCC_CODE) {
            isStateLinkedAccountListUpdated = true;
            this.props.trigger()
            showSuccessBox(
              this.trans("listGroupUserNew:errMess.accountUnlinked1") +
                " " +
                this.state.selectedLinkedAccount.userName +
                " " +
                this.trans("listGroupUserNew:errMess.accountUnlinked2")
            );
            
          } else if (res?.data?.code !== Constants.SERVICE_CODE.SUCC_CODE) {
            this.commonHandleError(res);
          }

          if (isStateLinkedAccountListUpdated) {
            let linkedAccountListNew = this.state.data;

            for (const [i, value] of linkedAccountListNew.entries()) {
              if (
                linkedAccountListNew[i].userName === this.state.selectedLinkedAccount.userName
              ) {
                linkedAccountListNew.splice(i, 1);
              }
            }

            await this.setState({
              data: linkedAccountListNew,
              isOTPModalOpen : false
            });
          }
    } catch (error) {
      
    }
  };

  handleResendOTP = async () => {
    const res = await this.UserService.getOtpUnlinked(
      this.state.selectedLinkedAccount
    );
    if (res && res.data) {
      if (res.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
        this.setState({
          errorMsg: null,
          remainingTime: this.state.resendTimeout,
          intervalId: setInterval(this.updateTimer, 1000),
        });
      } else {
        this.setState({ errorMsg: `${res.data.data}` });
      }
    }
  };
  handleLink = async (rowData) => {
    this.setState({
        isOTPModalOpen : true,
        selectedLinkedAccount: rowData
    })

    const res = await this.UserService.getOtpUnlinked(rowData);

    if (res.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
        this.setState({
            requestOTPBy: res.data.data.message,
            remainingTime: this.state.resendTimeout,
            intervalId: setInterval(this.updateTimer, 1000),
        });
    }
  };

  handleOTPChange = (e) => {
    const input = e.target.value;
    //Loại bỏ ký tự không phải là số
    const numericInput = input.replace(/\D/g, "");

    // Nhập tối đa 6 ký tự
    const maxLength = 6;
    const truncatedInput = numericInput.slice(0, maxLength);

    // Lưu vào state
    this.setState({ errorMsg: null, otp: truncatedInput });
  };

  onClose = () => {
    clearInterval(this.state.intervalId)
    this.setState({
      totalRecords: 0,
      rows: 10,
      resendTimeout: 60, // Timeout in seconds
      remainingTime: 60, // Remaining time in seconds
      intervalId: null,
      isOTPModalOpen : false,
      otp : ''
    })
  }
  render = () => {
    
      (this.props.linkedAccount || []).map((e) => {
        try {
          e.org = e.jobTitles[0].orgCode + " - " + e.jobTitles[0].orgName;
        } catch (error) {
          e.org = "";
        }
      });
      
      const {
        otp,
        requestOTPBy,
        remainingTime,
        errorMsg,
        resendOTP,
      } = this.state;
      
      return (
        <>
          <ModalAntd
            visible={this.state.isOTPModalOpen}
            onCancel={this.onClose}
            title={this.trans("EnterOTP")}
            footer={[
              <CommonButton
                label={this.trans("send")}
                type="primary"
                onClick={this.handleOTPVerification}
              />,
            ]}
            zIndex={100}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <CustomLabel>
                {this.trans("OTPSentTo")}
                <strong>{requestOTPBy}</strong>
              </CustomLabel>
              <CommonInputText value={otp} onChange={this.handleOTPChange} />
              <div hidden={!errorMsg} style={{ color: "red" }}>
                {errorMsg}
              </div>
              {remainingTime > 0 ? (
                <div>
                  <i>{this.trans("OTPResendCode1")}</i>
                  <p>
                    {this.trans("OTPResendCode2") +
                      remainingTime +
                      this.trans("OTPResendCode3")}
                  </p>
                </div>
              ) : (
                <div>
                  {resendOTP ? (
                    <></>
                  ) : (
                    <button
                      style={{
                        border: "none",
                        textDecoration: "underline",
                        padding: "0",
                        cursor: "pointer",
                        background: "none",
                      }}
                      onClick={this.handleResendOTP}
                    >
                      {this.trans("resend")}
                    </button>
                  )}
                </div>
              )}
            </div>
          </ModalAntd>
          <div className="userinfo-leftpanel row col-md-12">
            <div className="flex-100">
              <Datatable
                header={this.genHeader()}
                value={this.props.linkedAccount}
                first={this.state.first}
                rows={this.state.rows}
                scrollable={true}
                selectionMode="single"
              >
                <Column
                  sortable
                  field="userName"
                  header={this.trans("username")}
                  style={{
                    height: "5.5em",
                    width: "15%",
                    textAlign: "center",
                  }}
                ></Column>
                <Column
                  sortable
                  field="fullName"
                  header={this.trans("fullName")}
                  style={{
                    width: "20%",
                    textAlign: "center",
                  }}
                ></Column>
                <Column
                  sortable
                  field="org"
                  header={this.trans("Tên công ty")}
                  style={{
                    width: "30%",
                    textAlign: "center",
                  }}
                ></Column>
                <Column
                  sortable
                  field="alias"
                  header={this.trans("nickname")}
                  style={{
                    width: "20%",
                    textAlign: "center",
                  }}
                ></Column>
                <Column
                  sortable
                  field="userType"
                  header={this.trans("userType")}
                  body={this.genUserTypeCol}
                  style={{
                    width: "15%",
                    textAlign: "center",
                  }}
                ></Column>
                <Column
                  sortable
                  field={`status`}
                  body={this.genStatusCol}
                  header={this.trans("status")}
                  style={{
                    textAlign: "center",
                    width: "10%",
                  }}
                ></Column>
                <Column
                  body={(e) => this.genActionColLinkedAccount(e)}
                  header={this.trans("actionCol")}
                  style={{
                    textAlign: "center",
                    width: "10%",
                  }}
                />
              </Datatable>
            </div>
          </div>
        </>
      );
  };
}

export default withTranslation(["listGroupUserNew", "common"])(LinkedAccountPanel);