import React from "react";
import PropTypes from "prop-types";
import SelectOption from "./category/SelectOption";

class CustomLabel extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    return (
      <label>
        {this.props.children}
        {this.props.required ? <b style={{ color: 'red' }} className="requireStyle">*</b> : null}
      </label>
    );
  }
}

export default CustomLabel;
CustomLabel.propTypes = {
  required: PropTypes.bool
};
