import { GET_TYPE_OBJECT, UPDATE_JOB_TITLE_ROLE } from '../../constants/AppPath';
import {Utils} from '../common/utils';
import {
    GET_ALL_USER_BY_JOB_TITLE,
    GET_JOB_TITLE, GET_JOB_TITLE_ON_IHRP,
    GET_JOB_TITLE_ROLE,
    GET_JOB_TITLE_ROLE_BY_IHRP_CODE,
    GET_JOB_TITLE_ROLE_BY_CODE,
    REMOVE_JOB_TITLE_ROLE,
    REMOVE_LIST_JOB_TITLE_ROLE,
    SAVE_JOB_TITLE_ROLE,
    GET_JOB_POSITION
} from 'constants/AppPath';

class JobTitleRoleService {

    getJobTitleRole = (payload) => {
        return Utils.post(GET_JOB_TITLE_ROLE, payload);
    };

    getJobTitleRoleByIhrpCode = (payload) => {
        return Utils.post(GET_JOB_TITLE_ROLE_BY_IHRP_CODE, payload)
    }
    saveJobTitleRole = (payload) => {
        return Utils.post(SAVE_JOB_TITLE_ROLE, payload);
    };

    getRolesByListJobTitleCode = (payload) => {
        return Utils.post(GET_JOB_TITLE_ROLE_BY_CODE, payload);
    };

    getAllUserByJobTitle = (payload) => {
        return Utils.post(GET_ALL_USER_BY_JOB_TITLE, payload, null, true)
    }


    deleteJobTileRole(data) {
        return Utils.post(REMOVE_JOB_TITLE_ROLE, data, null);
    }

    deleteListJobTileRole(data) {
        return Utils.post(REMOVE_LIST_JOB_TITLE_ROLE, data, null);
    }

    getJobTitleIhrp = (payload) => {
        return Utils.post(GET_JOB_TITLE_ON_IHRP, payload)
    }

    getJobTitle = (payload) => {
        return Utils.post(GET_JOB_TITLE, payload);
    };

    getJobPosition = (payload) => {
        return Utils.get(GET_JOB_POSITION , null)
    }
    getTypeObject = () => {
        return Utils.get(GET_TYPE_OBJECT)
    }
    updateJobTitleRole = (payload) => {
        return Utils.post(UPDATE_JOB_TITLE_ROLE , payload , null  , true)
    }
}

export default JobTitleRoleService;
