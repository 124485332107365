import React from "react";
import "antd/dist/antd.css";
import { Button, Tooltip } from "antd";
import Constants from "../constants/Constants";

class ButtonLink extends React.Component {
 constructor(props) {
  super(props);
 }

 render() {
  let listPermissionButton = JSON.parse(localStorage.getItem("permissionBtn") || '[]');
  if (
   this.props.hidden ||
   (this.props?.action &&
    !Array.from(listPermissionButton).includes(
     Constants.BUTTON + this.props?.nameScreen + this.props?.action
    ))
  ) {
   // return <div className={'flex-container'}></div>;
   return <></>;
  }
  return (
   <>
    <Tooltip>
     <Button type="link" {...this.props}>
      {this.props.children}
     </Button>
    </Tooltip>
   </>
  );
 }
}

export default ButtonLink;
