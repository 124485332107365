import {FTUComponent} from '../../../components/FTUComponent';
import {withTranslation} from 'react-i18next';
import React from 'react';
import './ListOfSubSystems.scss';
import {ApplicationListService} from '../../../services/admin/ApplicationListService';
import {Menu} from 'primereact/menu';
import {Menubar} from 'primereact/menubar';
import ButtonLink from '../../../components/ButtonLink';
import {Footer} from 'antd/es/layout/layout';
import Constants from '../../../constants/Constants';
import {BehaviorSubject} from "rxjs";
import {AppFooter} from '../../../containers/app/AppFooter';
import {
    AuthenticationService, getObjectFromCookiesByPrefix, removeCookiesByPrefix, saveObjToCookies
} from '../../../services/access/AuthenticationService';
import {APP_DOMAIN} from '../../../constants/AppPath';
import moment, {months} from 'moment';
import store from '../../../reactRedux/store/store';
import {fetchUser} from '../../../reactRedux/action/userAction';
import AppTopbar from '../../app/AppTopbar';
import IconSVG from '../../app/IconSVG';
import {NotificationService} from '../../../services/admin/NotificationService';
import {UserService} from '../../../services/access/User';
import UserInfoService from '../../../services/admin/UserInfoService';
import {showErrorBox} from '../../../components/MessageBox';
import './NewListOfSubSystems.scss'

const {SubSystemStatus: {ON}} = Constants

class ListOfSubSystems extends FTUComponent {
    constructor(props) {
        super(props);
        this.state = {
            appItem: [], listItemClick: [],
            types: "socket",
            page: 0,
            size: 20,
            sort: "receiveTime,desc",
            count: 0, isLoggin: false,
            userName: ''
        };
        this.app = new ApplicationListService();
        this.notifService = new NotificationService();
        this.infoService = new UserInfoService();
    }

    // componentWillMount = () => {
    //     this.checkLogin()
    // }

    componentWillMount = async () => {
        document.title = 'ERP - Đất Xanh';
        this.onGetAppItem();
    }


    onGetAppItem = async () => {
        let res = await this.app.getAllItem();

        if (res && Array.isArray(res?.data)) {
            this.setState({
                appItem: res.data.filter((ele) => {
                    return ele.status === ON
                })
            });
        }
    };

    onClickItemApp = (data) => {
        if (!localStorage.getItem('access_token')) {
            window.open(data.linkApp);
        }
        //     get item click
        let lstItem = this.state.listItemClick;
        if (lstItem.length === 4) {
            if (lstItem.includes(data) === true) {
                this.setState({
                    listItemClick: lstItem,
                });
            } else {
                lstItem.pop();
                lstItem.splice(0, 0, data);
                this.setState({
                    listItemClick: lstItem,
                });
            }
        } else {
            if (lstItem.length !== 0) {
                if (lstItem.includes(data) === true) {
                    this.setState({
                        listItemClick: lstItem,
                    });
                } else {
                    lstItem.splice(0, 0, data);
                    this.setState({
                        listItemClick: lstItem,
                    });
                }
            } else {
                lstItem.push(data);
                this.setState({
                    listItemClick: lstItem,
                });
            }
        }
    };

    readNoti = () => {
        this.setState({
            count: 0
        })
    }
    handleError = (event) => {
        event.target.onerror = null; // Prevents looping in case the fallback image also fails
        event.target.src = '/assets/layout/images/icon_setting.svg';
    };

    // handlerGetUserName = (value) => {
    //     this.setState({
    //         userName: value
    //     })
    // }
    dataLocal = localStorage.getItem("userBasicInfo");
    render = () => {
        // const path = '/assets/layout/images/placeholder-subsystem.png'
        const path = '/assets/layout/images/icon_setting.svg'
        const pathArrow = '/assets/layout/images/arrow-right.svg'
        return (<>
            <div className='listOfSubSystems'
                 style={{backgroundImage: "url('assets/layout/images/bg-listOfSubSystem.svg')"}}>
                <AppTopbar path="/admin/" readNoti={this.readNoti} count={this.state.count} title={false}
                           logo={IconSVG.FullLogoLabel} notification={true}
                           appItem={this.state.appItem} onClickItemApp={this.onClickItemApp}/>
                    <div className='layout-wrapper layout-overlay' id={'wrap-main-landing'}>
                        <div className='content' id='content-layout' hidden={this.state.isLoggin}>
                            <div className='item'>

                                {/*code new*/}
                                <div className={'warp-box-name'}>
                                    <div className={'box-date-name'}>
                                        <div className="box-date">
                                            <p>{new Date().getDate()} {new Date().toLocaleString('vi-VN', {month: 'long'})}, {new Date().getFullYear()}</p>
                                        </div>
                                        <div className="box-name">
                                            <h3>Chào mừng, {this.dataLocal && JSON.parse(this.dataLocal)?.userName}</h3>
                                        </div>
                                    </div>
                                    <div className="box-bg-content">
                                        <div className={'box-bg'}
                                             style={{backgroundImage: "url('assets/layout/images/3d-casual-life-colleagues-discussing-team-project.svg')"}}></div>
                                    </div>
                                </div>
                                {/*end code*/}

                                <div
                                    className='row'
                                    hidden={this.state.listItemClick.length > 0 ? false : true}>
                                    <div className='name-app'>
                                        <h3 style={{fontSize: '32px'}}>
                                            {this.trans('Truy cập gần đây')}
                                        </h3>
                                    </div>
                                    <div className={'item-container'} style={{justifyContent : 'start'}}>
                                        {this.state.listItemClick.map((item) => {

                                            return (<div
                                                key={item.appId}
                                                className='box-item'>
                                                <div
                                                    className='client-app'
                                                    onClick={() => this.onClickItemApp(item)}>
                                                    <div
                                                        className={'img-app-frames'}>
                                                        <a
                                                            target='_blank'>
                                                            <img
                                                                width={124}
                                                                height={113}
                                                                className={'img-responsive display-inline img-app-margin fadeOut control'}
                                                                onError={this.handleError}
                                                                src={item.listSecFile[0]?.dataFile ?? path}
                                                                alt={item.appCode}
                                                            />
                                                        </a>
                                                    </div>

                                                    <a
                                                        target='_blank'>
                                                        <div className='client-app-item'>
                                                            <h4 className='client-app-name'>
                                                                {item.appName}
                                                            </h4>

                                                            <div className='client-app-des'>
                                                                {item.description}
                                                            </div>
                                                            <a style={{
                                                                marginTop: 'auto',
                                                                color: '#1D1D1F',
                                                                textDecoration: ' none',
                                                                fontWeight: 'bold'
                                                            }} href="javascript:void(0)">Truy cập ngay <img
                                                                src={pathArrow}/></a>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>);
                                        })}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='name-app'>
                                        <h3 style={{color: '#25324B', fontSize: '32px'}}>
                                            {this.trans('Danh sách phân hệ')}
                                        </h3>
                                    </div>
                                    <div className={'item-container'}>
                                        {this.state.appItem.map((item) => {
                                            return (<div
                                                key={item.appId}
                                                className='box-item'>
                                                <div
                                                    className='client-app'
                                                    onClick={() => this.onClickItemApp(item)}>
                                                    <div
                                                        className={'img-app-frames'}>
                                                        <a
                                                            target='_blank'>
                                                            <img
                                                                className={'img-responsive display-inline img-app-margin fadeOut control'}
                                                                onError={this.handleError}
                                                                src={item.listSecFile[0]?.dataFile ?? path}
                                                                alt={item.appCode}
                                                            />
                                                        </a>
                                                    </div>

                                                    <a
                                                        target='_blank'>
                                                        <div className='client-app-item'>
                                                            <h4 className='client-app-name'>
                                                                {item.appName}
                                                            </h4>
                                                            <div className='client-app-des'>
                                                                {item.description}
                                                            </div>

                                                            <a style={{
                                                                marginTop: 'auto',
                                                                color: '#1D1D1F',
                                                                textDecoration: ' none',
                                                                fontWeight: 'bold'
                                                            }} href="javascript:void(0)">Truy cập ngay <img
                                                                src={pathArrow}/></a>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>);
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                {/* Footer begin */}

                {/* Footer end */}
            </div>
                {/*<div className='fixed-children'>*/}
                {/*    <AppFooter text={this.trans('common:app.footer')}/>*/}
                {/*</div>*/}
            <div className="layout-footer-main" style={{borderTop: '1px solid #0000000F'}}
                 style={{backgroundImage: "url('assets/layout/images/bg-listOfSubSystem.svg')"}} id={'wrap-main-landing__footer'}>
                <p style={{color: '#00000073', margin: 0, padding: '15px 5.4%'}}>Copyright © 2024 DAT XANH GROUP.
                    All rights reserved.</p>
                </div>
        </>);
    };
}

export default withTranslation(['ListOfSubSystems', 'common'])(ListOfSubSystems);
