import {lazy} from 'react';

const ApplicationList = lazy(() => import('containers/admin/applicationList/ApplicationList'));
const SecModule = lazy(() => import('containers/admin/sec_module/SecModule'));
const ListGroupUserNew = lazy(() => import('containers/admin/listGroupUserNew/ListGroupUser'));
const ApDomain = lazy(() => import('containers/admin/apDomain/ApDomain'));
const TitlePermission = lazy(() => import('containers/admin/titlePermissionEAP/TitlePermissionEAP'));
const GrantPermission = lazy(() => import('containers/admin/grantPermissionEAP/GrantPermission'));
const TemplateEmailSms = lazy(() => import('containers/admin/templateEmailSms/TemplateEmailSms'));
const MasterDataDistribution = lazy(() => import('containers/admin/masterDataDistribution/MasterDataDistribution'));
const SecChanel = lazy(() => import('containers/admin/secChanel/SecChanel'));
const UserInfo = lazy(() => import('containers/admin/userInfor/UserInfo'));
const AccessLog = lazy(() => import('containers/admin/accessLog/AccessLog'));
const FollowChange = lazy(() => import('containers/admin/followChange/FollowChange'));
const ListOfSubSystems = lazy(() => import('containers/admin/listOfSubSystems/ListOfSubSystems'));
const MasterDataLogMonitor = lazy(() => import('containers/admin/masterDataLogMonitor/MasterDataLogMonitor'));
const RoleHistory = lazy(() => import('containers/admin/role_history/RoleHistory'));
const CreateAccountExternalLog = lazy(() => import('containers/admin/createAccountExternalLog/CreateAccountExternalLog'))
const ListDateAccess = lazy(() => import('containers/admin/listDateAccess/ListDateAccess'));
const DxgDocument = lazy(() => import('containers/admin/dxgDocument/TemporaryDocument'));
const SwitchLog =  lazy(() => import('containers/admin/userInfor/SwitchLog'));
const ApiPartner =  lazy(() => import('containers/admin/apiPartner/ApiPartner'));
const ProviderTemplate = lazy(() => import('containers/admin/emailLogMangement/Provider'))
const LoginLog = lazy(() => import('containers/admin/LoginLog/LoginLog'))
const LogoManagement = lazy(() => import('containers/admin/logoManagement/LogoManagement'));
const AddressPage = lazy(() => import('containers/admin/AddressPage/AddressPage'))
const SendIndividualMessage = lazy(() => import('containers/admin/sendIndividualMessage/SendIndividualMessage'))




const routes = [
    {path:  '/', exact: true, name: 'Home'},
    {path:  '/admin/sec_module', name: 'SecModule', exact: true, component: SecModule},
    {path:  '/admin/applicationList', name: 'ApplicationList', exact: true, component: ApplicationList},
    {path:  '/admin/listGroupUser', name: 'ListGroupUserNew', exact: true, component: ListGroupUserNew},
    {path:  '/admin/followChange', name: 'FollowChange', exact: true, component: FollowChange},
    {path:  '/admin/accessLog', name: 'AccessLog', exact: true, component: AccessLog},
    {path:  '/admin/listScheduleAccess', name: 'ListDateAccess', exact: true, component: ListDateAccess},
    {path:  '/admin/ap_domain', name: 'ApDomain', exact: true, component: ApDomain},
    {path:  '/admin/addTemplateSMSEmail', name: 'TemplateEmailSms', exact: true, component: TemplateEmailSms},
    {path:  '/admin/grantPermissionEAP', name: 'GrantPermission', exact: true, component: GrantPermission},
    {path:  '/admin/titlePermissionEAP', name: 'TitlePermission', exact: true, component: TitlePermission},
    {path:  '/admin/masterDataDistribution', name: 'MasterDataDistribution', exact: true, component: MasterDataDistribution},
    {path : '/admin/secChanel' , name : 'SecChannel', exact: true, component : SecChanel},
    {path : '/admin/userInfo' , name : 'UserInfo', exact: true, component : UserInfo},
    {path : '/admin/listOfSubSystems' , name : 'ListOfSubSystems', exact: true, component : ListOfSubSystems},
    {path : '/admin/masterDataLogMonitor' , name : 'MasterDataLogMonitor', exact: true, component : MasterDataLogMonitor},
    {path : '/admin/role_history' , name :'RoleHistory' , exact : true , component : RoleHistory},
    {path : '/admin/createAccountExternalLog' , name :'CreateAccountExternalLog' , exact : true , component : CreateAccountExternalLog},
    {path:  '/admin/documents', name: 'DxgDocument', exact: true, component: DxgDocument},
    {path : '/admin/switchLog' , name : 'SwitchLog' , exact: true, component: SwitchLog},
    {path : '/admin/api-partner' , name : 'ApiPartner' , exact: true, component: ApiPartner},
    {path : '/admin/emailLogMangement' , name : 'EmailLogMangement' , exact: true, component: ProviderTemplate},
    {path : '/admin/loginLog' , name : 'LoginLog' , exact: true, component: LoginLog},
    {path : '/admin/logoManagement' , name : 'LogoManagement' , exact: true, component: LogoManagement},
    {path : '/admin/addressList' , name : 'AddressList', exact: true, component : AddressPage},
    {path : '/admin/sendIndividualMessage' , name : 'SendIndividualMessage', exact: true, component : SendIndividualMessage},
];

export default routes;
