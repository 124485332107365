import { GET_TOKEN_BY_RF } from "../../constants/AppPath";
import { Utils } from "../common/utils";

export default class KeycloakService {
  
  getTokenByRF (data) {
    return Utils.post(GET_TOKEN_BY_RF , data , null , null , null , null)
  }


  getTokenByCode(data) {
    return Utils.post('/keycloak/get-token-by-code' , data, null ,true , null , null)
  }
}