import { Dropdown, Menu } from 'antd'
import React from 'react'

const NavSystemIcon = (props) => {
  const { appItem, onClickItemApp } = props
  console.log('appItem', appItem)
  const menu = (
    <Menu>
      {appItem &&
        appItem.map((item, index) => (
          <Menu.Item key={index}>
            <div className='box-item-nav' onClick={() => !!onClickItemApp ? onClickItemApp(item) : window.open(item.linkApp)}>
              <h5>{item.appName}</h5>
              <div className='box-image'>
                <img
                  className={
                    'img-responsive display-inline img-app-margin fadeOut control'
                  }
                  src={
                    item.listSecFile[0]?.dataFile ??
                    '/assets/layout/images/icon_setting.svg'
                  }
                  alt={item.appCode}
                />
              </div>
            </div>
          </Menu.Item>
        ))}
    </Menu>
  )

  return (
    <div className={'box-btn-dropdown'}>
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={(e) => e.preventDefault(e)}>
          <img src={'/assets/layout/images/icon_navbar.svg'} />
        </a>
      </Dropdown>
    </div>
  )
}

export default NavSystemIcon
