import React from "react";
import {stringNullOrEmpty} from "../../components/category/CommonUtils";
import {FTUTrans} from "../../components/FTUComponent";
import {showErrorBox} from "../../components/MessageBox";
import {
    DELETE_GROUP,
    DELETE_USER,
    GET_USER_BY_ROLE,
    GET_ALL_SYSTEM_POLICY,
    GET_PATH_GROUP_BY_USER,
    GET_PATH_MODULE_BY_USER,
    GET_PATH_QUERY_LIST_GROUP,
    GET_PATH_QUERY_LIST_GROUP_USER,
    GET_PATH_QUERY_MODULE_GET_ALL,
    GET_PATH_QUERY_MODULE_GROUP,
    GET_PATH_ORG,
    GET_PATH_SUB_ORG,
    GET_PATH_USER_BY_GROUP,
    POST_PATH_LIST_GROUP_GROUP,
    POST_PATH_LIST_GROUP_USER,
    PUT_LIST_GROUP_GROUP_DISABLE,
    PUT_LIST_GROUP_GROUP_ENABLE,
    PUT_LIST_GROUP_USER_UPDATE_GROUP,
    PUT_LIST_GROUP_USER_UPDATE_USER,
    GET_PATH_USER_BY_GROUP_WITHOUT_TREE,
    GET_PATH_QUERY_LIST_GROUP_BY_STATUS,
    CHANGE_PASSWORD,
    INSERT_APP_API,
    GET_PATH_QUERY_LIST_GROUP_USER_WITH_PAGE,
    GET_PATH_USER_BY_GROUP_WITHOUT_TREE_WITH_PAGE,
    GET_USER_BY_GROUP_WITH_PAGE,
    GET_GROUP_BY_CODE,
    GET_GROUP_BY_LIST_CODE,
    GET_GROUPS_BY_ACCOUNT,
    GET_ALL_USER,
    GET_USER_NO_ACCOUNT,
    SEARCH_USER_NO_ACCOUNT,
    GET_ALL_ACCOUNT_BY_CONDITION,
    GET_ACCOUNT_BY_CONDITION,
    GET_GROUP_BY_USER,
    GET_GROUP_BY_ACCOUNT,
    GET_PASSWORD_STATUS,
    GET_ALL_CHILDREN_ROLE,
    GET_ORG_TREE_DATA,
    GET_RESOURCE_TYPE_LIST,
    GET_DATA_BY_RESOURCE_TYPE,
    SAVE_PERMISSION,
    GET_PERMISSION,
    GET_USER_INFO_BY_USER_NAME,
    DELETE_PERMISSION,
    IS_EXIST_PERMISSION, INACTIVE_ACCOUNT,
    GET_DEPT, GET_LINKED_ACCOUNT,
    UNLINK_ACCOUNT,
    RESET_PASSWORD_EMAIL,
    ACTIVE_GROUP,
    DELETE_USER_FROM_GROUP,
    UNLINK_OTP,
} from "../../constants/AppPath";
import Constants from "../../constants/Constants";
import {Utils} from "../common/utils";

export class ListGroupUserService {
    /*
      Viết các hàm gọi API ở đây
      */
    async getListGroup() {
        return this.onMessage(
            await Utils.get(GET_PATH_QUERY_LIST_GROUP, null, null, true)
        );
    }

    async getListGroupByStatus(status) {
        return this.onMessage(
            await Utils.get(GET_PATH_QUERY_LIST_GROUP_BY_STATUS, {status: status}, null, true)
        );
    }

    async getListUser() {
        return this.onMessage(
            await Utils.get(GET_PATH_QUERY_LIST_GROUP_USER, null, null, true)
        );
    }

    getListUserWithPage = (data) => {
        return Utils.post(GET_PATH_QUERY_LIST_GROUP_USER_WITH_PAGE, data, null, true);
    }

    async getModuleByUser(id) {
        return Utils.get(GET_PATH_MODULE_BY_USER, {userId: id}, null, true);
    }

    async postListUser(data) {
        return Utils.post(POST_PATH_LIST_GROUP_USER, data, null, true);
    }

    // async deleteUser(id, func) {
    //     return this.onMessage(await Utils.del(DELETE_USER, {userId: id}, func, true));
    // }
    deleteUser(payload) {
        return Utils.post(DELETE_USER, payload);
    }

    inactiveAccount(payload) {
        return Utils.post(INACTIVE_ACCOUNT, payload);
    }

    async getUserByGroup(id) {
        return this.onMessage(
            await Utils.get(GET_PATH_USER_BY_GROUP, {groupId: id}, null, true)
        );
    }

    async getUserByRole(payload) {
        return await Utils.post(GET_USER_BY_ROLE, payload, null, false)
    }

    // getUserAll(payload) {
    //     return Utils.post(GET_ALL_USER, payload, null, true)
    // }

    getAllUser(payload) {
        return Utils.get(GET_ALL_USER, payload, null);
    }

    getAllAccountByCondition(payload) {
        return Utils.post(GET_ALL_ACCOUNT_BY_CONDITION, payload);
    }

    getAccountByCondition(payload) {
        return Utils.post(GET_ACCOUNT_BY_CONDITION, payload , null , false);
    }

    getUserNoAcount(payload) {
        return Utils.post(GET_USER_NO_ACCOUNT, payload, null, true)
    }

    searchUserNoAcount(payload) {
        return Utils.post(SEARCH_USER_NO_ACCOUNT, payload, null, true)
    }

    async getPasswordStatus(payload) {
        return await Utils.post(GET_PASSWORD_STATUS, payload)
    }

    async getGroupByAccount(payload) {
        return await Utils.post(GET_GROUP_BY_ACCOUNT, payload);
    }

    async getGroupsByAccount(payload, account) {
        return await Utils.post(GET_GROUPS_BY_ACCOUNT + "?account=" + account, payload , null , false)
    }

    async getGroupByUser(payload) {
        return this.onMessage(
            await Utils.get(GET_PATH_GROUP_BY_USER, payload, null)
        );
    }

    async getAllChilrenRole(payload) {
        return this.onMessage(
            await Utils.post(GET_ALL_CHILDREN_ROLE, payload, null)
        );
    }

    async putUpdateUser(data) {
        return await Utils.post(PUT_LIST_GROUP_USER_UPDATE_USER, data, null, true);
    }

    async getModuleAll() {
        return this.onMessage(
            await Utils.get(GET_PATH_QUERY_MODULE_GET_ALL, null, null, true)
        );
    }

    async deleteGroup(id, func) {
        return this.onMessage(await Utils.del(DELETE_GROUP, {groupCode: id}, func));
    }

    async putGroupDisable(id) {
        return this.onMessage(
            await Utils.put(
                Utils._urlRender(PUT_LIST_GROUP_GROUP_DISABLE, {groupId: id}, false),
                null,
                null
            )
        );
    }

    async putGroupEnable(id) {
        return this.onMessage(
            await Utils.put(
                Utils._urlRender(PUT_LIST_GROUP_GROUP_ENABLE, {groupId: id}, false),
                null,
                null
            )
        );
    }

    async getModuleByGroup(id) {
        return this.onMessage(
            await Utils.get(GET_PATH_QUERY_MODULE_GROUP, {groupCode: id}, null)
        );
    }

    async putUpdateGroup(data) {
        return await Utils.put(PUT_LIST_GROUP_USER_UPDATE_GROUP, data, null, true);
    }

    async postListGroup(data) {
        return await Utils.post(POST_PATH_LIST_GROUP_GROUP, data, null, true);
    }

    async getOrg() {
        return this.onMessage(await Utils.get(GET_PATH_ORG, null, null, true));
    }

    async getSubShop(data) {
        return await Utils.get(GET_PATH_SUB_ORG, {orgCode: data}, null);
    }

    async getSystemPolicy() {
        return this.onMessage(await Utils.get(GET_ALL_SYSTEM_POLICY, null, null));
    }

    onMessage = (rs) => {
        if (rs && rs.data && rs.data.code != Constants.SERVICE_CODE.SUCC_CODE) {
            this.mapMessage(rs);
            return [];
        } else {
            return rs;
        }
    };

    mapMessage = (rs) => {
        let code = rs.data.code;
        let message = rs.data.message;
        if (!stringNullOrEmpty(code)) {
            switch (code) {
                case "API500":
                    showErrorBox(<FTUTrans ns="common" name={"msg." + code}/>);
                    break;
                case "API504":
                    showErrorBox(<FTUTrans ns="common" name={"msg." + code}/>);
                    break;
                default:
                    showErrorBox("Lỗi :" + rs.data.message);
                    break;
            }
        } else {
            showErrorBox("Lỗi :" + rs.data.message);
        }
    };

    async getUserByGroupWithoutTree(id) {
        return this.onMessage(
            await Utils.get(GET_PATH_USER_BY_GROUP_WITHOUT_TREE, {groupId: id}, null, true)
        );
    }

    getUserByGroupIdWithoutTreeWithPage = (data) => {
        return Utils.post(GET_PATH_USER_BY_GROUP_WITHOUT_TREE_WITH_PAGE, data, null);
    }
    getUserByGroupWithPage = (data) => {
        return Utils.post(GET_USER_BY_GROUP_WITH_PAGE, data, null);
    }

    getGroupByCode = (data) => {
        return Utils.post(GET_GROUP_BY_CODE, data, null , false);
    }

    getGroupByListCode = (data) => {
        return Utils.post(GET_GROUP_BY_LIST_CODE, data, null , true);
    }

    getOrgTreeData(payload) {
        return Utils.post(GET_ORG_TREE_DATA, payload);
    }

    getResourceTypeList(payload) {
        return Utils.post(GET_RESOURCE_TYPE_LIST, payload , null , true);
    }

    getDataByResourceType(payload) {
        return Utils.post(GET_DATA_BY_RESOURCE_TYPE, payload , null , true);
    }

    savePermisson(payload) {
        return Utils.post(SAVE_PERMISSION, payload);
    }

    getPermisson(payload) {
        return Utils.post(GET_PERMISSION, payload);
    }

    getUserInfoByUserName(payload) {
        return Utils.post(GET_USER_INFO_BY_USER_NAME, payload);
    }

    deletePermission(payload) {
        return Utils.post(DELETE_PERMISSION, payload);
    }

    isExistPermission(payload) {
        return Utils.post(IS_EXIST_PERMISSION, payload);
    }
    getDept() {
        return Utils.getByUrl(GET_DEPT)
    }
    // getLinkedAccount() {
    //     return Utils.getByUrl(GET_LINKED_ACCOUNT)
    // }
    createLinkedAccount(payload) {
        return Utils.post(GET_LINKED_ACCOUNT, payload)
    }
    unlinkAccount(payload) {
        return Utils.post(UNLINK_ACCOUNT, payload , null , true)
    }

    async resetPassword(payload) {
        return Utils.post(RESET_PASSWORD_EMAIL, payload)
    }

    activeGroup(payload) {
        return Utils.get(ACTIVE_GROUP , payload , null , true)
    }
    removeAccountFromGroup (payload) {
        return Utils.post(DELETE_USER_FROM_GROUP , payload , null , true)
    }

    // getOtpUnlinked = (payload) => {
    //     return Utils.post(UNLINK_OTP , payload , null , true)
    // }
}
