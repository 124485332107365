import React, { useMemo } from "react";
import moment from "moment";

import { DatePicker } from "antd";
import "./CommonDatePicker.scss";
import { FTUTrans } from "../../FTUComponent";
import ReactDomServer from "react-dom/server";

const isPlainObject = (value) => value?.constructor === Object;

const convertSpecialToDateObject = (value) => {
 let dateConvert = "";

 if (dateConvert && typeof value === "string") {
  dateConvert = moment(value, "DD/MM/YYYY");
 }

 if (value instanceof moment) {
  dateConvert = value;
 }

 if (isPlainObject(value)) {
  dateConvert = moment(value.value, value.format);
 }

 return dateConvert;
};

const CommonDatePicker = (props) => {
 const { value, defaultValue, onChange } = props;
 let options = props;

 const convertValue = useMemo(() => {
  return convertSpecialToDateObject(value);
 }, [value]);

 const convertDefaultValue = useMemo(() => {
  return convertSpecialToDateObject(defaultValue);
 }, [defaultValue]);

 if (convertValue) {
  options = { ...options, value: convertValue };
 }

 if (convertDefaultValue) {
  options = { ...options, defaultValue: convertDefaultValue };
 }

 // Hàm này chỉ đơn giản là đảo ngược lại vị trí 2 biến, biến date có kiểu là moment, dateString có kiểu là string
 // const customHandleChange = (date, dateString) => {
 //     onChange(dateString, date);
 // };

 let placeholder = ReactDomServer.renderToString(<FTUTrans ns="common" name="data.searchDate" />);

 return (
  <div>
   <DatePicker
    className="c_datepicker"
    format={["DD/MM/YYYY"]}
    placeholder={placeholder}
    {...options}
    // onChange={customHandleChange}
   />
  </div>
 );
};

export default CommonDatePicker;
