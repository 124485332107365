import axios from "axios";
import Cookies from 'js-cookie'


export const ObjectUtils = {
    checkIsNullObject,
    compareObject
};


function checkIsNullObject (obj) {
    if(
        obj === undefined 
        || String(obj) === "undefined" 
        || obj === null 
        || String(obj) === "null" 
        || String(obj) === "" 
        || String(obj) === "NaN"
        || ( typeof obj === 'object' && Object.keys(obj).length === 0 )    
        ){
        return true;
    }

    return false;
}

function compareObject (obj1, obj2) {
    if(JSON.stringify(obj1) === JSON.stringify(obj2)){
        return true;
    }

    return false;
}


export const uploadToS3 = async (file) => {

    const formData = new FormData()

    formData.append('upload' , file);
    return await axios.post((window).globalConfig.APP_DOMAIN + "/file/upload-to-cloud" , formData , {
        headers : {
            Authorization:`Bearer ${Cookies.get('access_token')}`
        }
    })
}

export async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){
    const response = await fetch(url , {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers' : '*',
            'cache-control' : 'no-cache'
        }
    });
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }