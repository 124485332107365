import {Utils} from "../common/utils";
import {CHANGE_PASS, USER_INFO, USER_UPDATE, SEARCH_UNLINKED_ACCOUNT,GET_LINKED_ACCOUNT,REQUEST_OTP,VERIFY_OTP, GET_LOGIN_SESSION, UNLINK_OTP, USER_BASIC, LINK_ACCOUNT_ACTION} from "../../constants/AppPath";


export class UserInfoService{
    getInfoUser() {
        return Utils.post(USER_INFO,null,null,true)
    }
    getInfoUserBasic() {
        return Utils.post(USER_BASIC,null,null,true)
    }
    changePasswordUser(data) {
        return Utils.post(CHANGE_PASS,data,null ,true)
    }
    getLinkedAccount() {
        return Utils.getByUrl(GET_LINKED_ACCOUNT)
    }
    searchUnlinkedAccount(data){
        return Utils.post(SEARCH_UNLINKED_ACCOUNT, data , null , true)
    }
    postRequestOTP(data){
        return Utils.post(REQUEST_OTP, data, null , true)
    }
    getOtpUnlinked = (payload) => {
        return Utils.post(UNLINK_OTP , payload , null , true)
    }
    verifyOTP(data) {
        return Utils.post(VERIFY_OTP, data, null, true)
    }

    getLogginSession(payload)  {
        return Utils.post(GET_LOGIN_SESSION , payload , null , true)
    }

    actionLinkedAccount = (payload) => {
        return Utils.post(LINK_ACCOUNT_ACTION  , payload , null , true)
    }

}

export default UserInfoService;