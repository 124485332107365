//import "@babel/polyfill";
import MessageBox from "components/MessageBox";
import PrivateRoute from 'components/PrivateRoute';
import ProgressCustom from "components/ProgressCustom";
import ScrollToTop from 'components/scrollToTop/ScrollToTop';
import App from 'containers/app/App';
import 'locales/i18n';
// import Login from 'pages/Login';
import Permission from 'pages/Permission';
import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from "reactRedux/store/store";
import Dialog from "./components/Dialog";
import DialogImport from "./components/DialogImport";
import "@fortawesome/fontawesome-free/css/all.css";
import UserInfo from "./containers/admin/userInfor/UserInfo";
import ListOfSubSystems from "./containers/admin/listOfSubSystems/ListOfSubSystems";
import SwitchAccount from "./containers/admin/SwitchAccount/SwitchAccount";
import {Toaster} from 'react-hot-toast'
import * as Sentry from "@sentry/react";

// import process from "process"; 

// if (process.env.NODE_ENV === 'production') {
//     console.log = () => {}
//     console.error = () => {}
//     console.debug = () => {}
// }


Sentry.init({
    dsn: "https://0ac339265e6e47274a96d00792f2a78e@o4507575317889024.ingest.us.sentry.io/4507575319134208",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/, /^https:\/\/erp\.datxanh\.com\.vn/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={window.globalConfig.PUBLIC_URL}>
            <>
                <ProgressCustom/>
                <MessageBox/>
                <Dialog/>
                <DialogImport/>
                <ScrollToTop>
                    <Suspense fallback={'Đang tải dữ liệu...'}>
                        <Switch>
                            {/*<Route path="/login" exact component={Login}/>*/}
                             <PrivateRoute path="/userInfo" exact component={UserInfo}/>
                             <PrivateRoute path="/" exact component={ListOfSubSystems}/>
                             <Route path="/switchAccount" exact component={SwitchAccount}/>
                            {/* <Route path="/permission/:error_code" exact component={Permission}/> */}
                            <PrivateRoute path="/admin" component={App}/>
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </>
        </BrowserRouter>
        <Toaster />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
