import React from 'react';
import {MultiSelect} from 'primereact/multiselect';
import './CommonMutiSelect.scss';
import {FTUComponent} from "../../FTUComponent";

class CommonMutiSelect extends FTUComponent {
    constructor(props) {
        super(props);

    }

    render() {
        if (this.props.hidden === true) return;
        return (
            <MultiSelect {...this.props}
            />
        )
    }
}

export default CommonMutiSelect;