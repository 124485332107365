import React, { Component } from 'react';
import Constants from "../constants/Constants"

const {
  OTP_COUNTDOWN
} = Constants

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: this.props.otpCount  , // Set the initial countdown value to 60 seconds
    };
  }

  componentDidMount() {
    
    this.interval = setInterval(this.tick, 1000); // Start the countdown timer
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Clear the interval when the component unmounts
  }

  tick = () => {
    // Update the countdown value by decreasing it by 1 second
    this.setState((prevState) => ({
      seconds: prevState.seconds - 1,
    }));

    // Check if the countdown has reached 0
    if (this.state.seconds === 0) {
      clearInterval(this.interval); // Stop the countdown when it reaches 0
      this.props.hitCountdown()
    }
  };

  render() {
    return (
      <>
        <span style={{
          paddingBottom : '4px',
          display : 'inline-block'
        }}>({this.state.seconds})</span>
      </>
    );
  }
}

export default Countdown;