import {FTUComponent} from "../../components/FTUComponent";
import {withTranslation} from "react-i18next";
import Datatable, {
    genColumnDate,
    genColumnValue,
    genFilterDateNew,
    genFilterDropdown,
    genNoCol
} from "../../components/category/Datatable";
import {Column} from "primereact/column";
import React from "react";
import Constants from "../../constants/Constants";
import {NotifyHistoryService} from "../../services/admin/NotifyHistoryService";
import {DATE_FORMAT} from "../../constants/AppProps";
const _ = require("lodash");

class DetailNotify extends FTUComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataNotify : this.props.options.dataNotify,
            dataNoSeen : this.props.options.dataNoSeen,
            dataStatus: [
                {label: this.trans("notifyHistory:cbStatus.seen"), value: 1},
                {label: this.trans("notifyHistory:cbStatus.noSeen"), value: 0}
            ],
            dataNotifyType : []
        }
        this.NotifyService = new NotifyHistoryService();
    }

    componentDidMount() {
        this.onGetNotifyType();
        setTimeout(() => this.focusFirstElement("NotifyForm"), 0);
    }

    onGetNotifyType = async() =>{
        let rs = await this.NotifyService.getNotifyType();
        if (rs && rs.data && rs.data.data) {
            let type = rs.data.data;
            this.setState({
                dataNotifyType: type
            });
        }
    }

    onUpdateSeen = async (data) => {
        data.status = Constants.NOTIFY.SEEN;
        let rs = await this.NotifyService.seenNotify(data);
        if(rs && rs.data){
            let dataNotify = this.state.dataNotify;
            let dataNoSeen = this.state.dataNoSeen;
            let indexData = _.filter(dataNotify, function (o) {return o.notifyId === data.notifyId});
            let dataSeen = _.remove(dataNoSeen, function (o) {return o.notifyId === data.notifyId});
            if(indexData > -1){
                dataNotify[indexData] = data;
            }
            this.props.options.parentCallBack(dataNoSeen.length);
            this.setState({
                dataNotify : dataNotify,
                dataNoSeen : dataNoSeen
            })
        }
    }


    onTblSelectedChange = (e) =>{
        if(e.value){
            let data = e.value;
            if(data.status == Constants.NOTIFY.NO_SEEN){
                this.onUpdateSeen(data);
            }
        }
    }

    render() {
        const rowClass = (data) => {
            return data.status != 1 ? {"rowStyleSeenNotify" : true} : null;
        }
        return(
            <div className="row">
                <div className="col-md-12">
                    <div>
                        <Datatable value={this.state.dataNotify} forwardRef={(el) => this.dt = el}
                                   dt={this.dt}
                                   scrollable={true}
                                   rows={10}
                                   autoSelect={false}
                                   selectionMode="single"
                                   onSelectionChange={this.onTblSelectedChange}
                                   rowClassName={rowClass}
                        >
                            <Column header={this.trans("common:table.STT")}
                                    style={{width: '50px', textAlign: 'center'}}
                                    body={genNoCol.bind(this)}/>
                            {/*<Column header={<i className="fa fa-fw fa-cogs"></i>}
                                style={{width: "60px"}}
                                body={this.genActionCol}/>*/}
                            <Column field="message" style={{width: '500px'}} sortable={true}
                                    filterMatchMode="contains" filter={true}
                                    header={this.trans("notifyHistory:message")}/>
                            <Column field="strCreateDate"
                                    filterElement={genFilterDateNew(this, "strCreateDate", DATE_FORMAT.DATE_FORMAT,
                                        DATE_FORMAT.DATE_FORMAT, DATE_FORMAT.DATE_FORMAT, true, "contains")}
                                    body={genColumnDate.bind(this, "strCreateDate", Constants.DATE_FORMAT_FULL, Constants.DATE_FORMAT_FULL)}
                                    header={this.trans("notifyHistory:createDate")}
                                    style={{width: "100px",textAlign:'center'}} filter={true} sortable={true}/>
                            <Column field="status" style={{width: '100px',textAlign:'center'}} sortable={true}
                                    filterMatchMode="contains" filter={true}
                                    filterElement={genFilterDropdown(this, "status", "equals", this.state.dataStatus, "value", "label")}
                                    header={this.trans("notifyHistory:status")}
                                    body={genColumnValue.bind(this, "status", this.state.dataStatus, "value", "label")}/>
                        </Datatable>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation(["notifyHistory", "common"])(DetailNotify);