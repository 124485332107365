import React, { useEffect, useRef } from "react";
import { Input } from "reactstrap";
import "./CommonInputText.scss";
import { omit } from "lodash";

const CommonInputText = (props) => {
  return (
      <Input {...props}  className={`c_input-text ${props.className}`} />
  );
};

export default CommonInputText;
