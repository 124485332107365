import React from 'react'
import {AuthenticationService, getObjectFromCookiesByPrefix, removeCookiesByPrefix, saveObjToCookies} from '../services/access/AuthenticationService'
import {Redirect, Route} from 'react-router-dom'
import {Utils} from "../services/common/utils";
import {APP_DOMAIN, GET_USER_INFO_SSO, URL_SSO_BACK, URL_SSO_FRONT} from "../constants/AppPath";
import Constants from "../constants/Constants";
import store from "../reactRedux/store/store";
import {fetchUser} from "../reactRedux/action/userAction";
import {BehaviorSubject} from "rxjs";
import moment from "moment";
import {UserInfoService} from '../services/admin/UserInfoService'
import { UserService } from '../services/access/User';

const PrivateRoute = ({component: Component, ...rest}) => {

    // Add your own authentication on the below line.
    const isLoggedIn = AuthenticationService.isLogin();
    const currentUserSubject = new BehaviorSubject(getObjectFromCookiesByPrefix('token'));
    const infoService = new UserInfoService()


    function checkLogin() {
        // logout();
        //Kiểm tra đã đăng nhập hay chưa, bằng access_token lưu trong localstorage
        const currentUser = getObjectFromCookiesByPrefix('token');

        // debugger
        if (currentUser?.access_token) {//Có access_token --> đã đăng nhập
            if (AuthenticationService.checkExpireToken()) {
                logout();
            }
            return <div/>;
        }
        //TH chưa có access_token, kiểm tra xem URL có thông tin authorization_code trả về từ KeyCloak không
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code") ;

        //Nếu có authorization_code --> lấy access_token và giữ phiên đăng nhập
        if (code) {
            // getTokenByCode(code, window.location.protocol + '//' + window.location.host);
            let redirectUrl = window.location.origin;
            const url = new URL(window.location.href);
            const urlPathId = url.searchParams.get("urlPathId") || null;

            if (urlPathId) {
                redirectUrl = window.location.origin + window.location.pathname + "?urlPathId=" + urlPathId;
            }else if (window.location.pathname !== "/")  {
                redirectUrl = window.location.origin + window.location.pathname;
            }
            // alert(redirectUrl)
            getTokenByCode(code, redirectUrl);
        } else {
            const tmp =  (window.location.pathname+window.location.search).substr(1)
            window.open(window.globalConfig.urlBase + window.globalConfig.urlAuthorizationEndpoint + window.globalConfig.clientId + "&redirect_uri=" + window.location.origin + (tmp && "/") + tmp , '_self');
        }
        return <div/>;
    }


    async function getTokenByCode(code, redirectUrl) {
        const currentUser = getObjectFromCookiesByPrefix('token');
        if (currentUser && currentUser.access_token) {//Có access_token --> đã đăng nhập
            return;
        }
        try {
            // 1: get token
            let responseGetToken = await fetch(APP_DOMAIN + '/keycloak/get-token-by-code/' + code, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({'authorizationCode': code, 'redirectUrl': redirectUrl})
            });
            if (responseGetToken.status === 200) {

                let dataGetToken = await responseGetToken.json();

                // debugger
                let strJwt = Object.assign(dataGetToken.data, _updateAccessTime(dataGetToken.data.expires_in));
                saveObjToCookies(strJwt , {path : '/' , domain : window.globalConfig.domainPrefix} , 'token')

                // Get button permission (ininital permission button array)
                // Avoid bug when user go directly to the page without going through the blank Home page

                let listPermissionButton = await UserService.getPermissionButton();
                localStorage.setItem('permissionBtn', JSON.stringify(listPermissionButton));

                currentUserSubject.next(strJwt);
                //luu thong tin user vao redux
                store.dispatch(
                    fetchUser(strJwt)
                );

                let url = getBasicUrl();
                window.open(url, "_self");
            }
        } catch (err) {
            console.error(err);
        }
    }



    async function getUserInfoSSO(token) {
        let result = await Utils.getByUrl(URL_SSO_BACK + "/sso-login/serviceValidate?ticket=" + token + "&appCode=" + Constants.APP_ID);

        if (result && result.data) {
            if (result.data.code && result.data.code === 'API-000') {

                let data = result.data.data.yourAppReturn;
                if (data && data.body) {
                    let strJwt = Object.assign(data.body, _updateAccessTime(data.body.accessTokenExpirationSecond));

                    saveObjToCookies(strJwt , {path : '/' , domain : window.globalConfig.domainPrefix} , 'token')
                    currentUserSubject.next(strJwt);
                    store.dispatch(fetchUser(strJwt));
                    if (data.body.remainExpirePassword >= 0 && data.body.remainExpirePassword <= 5) {
                        // alert("Mật khẩu của bạn còn " + data.body.remainExpirePassword + " ngày.Mời thực hiện đổi mật khẩu.")
                    }
                    let url = removeTicketFromUrl();
                    window.open(url, "_self");
                }
            } else {

                onRedirectLogin();
            }
        }
    }


    function _updateAccessTime(accessExpireSeconds, waitingTime) {
        let accessTime = {};
        let nowTime = new Date();
        nowTime.setSeconds(nowTime.getSeconds() + accessExpireSeconds);
        accessTime["validTo"] = nowTime.getTime();
        accessTime["waitingTime"] = waitingTime;

        // accessTime["timeBeginVN"] = nowTime.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"});
        // accessTime["timeBegin"] = nowTime;

        accessTime["timeBegin"] = moment().format(Constants.DATE_FORMAT_FULL);

        return accessTime;
    }

    /**
     * Đăng xuất
     * */
    function logout() {
        //Xóa access_token trong localstorage
        removeCookiesByPrefix('token' , {path : '/' , domain : window.globalConfig.domainPrefix})
        localStorage.clear()
        //Open link của KeyCloak để xóa phiên đăng nhập trên KeyCloak. Trình duyệt tự động redirect về trang ban đầu
        // window.open('http://{{keycloak-server}}/realms/dxg/protocol/openid-connect/auth?response_type=code&client_id=admin-app' + location.protocol + '//' + location.host, '_self');
        // window.open(window.globalConfig.urlBase + window.globalConfig.urlEndSessionEndpoint + window.location.protocol + '//' + window.location.host, '_self');
        window.open(window.globalConfig.urlBase + window.globalConfig.urlAuthorizationEndpoint + window.globalConfig.clientId + "&redirect_uri=" + window.location.origin, '_self');
    }

    function removeTicketFromUrl() {
        let url = new URL(window.location.href);
        let urlReturn = url.href;
        if (url.search.includes("&ticket=")) {
            let index = url.search.indexOf("&ticket=");
            let valueReplace = url.search.slice(index, url.search.length);
            urlReturn = url.href.replaceAll(valueReplace, "");
        }
        return urlReturn;
    }

    function getBasicUrl() {
        let url = new URL(window.location.href);
        url.searchParams.delete('session_state');
        url.searchParams.delete('code');
        return url.href;
    }

    function onRedirectLogin() {
        window.location.assign(URL_SSO_FRONT + "/login?appCode=" + Constants.APP_ID + "&redirectUrl=" + removeTicketFromUrl());
        // debugger
    }

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    /* <Redirect to={{ pathname: '/login', state: { from: props.location } }} />*/
                    checkLogin(props)
                )
            }
        />
    )
}

export default PrivateRoute
