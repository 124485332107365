import { FTUComponent } from "components/FTUComponent";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import React from "react";
import { withTranslation } from "react-i18next";
import { ModalBody, ModalFooter } from "reactstrap";
import { stringNullOrEmpty } from "../../../components/category/CommonUtils";
import { hideDialog } from "../../../components/Dialog";
import Label from "../../../components/Label";
import {
  showErrorBox,
  showMessageBox,
  showSuccessBox,
} from "../../../components/MessageBox";
import { SERVICE_CODE } from "../../../constants/AppProps";
import { ChangePasswordService } from "../../../services/admin/ChangePasswordService";

class ChangePassword extends FTUComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        oldPassword: "",
        newPassword: "",
        confirmPass: "",
      },
      // ,action : "defaultAction",
      // userName : "userName"
    };
    this.service = new ChangePasswordService();
  }

  // componentWillMount() {
  //     let defaultAction = "changePassword";
  //     let userName = "";
  //     if(this.props.options.action){
  //         defaultAction = this.props.options.action;
  //         userName = this.props.options.userName
  //     }
  //     this.setState({
  //         action : defaultAction,
  //         userName : userName
  //     });
  // }

  componentDidMount() {
    setTimeout(() => this.focusFirstElement("changePasswordForm"), 0);
  }

  validate = (data) => {
    let isValid = true;
    if (stringNullOrEmpty(data.oldPassword)) {
      showErrorBox(
        this.trans("changePassword:msg.oldPassIsEmpty"),
        this.trans("common:msg.title"),
        document.getElementById("oldPassword").focus()
      );
      isValid = false;
    } else if (stringNullOrEmpty(data.newPassword)) {
      showErrorBox(
        this.trans("changePassword:msg.newPassIsEmpty"),
        this.trans("common:msg.title"),
        document.getElementById("newPassword").focus()
      );
      isValid = false;
    } else if (stringNullOrEmpty(data.confirmPass)) {
      showErrorBox(
        this.trans("changePassword:msg.confirmPassIsEmpty"),
        this.trans("common:msg.title"),
        document.getElementById("confirmPass").focus()
      );
      isValid = false;
    } else if (data.newPassword !== data.confirmPass) {
      showErrorBox(
        this.trans("changePassword:msg.wrongConfirm"),
        this.trans("common:msg.title"),
        document.getElementById("confirmPass").focus()
      );
      isValid = false;
    } /*else if (data.oldPassword === data.newPassword) {
            showErrorBox(this.trans("changePassword:msg.samePassword"), this.trans("common:msg.title"),
                document.getElementById("newPassword").focus());
            isValid = false;
        }*/
    return isValid;
  };

  onSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const data = this.state.data;
    if (this.validate(data)) {
      const _ = require("lodash");
      const res = await this.service.changePassword(
        _.omit(data, ["confirmPass"])
      );
      // let res = {};
      // if(this.state.action == "changePassword") {
      //     res = await this.service.changePassword(_.omit(data, ['confirmPass']));
      // }else {
      //     let dataReset = {
      //         newPassword: data.newPassword,
      //         oldPassword: data.oldPassword,
      //         userName: this.state.userName
      //     }
      //     res = await this.service.resetPassword(dataReset);
      // }

      if (res && res.data) {
        if (res.data.code === SERVICE_CODE.SUCC_CODE) {
          showSuccessBox(this.trans("changePassword:msg." + res.data.code));
          hideDialog();
        } else {
          if (res.data.code === "APP004") {
            //min Length
            showErrorBox(
              this.trans("changePassword:msg." + res.data.code, {
                length: res.data.message,
              }),
              document.getElementById("newPassword").focus()
            );
          } else if (res.data.code === "APP003") {
            //empty Password
            showErrorBox(
              this.trans("changePassword:msg." + res.data.code),
              document.getElementById("newPassword").focus()
            );
          } else {
            showErrorBox(
              this.trans("changePassword:msg." + res.data.code),
              document.getElementById("oldPassword").focus()
            );
          }
        }
      } else {
        showErrorBox(this.trans("common:msg.error"));
      }
    }
  };

  onCancel = () => {
    hideDialog(false);
  };

  render() {
    return (
      <div>
        <form
          className="form-group"
          id={"changePasswordForm"}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <ModalBody>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <Label
                    required={true}
                    value={this.trans("changePassword:oldPassword")}
                  />
                </div>
                <div className="col-md-8">
                  <Password
                    id={"oldPassword"}
                    feedback={false}
                    defaultValue={""}
                    autoComplete={"off"}
                    onChange={this.onChangeTxtField.bind(this, "oldPassword")}
                    disabled={this.state.disabledAll}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Label
                    required={true}
                    value={this.trans("changePassword:newPassword")}
                  />
                </div>
                <div className="col-md-8">
                  <Password
                    id={"newPassword"}
                    feedback={false}
                    defaultValue={""}
                    autoComplete={"off"}
                    onChange={this.onChangeTxtField.bind(this, "newPassword")}
                    disabled={this.state.disabledAll}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Label
                    required={true}
                    value={this.trans("changePassword:newPasswordConfirm")}
                  />
                </div>
                <div className="col-md-8">
                  <Password
                    id={"confirmPass"}
                    feedback={false}
                    defaultValue={""}
                    autoComplete={"off"}
                    onChange={this.onChangeTxtField.bind(this, "confirmPass")}
                    disabled={this.state.disabledAll}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </form>
        <ModalFooter>
          <div className={"col-md-12"} style={{ textAlign: "center" }}>
            <Button
              color="primary"
              label={this.trans("changePassword:button.accept")}
              type={"submit"}
              onClick={this.onSubmit}
              style={{ width: "100px", marginRight: "5px" }}
            />
            <Button
              color="secondary"
              label={this.trans("changePassword:button.cancel")}
              onClick={this.onCancel}
              style={{ width: "100px" }}
              type={"button"}
            />
          </div>
        </ModalFooter>
      </div>
    );
  }
}

export default withTranslation(["changePassword", "common"])(ChangePassword);
