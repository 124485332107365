import React from 'react';

import { Button } from 'primereact/button';
import { Input } from 'reactstrap';
import './CommonInputGroup.scss';

const CommonInputGroup = (props) => {
    // return <Input className='c_input-text' {...props} />;
    const { disabledInput, disabledButton, icon, onClick, ...rest } = props;

    return (
        <div className='c_input-group'>
            <Input
                disabled={disabledInput}
                placeholder=''
                className='c_input-text'
                {...rest}
            />
            <Button onClick={onClick} disabled={disabledButton} icon={icon} />
        </div>
    );
};

export default CommonInputGroup;
