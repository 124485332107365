import { FETCH_USER_SUCCESS, FETCH_USER_FAILURE, UPDATE_ACCESS_SUCCESS } from 'reactRedux/action/userAction';

const initialState = {
    info: {
        accessToken: "",
        type: "ư",
        username: "",
        refreshToken: "",
        appCode: "",
		shopCode: ""		,
		userId:	0		,
        validTo: 1000000000000*1000000000000,
        lastAccess: 0
    }
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {

        case FETCH_USER_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                info: Object.assign(state.info, action.payload.user)
            };

        case FETCH_USER_FAILURE:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return state;

        case UPDATE_ACCESS_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                info: Object.assign(state.info, action.payload.accessTime)
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}
