import React from "react";
import Label from "./Label";
import {
 CommonInputText,
 CommonInputGroup,
 CommonSelect,
 CommonDatePicker,
} from "components/common";
import { Checkbox } from "primereact/checkbox";
import CustomValidTooltip from "components/CustomValidTooltip";
import { FTULazySelect } from "./category/FTULazySelect";
import { Switch, Upload } from "antd";

class LabelInput extends React.Component {
 // responsive:
 // label: typo__label--4
 //  input: .typo__placeholder--2
 // tooltip: typo__button-text--2
 render() {
  return (
   <div className={"row label-input w-100 " + this.props.className} hidden={this.props.hidden ?? false}>
    <div style={{overflow : 'hidden'}} className={this.props.labelClass ? this.props.labelClass : "col-md-4"}>
     <Label
      style={this.props.labelStyle}
      value={this.props.labelValue + ": "}
      required={this.props.required}
     />
    </div>
    <div  style={this.props?.inputStyle} className={this.props?.inputClass ? this.props?.inputClass : "col-md-8"}>
     <CustomValidTooltip>{this.props.tooltip}</CustomValidTooltip>
     {this.props.input ? (
      <CommonInputText
       {...this.props.input}
       // className={this.props.input?.className + 'w-100'}
       disabled={this.props.disabled}
      />
     ) : (
      ""
     )}
     {this.props.inputGroup ? (
      <CommonInputGroup
       {...this.props.inputGroup}
       // className={this.props.inputGroup?.className + ' w-100'}
      />
     ) : (
      ""
     )}
     {this.props.inputSelect ? (
      <CommonSelect {...this.props.inputSelect} disabled={this.props.disabled} />
     ) : (
      ""
     )}
     {this.props.inputCheckbox ? (
      <Checkbox {...this.props.inputCheckbox} disabled={this.props.disabled} />
     ) : (
      ""
     )}
     {this.props.inputDatePicker ? (
      <CommonDatePicker {...this.props.inputDatePicker} disabled={this.props.disabled} />
     ) : (
      ""
     )}
     {this.props.inputLazySelect ? (
      <FTULazySelect {...this.props.inputLazySelect} disabled={this.props.disabled} />
     ) : (
      ""
     )}
     {this.props.inputCheckBox ? (
      <Checkbox {...this.props.inputCheckBox} disabled={this.props.disabled} />
     ) : (
      ""
     )}
     {this.props.inputUpload ? (
      <Upload {...this.props.inputUpload} disabled={this.props.disabled} />
     ) : (
      ""
     )}
     {this.props.inputSwitch ? (
      <Switch {...this.props.inputSwitch} disabled={this.props.disabled} />
      ) : (
      ""
    )}
    </div>
   </div>
  );
 }
}

export default LabelInput;
