import React from "react";

import { FTUTrans } from "../../components/FTUComponent";
import { showErrorBox } from "../../components/MessageBox";
import { hideProgress } from "../../components/ProgressCustom";
import { pushHistory } from "../../containers/app/App";
import { AuthenticationService } from "../access/AuthenticationService";
import { emptyAxios } from "./utils";

export const handlerError = (path , hasDialogProcess) => (error) => {
  if(error?.response?.status === 401) {
    showErrorBox(`Bạn không có quyền sử dụng API ${path}. Vui lòng liên hệ quản trị viên để được cấp quyền`, " Lỗi", async () => {
        // AuthenticationService.logOutAll();
    });
  }else if (error?.response?.status === 417) {
    AuthenticationService.logOutAll();
  }else if (error && error.response && error.response.status && error.response.status === 403) {
    showErrorBox(<FTUTrans ns="common" name="error.common403"/>, undefined, () => {
        pushHistory("/");
    });
  }else if (error && error.response && error.response.status && error.response.status === 409) {
    showErrorBox(<FTUTrans ns="common" name="error.common409"/>, undefined, () => {
        AuthenticationService.logout();
    });
  }else if (error && error.response && error.response.data && error.response.data.message) {
    showErrorBox(error.response.data.message);
  }else {
    showErrorBox(<FTUTrans ns="common" name="error.common"/>);
  }
  if(hasDialogProcess) {
    hideProgress();
  }

  return emptyAxios;
}
