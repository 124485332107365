import {Utils} from "../common/utils";
import {
    CHANGE_PASSWORD,
    RESET_PASSWORD
} from "../../constants/AppPath";
import {showErrorBox} from "../../components/MessageBox";
import Constants from "../../constants/Constants";

export class ChangePasswordService {

    changePassword = (data) => {
        return Utils.put(CHANGE_PASSWORD, data, null, true);
    }

    resetPassword = async (data) => {
        return this.onMessage(await Utils.postLogin(RESET_PASSWORD, data, null, true));
    }

    onMessage = (rs) =>{
        if(rs && rs.data && rs.data.code != Constants.SERVICE_CODE.SUCC_CODE){
            showErrorBox("Lỗi :" +rs.data.message);
            return [];
        }else{
            return rs;
        }
    }

}