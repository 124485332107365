import React from 'react';

const CustomValidTooltip = (props) => {
    return (
        <div
            className={'tooltip-validate-custom'}
            hidden={!Boolean(props.children)}>
            {props.children}
        </div>
    );
};

export default CustomValidTooltip;
