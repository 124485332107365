import React from "react";
import {Modal, ModalHeader} from "reactstrap";
import {showConfirm} from "./MessageBox";
import {FTUTrans} from 'components/FTUComponent';

const _ = require('lodash');
export var showDialog = function (_content, _title, _style = {}) {
    this.setState({
        open: true,
        title: _title,
        content: _content,
        style: _style
    });
};

export var hideDialog = function (_showConfirm , message) {
    if (_showConfirm && !_.isEqual(this.state.content.props.options.data, this.state.content.props.options.dataNew)) {
        if(message){
            showConfirm(<FTUTrans name={message} />, () => {
                this.setState({open: false});
            }, <FTUTrans ns="common" name="msg.title"/>);
        }else {
            showConfirm(<FTUTrans ns="common" name="msg.confirmClose"/>, () => {
                this.setState({open: false});
            }, <FTUTrans ns="common" name="msg.title"/>);
        }
    } else {
        this.setState({open: false});
    }
};

class DialogImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        showDialog = showDialog.bind(this);
        hideDialog = hideDialog.bind(this);
    }

    render() {

        return (
            <div>
                <Modal
                    style={this.state.style}
                    isOpen={this.state.open}
                    toggle={hideDialog}
                    className="modal-primary"
                    backdrop="static"
                >
                    <ModalHeader toggle={hideDialog}>{this.state.title}</ModalHeader>
                </Modal>
            </div>
        );
    }
}

export default DialogImport;
