import { withTranslation } from "react-i18next";
import { FTUComponent } from "../../../components/FTUComponent";
import React from "react";
import Datatable from "../../../components/category/Datatable";
import { Column } from "primereact/column";
import Constants from "../../../constants/Constants";
import { FileService } from "../../../services/admin/FileService";
import { Image } from 'antd';
import { CommonButton, CommonDatePicker } from "../../../components/common";
import ButtonLink from "../../../components/ButtonLink";
import SkeletenLoading from '../../../components/SkeletionLoading'
import { showConfirm, showWarningBox } from "../../../components/MessageBox";
import { DATE_FORMAT } from "../../../constants/AppProps";
import moment from "moment";
import Icon from "../../../constants/Icon";

const dataform = [{skeleton : true} , {skeleton : true} , {skeleton : true} , {skeleton : true} , {skeleton : true} , {skeleton : true} , {skeleton : true},{skeleton : true}, {skeleton : true}, {skeleton : true} ]

const {IMAGE_EXTENTION} = Constants

class ImageManagement extends FTUComponent {
  constructor (props) {
    super(props);


    this.state = {
      data : [],
      rows : Constants.TABLE.ROWS_DEFAULT,
      totalRecords : 0,
      first : 0,
      page : 0,
    }

    this.fileService = new FileService()
  }

  componentDidMount = async () => {
    const res = await this.fileService.postS3GetFile({
      page : this.state.first + 1,
      pageSize : this.state.rows
    });

    if (Array.isArray(res?.data?.data) && (res?.data?.data || []).length > 0) {
        this.setState({
          data : res.data.data,
          totalRecord : res.data.totalRecord
        })
    }
  }

  onPage = ({first = this.state.first , rows = this.state.rows, page = this.state.page }) => {
      this.setState({
        first : first,
        rows : rows,
        page : page,
        
      } , async () => {
        const res = await this.fileService.postS3GetFile({
          page : page+1,
          pageSize  : this.state.rows,
          uploadDate : this.state.search instanceof moment ? this.state.search.format(DATE_FORMAT.DATE_FORMAT) : null
        });
        if (Array.isArray(res.data.data) ) {
            this.setState({
              data : res.data.data,
              totalRecord : res.data.totalRecord
            })
        }
    
      })
  }

  genActionCol = (row) => {
    return (
      <div className={"lst-btn-link"}>
          <ButtonLink
          className={"btn-link"}
          title={this.trans("common:msg.del")}
          onClick={() => this.onDelete(row)}
          >
          {Constants.TABLE_ICON.Delete}
          </ButtonLink>
      </div>
    )
    
  }


  onDelete = (row) => {
    showConfirm(this.trans('confirmDeleteImage') , async () => {
      const res = await this.fileService.onDelete({
        filename : row.fileName
      })


      // if (res.data === "DONE" ) {
      if (res.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
        const res = await this.fileService.postS3GetFile({
          page : this.state.page+1,
          pageSize  : this.state.rows,
        });
        if (Array.isArray(res.data.data) ) {
            this.setState({
              data : res.data.data,
              totalRecord : res.data.totalRecord
            })
        }
      }
    })
  }

  genPreview = (row) => {
    if (new RegExp(IMAGE_EXTENTION.join("|")).test((row.dataFile || '.').split('.').pop().toLowerCase())) {
      return <Image width={150} src={row.dataFile} />
    }else {
      return <div className="flex-center" style={{cursor : 'pointer'}} onClick={() => this.onDownload(row.dataFile)}> 
        <span className="mr-2">{Icon.Download}</span>
        <span>{this.trans('listGroupUserNew:download')}</span>
      </div>
    }
  }
  

  onDownload = (url) => {
      let element = window.document.createElement('a');
      // const filename = res.headers['content-disposition'].split('filename=')[1];
      element.href = url
      element.download = url;
      element.click();
  }

  genCopy = (field) => (data) => {
    return (
       <div onClick={() => this.copyToClipBoard(data[field])} className="p-copy">{data[field]}</div>
    )
  } 

  onChangeSearch = (e) => {
    if (e instanceof moment) {
      if (e.isAfter(moment())) {
        showWarningBox(this.trans('common:msg.errorDate'));
      }else {
        this.setState({
          search : e
        })
      }
    }else {
      this.setState({
        search : null
      })
    }
  }

  genHeader = () => {
    return (
      <div className='sticky-header-100'>
          <div>{this.trans('listGroupUserNew:imageManagement')}</div>

          <div className={'sticky-header-button'}>
              <div className='sticky-button-group flex-center'>
                <CommonDatePicker
                  className='mr-2'
                  format={[
                    'DD/MM/YYYY',
                    'DDMMYYYY',
                  ]}
                  id='toDate'
                  value={this.state.search}
                  onChange={(e) => this.onChangeSearch(e)}
                />
                <CommonButton
                  label={this.trans('common:button.search')}
                    icon='pi pi-search'
                    onClick={() => this.onPage({first : 0 , rows : this.state.rows, page : 0 })}
                    nameScreen={Constants.BTN_NAME_SCREEN.APDOMAIN}
                    action={Constants.ACTION.DELETE}
                />
              </div>
          </div>
      </div>
  );
  }
  render = () => {
    return (
      <>
        <div className="flex-100 h-95">
        <div className="sticky-header-100">
        <div>{this.trans('listGroupUserNew:imageManagement')}</div>

            <div className={'sticky-header-button'}>
              <div className='sticky-button-group flex-center'>
                <CommonDatePicker
                  className='mr-2'
                  format={[
                    'DD/MM/YYYY',
                    'DDMMYYYY',
                  ]}
                  id='toDate'
                  value={this.state.search}
                  onChange={(e) => this.onChangeSearch(e)}
                />
                <CommonButton
                  label={this.trans('common:button.search')}
                  icon='pi pi-search'
                  onClick={() => this.onPage({ first: 0, rows: this.state.rows, page: 0 })}
                  nameScreen={Constants.BTN_NAME_SCREEN.APDOMAIN}
                  action={Constants.ACTION.DELETE}
                />
              </div>
            </div>
        </div>
                <Datatable
                    lazy={true}
                    value={this.state.data}
                    first={this.state.first}
                    rows={this.state.rows}
                    onPage={this.onPage}
                    totalRecords={this.state.totalRecord}
                    className="login-session vertical observer">
                    <Column
                      header={this.trans("STT")}
                      style={{
                        textAlign: "center",
                        width: "30px",
                      }}
                      body={(_, e) => {
                        return this.state.first + e.rowIndex + 1;
                      }}
                    />
                    <Column
                        header={<i className="fa fa-fw fa-cogs"></i>}
                        style={{ width: "30px" }}
                        body={this.genActionCol}
                    />
                    <Column
                        field="bucketName"
                        style={{ width: '60px', textAlign: 'center' }}
                        header={this.trans('bucketName')}
                        body={(row) => {
                          return row['bucketName']
                        }}
                    />
                    <Column
                        field='createDate'
                        style={{ width: '150px', textAlign: 'center' }}
                        header={this.trans('lastModified')}
                        body={row => {
                          return row['createDate'] ? !Number.isNaN(row['createDate']) ? moment.unix(row['createDate']/1000).format(DATE_FORMAT.DATE_TIME_FORMAT) : '' : ''
                        }}
                    />

                    <Column
                        field='dataFile'
                        style={{ width: '150px', textAlign: 'center' }}
                        header={this.trans('link')}
                        body={this.genCopy('dataFile')}
                        
                    />
                    <Column
                        style={{ width: '150px', textAlign: 'center' }}
                        body={this.genPreview}
                        header={this.trans('Preview')}
                    />
                </Datatable>   
          </div>
      </>
    )
    
  }
}


export default withTranslation(['listGroupUserNew' , 'common'])(ImageManagement)

