import { FTUComponent, FTUTrans } from 'components/FTUComponent';
import 'layout/login.scss';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Row
} from "reactstrap";



class Permission extends FTUComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
    }

    render() {
        const logo = 'assets/layout/images/logo_itel.png';
        const backgroud = 'url(assets/layout/images/backgroud.png) no-repeat fixed center';
        // http://localhost:3000/employee/profile
        // http://localhost:3000/customer/profile
        // http://localhost:3000/constructor/profile



        return (
            <div style={{background: backgroud}} className="app flex-row align-items-center">
                <Container className="content-section introduction growl-demo">
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <center>
                                            alo
                                        <h2><font color={"red"}><FTUTrans ns="login" name={this.props.match.params.error_code}/></font></h2>
                                        <h3><a href={window.globalConfig.PUBLIC_URL + "/"}><FTUTrans ns="login" name={"Auto_Logout_Notify"}/></a></h3>
                                        </center>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withTranslation("login")(Permission);
