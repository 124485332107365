import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import Constants from '../../constants/Constants';
import Label from 'reactstrap/es/Label';
import { withTranslation } from 'react-i18next';
import { FTUComponent, FTUTrans } from '../FTUComponent';
import moment from 'moment';
import { addThousandSeparator, stringNullOrEmpty } from './CommonUtils';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DatePicker } from 'antd';
import { FORMAT_DATE_PICKER, DATE_FORMAT } from 'constants/AppProps';
import { InputText } from 'primereact/inputtext';
import InputNumber from 'components/category/InputNumber';
import ReactDOM from 'react-dom';
import { TABLE } from '../../constants/AppProps';
import { CommonInputText } from '../common/CommonInputText';
import CustomValidTooltip from '../CustomValidTooltip';
import xlsx from 'xlsx';
import ButtonLink from '../ButtonLink';

const _ = require('lodash');

class Datatable extends FTUComponent {
    constructor(props) {
        super(props);
        //colsDefault: cột mặc định hiện(stt+action)
        //colOptions : danh sách cột có thể chọn ẩn/hiện
        //colsSelects: danh sách cột hiện
        this.state = {
            colsDefault: [],
            colOptions: [],
            colsSelects: [],
            totalRecord: 0,
            first: 0,
            rows: this.props.rows ?? 5,
            error: '',
        };
    }

    componentWillMount() {
        let lstDefault = [];
        let lstOption = [];
        let lstSelect = [];
        React.Children.map(this.props.children, (child, idx) => {
            if (!child.props.field || child.props.visibleCol) {
                lstDefault.push({
                    label: child.props.header,
                    value: child.props,
                });
            } else {
                lstOption.push({
                    label: child.props.header,
                    value: child.props,
                });
                lstSelect.push(child.props);
            }
        });
        this.setState({
            colsDefault: lstDefault,
            colOptions: lstOption,
            colsSelects: lstSelect,
            totalRecord: this.props.totalRecord,
        });
    }

    componentDidMount() {
        this.setColToggleAttribute();
        if (this.dt.processData() && this.state.totalRecord !== this.dt.processData().length) {
            this.setState({
                totalRecord: this.dt.processData().length,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.dt.processData() && this.state.totalRecord !== this.dt.processData().length) {
            this.setState({
                totalRecord: this.dt.processData().length,
            });
        }
    }

    findParentByClassName = (element) => {
        if (element) {
            let parent = element.parentElement;
            while (true) {
                if (!parent) {
                    return null;
                }
                if (
                    parent.className.includes('Pane vertical') ||
                    parent.className.includes('Pane horizontal') ||
                    parent.className.includes('FTUPanel')
                ) {
                    return parent;
                }
                parent = parent.parentElement;
            }
        } else {
            return null;
        }
    };

    setColToggleAttribute = () => {
        try {
            const table = ReactDOM.findDOMNode(this);
            let colToggle = table.getElementsByClassName('colToggle')[0];
            let paginator = table.getElementsByClassName('p-paginator')[0];
            let rowChange = paginator.getElementsByClassName('p-dropdown')[0];
            let parent = this.findParentByClassName(table);
            if (rowChange) {
                let rowPanel = rowChange.getElementsByClassName('p-dropdown-panel')[0];
                let panelWrapper = rowPanel.getElementsByClassName('p-dropdown-items-wrapper')[0];
                rowChange.onclick = (e) => {
                    if (rowPanel && rowPanel.style.display !== 'block') {
                        if (panelWrapper) {
                            let maxH = 0;
                            if (parent) {
                                const parentBound = parent.getBoundingClientRect();
                                const rowChangeBound = colToggle.getBoundingClientRect();
                                let offTopParent = rowChangeBound.top - parentBound.top;
                                let offBotParent = parentBound.height - offTopParent;
                                if (offTopParent > offBotParent) {
                                    rowPanel.className = rowPanel.className
                                        .replace(' p-up', '')
                                        .replace(' p-down', '');
                                    rowPanel.className += ' p-up';
                                    maxH = offTopParent - 20;
                                } else {
                                    rowPanel.className = rowPanel.className
                                        .replace(' p-up', '')
                                        .replace(' p-down', '');
                                    rowPanel.className += ' p-down';
                                    maxH = offBotParent - 25;
                                }
                            } else {
                                rowPanel.className = rowPanel.className
                                    .replace(' p-up', '')
                                    .replace(' p-down', '');
                                rowPanel.className += ' p-up';
                                maxH =
                                    window.pageYOffset +
                                    colToggle.getBoundingClientRect().top -
                                    150;
                            }
                            if (maxH) {
                                panelWrapper.style.maxHeight = maxH > 300 ? '300px' : maxH + 'px';
                            }
                        }
                    }
                };
            }
            if (colToggle) {
                let input = colToggle.getElementsByTagName('input')[0];
                let span = colToggle.getElementsByClassName('p-multiselect-trigger-icon')[0];
                let panel = colToggle.getElementsByClassName('p-multiselect-panel')[0];
                let listCols = panel
                    ? panel.getElementsByClassName('p-multiselect-items-wrapper')[0]
                    : null;
                colToggle.onclick = (e) => {
                    if (e.target === span || e.target === colToggle) {
                        if (panel && panel.style.display !== 'block') {
                            setTimeout(() => this.focusEl(panel), 0);
                            if (listCols) {
                                let maxH = 0;
                                if (parent) {
                                    const parentBound = parent.getBoundingClientRect();
                                    const colToggleBound = colToggle.getBoundingClientRect();
                                    let offTopParent = colToggleBound.top - parentBound.top;
                                    let offBotParent = parentBound.height - offTopParent;
                                    if (offTopParent > offBotParent) {
                                        panel.className = panel.className
                                            .replace(' p-up', '')
                                            .replace(' p-down', '');
                                        panel.className += ' p-up';
                                        maxH = offTopParent - 45;
                                    } else {
                                        panel.className = panel.className
                                            .replace(' p-up', '')
                                            .replace(' p-down', '');
                                        panel.className += ' p-down';
                                        maxH = offBotParent - 50;
                                    }
                                } else {
                                    panel.className = panel.className
                                        .replace(' p-up', '')
                                        .replace(' p-down', '');
                                    panel.className += ' p-up';
                                    maxH =
                                        window.pageYOffset +
                                        colToggle.getBoundingClientRect().top -
                                        150;
                                }
                                if (maxH) {
                                    listCols.style.maxHeight = maxH > 300 ? '300px' : maxH + 'px';
                                }
                            }
                        }
                    }
                };
                if (input) {
                    input.tabIndex = -1;
                }
                if (span) {
                    span.tabIndex = 0;
                    span.onkeydown = (e) => {
                        if (e.keyCode === 13) {
                            //press Enter
                            colToggle.click();
                        }
                    };
                    if (panel) {
                        const listFocus = this.getFocusableEl(panel);
                        const firstEl = listFocus[0];
                        const lastEl = listFocus[listFocus.length - 1];
                        panel.onkeydown = (e) => {
                            if (e.keyCode === 9) {
                                if (e.shiftKey) {
                                    //shift tab
                                    if (e.target === firstEl) {
                                        lastEl.focus();
                                        e.preventDefault(); //prevent duplicate tab
                                    }
                                } else {
                                    // tab
                                    if (e.target === lastEl) {
                                        firstEl.focus();
                                        e.preventDefault(); //prevent duplicate tab
                                    }
                                }
                            } else if (panel.style.display === 'block' && e.keyCode === 27) {
                                //Being displayed and press Escape
                                colToggle.click();
                                span.focus();
                            }
                        };
                        let btnClose = panel.getElementsByClassName('p-multiselect-close')[0];
                        if (btnClose) {
                            btnClose.onclick = () => {
                                span.focus();
                            };
                        }
                    }
                }
            }
        } catch (error) {

        }
    };

    getFocusableEl = (element) => {
        let listFocus = element.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        listFocus = _.filter(listFocus, (el) => !el.disabled && !el.hidden && el.tabIndex !== -1);
        return listFocus;
    };

    handlerSubmitGoToPage = (e) => {
        //
        //
        //
        //
        // This script handler loaded-type datatable (take all record at once request)
        e.preventDefault();

        const totalRecord = this.props.totalRecords
            ? this.props.totalRecords
            : this.state.totalRecord;

        // In single page may have many datatable
        // Instead of selectById , catch value with target to avoid id duplication
        const { value: pageIndex } = e.target.children[1];

        let error = '';

        //

        if (isNaN(Number(pageIndex)) || pageIndex === '') {
            error = this.trans('common:button.invalidInput');
        } else if ((pageIndex - 1) * this.state.rows >= totalRecord || Number(pageIndex) <= 0) {
            error = this.trans('common:button.invalidPage');
        }

        this.setState({
            error: error,
        });
        if (error.length === 0) {
            // specific thread between LOCAL AND REQUEST go to page
            if (typeof this.props.onPage === 'function') {
                this.onPageMiddleware({
                    first: (pageIndex - 1) * this.props.rows,
                    rows: this.props.rows,
                    page: pageIndex - 1,
                });
            } else {
                this.onPageMiddleware({ first: (pageIndex - 1) * this.state.rows, rows: this.state.rows });
            }
        }
    };

    getColToggle = () => {
        return (
            <>
                <div className='paginator-go-to-page'>
                    <form onSubmit={this.handlerSubmitGoToPage} id='paginatorForm'>
                        <CustomValidTooltip>{this.state.error}</CustomValidTooltip>
                        <CommonInputText
                            placeholder='Đi đến trang'
                            type='number'
                            onFocus={() => this.setState({ error: '' })}
                            id='paginator-value'
                        />
                        <button type='submit'>
                            <i className='pi pi-arrow-right' />
                        </button>
                    </form>
                </div>
                <div className='extention-wrapper'>
                    {this.dt ? (
                        <ButtonLink
                            title={this.trans('common:msg.exportCsv')}
                            placement='bottom'
                            className={'flex-100 center-row-y center-row-pi'}
                            onClick={() => this.exportCSV()}
                        >
                            {Constants.TABLE_ICON.Export}
                        </ButtonLink>
                    ) : (
                        <></>
                    )}
                    <MultiSelect
                        value={this.state.colsSelects}
                        options={this.state.colOptions}
                        onChange={this.onColumnToggle}
                        className={'colToggle'}
                    />
                </div>
            </>
        );
    };

    onColumnToggle = async (event) => {
        if (event.value.length || this.state.colsDefault.length) {
            await this.setState({ colsSelects: event.value });
        }
    };

    onValueChange = async (e) => {
        if (e) {
            // invoke khi datatable thay doi --> them thuoc tinh selectionModeCustom vao, neu co thuoc tinh nay thi ko set element dau tien trong table
            if (
                this.props.autoSelect &&
                this.props.onSelectionChange &&
                !this.props.selectionModeCustom
            ) {
                this.props.onSelectionChange({ value: e[0] ? e[0] : {} });
            }
            await this.setState({ totalRecord: e ? e.length : 0 });
        }
    };

    /**
     * @author tanpn16
     * @description Export CSV
     * @type () => void
     */
    exportCSV = async () => {
        const { lazyExport, exportFetcher, totalRecords } = this.props;
        let value = this.props.value;

        if (lazyExport) {
            value = await exportFetcher(totalRecords);
        }

        if (value && value.length) {
            const hash = {};
            const headerLabel = [];
            this.props.children.forEach((child) => {
                if (!stringNullOrEmpty(child.props.field) && !stringNullOrEmpty(child.props.header)) {
                    console.log(child.props);
                    if (typeof child.props.header === 'object') {
                        hash[child.props.field] = child.props.header?.key;
                        headerLabel.push(child.props.header?.key)
                    }else {
                        hash[child.props.field] = child.props.header
                        headerLabel.push(child.props.header)
                    }
                }
            });

            const valueWithLabel = value.map((item) => {
                const newItem = {};
                let i = 0;
                for (const key in item) {
                    if (hash[key]) {
                        newItem[hash[key]] = item[key];
                    }
                }
                return newItem;
            });


            const worksheet = xlsx.utils.json_to_sheet(valueWithLabel , {header : headerLabel});

            const workbook = xlsx.utils.book_new();

            xlsx.utils.book_append_sheet(workbook, worksheet, 'Data Sheet 1');

            xlsx.utils.sheet_add_aoa(worksheet, [   ], { origin: 'A1' });

            let wscols = [];
            headerLabel.map((arr) => {
                wscols.push({ wch: arr?.length ?? 0 + 10 });
            });

            worksheet['!cols'] = wscols;

            xlsx.writeFile(workbook, 'Download.xlsx', { compression: true });

            // this.dt.exportCSV();
        }
    };

    handleRef = (el) => {
        if (this.props.forwardRef) {
            this.props.forwardRef(el);
        }
        this.dt = el;
    };

    onPage = ({ first, rows, page, pageCount }) => {
        //
        this.setState({
            first: first,
            rows: rows,
        });
    };

    onPageMiddleware = (e) => {

        try {
            this.dt.container.getElementsByClassName('p-datatable-scrollable-body')[0].scroll({
                top:       0,        // Number of pixels along the Y axis to scroll the window or element
                left:      0,        // Number of pixels along the X axis to scroll the window or element.
                behavior:  'smooth'  // ('smooth'|'auto') - animate smoothly, or move in a single jump
            })
        } catch (error) {
            console.error(error);
        }

        this.props.onPage ? this.props.onPage(e) : this.onPage(e)
    }

    render() {
        //
        let paginator =
            this.props.paginator === false ? false : !this.props.paginator === true ? true : true;
        if (!paginator) {
            this.state.rows = this.state.totalRecord;
        }
        return (
            <>
                <DataTable
                    {...this.props}
                    first={this.props.first ? this.props.first : this.state.first}
                    rows={this.props.onPage ? this.props.rows : this.state.rows}
                    onPage={this.onPageMiddleware}
                    id='primereact-datatable'
                    value={this.props.value ? this.props.value : []}
                    ref={this.handleRef}
                    paginator={paginator}
                    scrollable={true}
                    // emptyMessage={this.trans("table.noData")}
                    emptyMessage={
                        <div class='ant-empty ant-empty-normal'>
                            <div class='ant-empty-image'>
                                <svg
                                    class='ant-empty-img-simple'
                                    width='64'
                                    height='41'
                                    viewBox='0 0 64 41'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <g transform='translate(0 1)' fill='none' fill-rule='evenodd'>
                                        <ellipse
                                            className='ant-empty-img-simple-ellipse'
                                            cx='32'
                                            cy='33'
                                            rx='32'
                                            ry='7'
                                        ></ellipse>
                                        <g class='ant-empty-img-simple-g' fill-rule='nonzero'>
                                            <path d='M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z'></path>
                                            <path
                                                d='M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z'
                                                class='ant-empty-img-simple-path'
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div class='ant-empty-description'>
                                {this.trans('common:table.noData')}
                            </div>
                        </div>
                    }
                    resizableColumns={true}
                    columnResizeMode='fit'
                    paginatorRight={this.getColToggle()}
                    paginatorLeft={totalRecords(
                        this.props.totalRecords ? this.props.totalRecords : this.state.totalRecord
                    )}
                    onValueChange={this.onValueChange}
                >
                    {React.Children.map(this.props.children, (child, idx) => {
                        if (!child.props.field || child.props.visibleCol) {
                            return child;
                        } else {
                            for (let col of this.state.colsSelects) {
                                if (col.field === child.props.field) return child;
                            }
                        }
                    })}
                </DataTable>
            </>
        );
    }
}

Datatable.propTypes = {
    autoSelect: PropTypes.bool,
    rowsPerPageOptions: PropTypes.array,
    forwardRef: PropTypes.func,
    rows: PropTypes.number,
    scrollHeight: PropTypes.any,
};

Datatable.defaultProps = {
    autoSelect: true,
    rowsPerPageOptions: TABLE.SIZE_PER_PAGE,
    rows: TABLE.ROWS,
    scrollHeight: '268px',
};

export default withTranslation('common')(Datatable);

export const totalRecords = (totalRecord = 0) => {
    return (
        <Label style={{ marginLeft: '10px' }}>
            <FTUTrans ns='common' name='table.totalRecords' /> {totalRecord}
        </Label>
    );
};

export const genNoCol = (data, column) => {
    return <>{column.rowIndex + 1}</>;
};

export const genColumnValue = (
    field,
    lstValue,
    valueCode = 'code',
    valueName = 'name',
    rowData,
    column
) => {
    if (stringNullOrEmpty(rowData)) return '';
    let value = rowData[field];
    if (lstValue) {
        lstValue.map((item) => {
            //     if (!stringNullOrEmpty(value)
            //     || (stringNullOrEmpty(item[valueCode]) && stringNullOrEmpty(value))
            // ) {
            if (value == item[valueCode]) value = item[valueName];
            // }
        });
    }
    return value;
};

export const genColumnValueNumber = (
    field,
    lstValue,
    valueCode = 'code',
    valueName = 'name',
    rowData,
    column
) => {
    if (stringNullOrEmpty(rowData)) return '';
    let value = rowData[field];
    if (lstValue && !stringNullOrEmpty(value))
        lstValue.map((item) => {
            if (value == item[valueCode]) value = item[valueName];
        });
    return addThousandSeparator(value);
};

export const genColumnDate = (
    field,
    srcDateFormat = Constants.DATE_FORMAT_REV,
    desDateFormat = Constants.DATE_FORMAT,
    data,
    column
) => {
    if (data && data[field]) return moment(data[field], srcDateFormat).format(desDateFormat);
    else return null;
};

export const genColumnNumber = (field, rowData, column) => {
    if (stringNullOrEmpty(rowData[field])) return '';
    let value = rowData[field];
    return addThousandSeparator(value);
};

export const genColumnFromObject = (field, property, rowData, column) => {
    let object = rowData[field];
    if (object && object[property]) return object[property];
    else return '';
};

export const genColumnCheckBox = (field, rowData, column) => {
    return <Checkbox disabled checked={rowData[field] == 1 || rowData[field] === true} style={{ textAlign: 'center' }} />;
};

export const genFilter = (context, options, field, code = 'code', name = 'name') => {
    return genFilterDropdown(context, field, 'equals', options, code, name);
};

export const genFilterDropdown = (context, field, mode, options, code = 'code', name = 'name') => {
    return (
        <Dropdown
            style={{ width: '100%' }}
            optionLabel={name}
            dataKey={code}
            value={context.state.filter ? context.state.filter[field] : null}
            options={options}
            onChange={filterDropdownChange.bind(this, context, field, mode, code)}
            showClear={true}
            filter={true}
            appendTo={document.body}
        />
    );
};

export const filterDropdownChange = (context, field, mode, code, event) => {
    (context.dt ?? context.dt1).filter(event.value ? event.value[code] : event.value, field, mode);
    context.setState((prevState) => ({
        filter: {
            ...prevState.filter,
            [field]: event.value,
        },
    }));
};

export const genFilterDropdownByIdAndApp = (
    context,
    field,
    mode,
    options,
    id = 'id',
    code = 'code',
    name = 'name'
) => {
    return (
        <Dropdown
            style={{ width: '100%' }}
            optionLabel={name}
            dataKey={code}
            value={context.state.filter ? context.state.filter[field] : null}
            options={options}
            onChange={filterDropdownChange.bind(this, context, field, mode, id)}
            showClear={true}
            filter={true}
            appendTo={document.body}
        />
    );
};

export const genFilterDate = (
    context,
    field,
    format = FORMAT_DATE_PICKER,
    placeholder = DATE_FORMAT.DATE_FORMAT,
    formatrev = DATE_FORMAT.DATE_FORMAT_REV
) => {
    return (
        <DatePicker
            style={{ width: '100%' }}
            format={format}
            placeholder={placeholder}
            value={context.state.filter ? context.state.filter[field] : null}
            onChange={filterDateChange.bind(this, context, field, formatrev)}
        />
    );
};

export const filterDateChange = (context, field, formatrev, date) => {
    context.dt.filter(date ? date.format(formatrev) : '', field, 'equals');
    context.setState((prevState) => ({
        filter: {
            ...prevState.filter,
            [field]: date,
        },
    }));
};

export const genFilterDateNew = (
    context,
    field,
    format = FORMAT_DATE_PICKER,
    placeholder = DATE_FORMAT.DATE_FORMAT,
    formatrev = DATE_FORMAT.DATE_FORMAT_REV,
    showTime = false,
    mode = 'equals'
) => {
    return (
        <DatePicker
            style={{ width: '100%' }}
            format={format}
            placeholder={placeholder}
            showTime={showTime}
            value={context.state.filter ? context.state.filter[field] : null}
            onChange={filterDateChangeNew.bind(this, context, field, formatrev, mode)}
        />
    );
};

export const filterDateChangeNew = (context, field, formatrev, mode, date) => {
    context.dt.filter(date ? date.format(formatrev) : '', field, mode);
    context.setState((prevState) => ({
        filter: {
            ...prevState.filter,
            [field]: date,
        },
    }));
};

export const genFilterInputText = (context, field, mode = 'contains') => {
    return (
        <InputText
            style={{ width: '100%' }}
            onKeyDown={(e, val) => {
                if (e.keyCode === 13) {
                    filterInputTextChange(context, field, mode, e);
                }
            }}
            onBlur={filterInputTextChange.bind(this, context, field, mode)}
            defaultValue={
                context.state.filter && context.state.filter[field]
                    ? context.state.filter[field]
                    : ''
            }
        />
    );
};

export const filterInputTextChange = (context, field, mode, e) => {
    let realValue = '';
    if (e.target && e.target.value) {
        realValue = e.target.value;
    }
    let filter = context.state.filter ? context.state.filter : {};
    if (filter[field] === undefined) {
        filter[field] = '';
    }
    if (realValue !== filter[field]) {
        context.dt.filter(realValue, field, mode);
        filter[field] = realValue;
        context.setState({ filter: filter });
    }
};

export const genFilterInputNumber = (
    context,
    field,
    remainnumber = 16,
    decimalScale = 4,
    mode = 'contains'
) => {
    return (
        <InputNumber
            remainnumber={remainnumber}
            decimalScale={decimalScale}
            value={
                context.state.filter && context.state.filter[field]
                    ? context.state.filter[field]
                    : ''
            }
            onValueChange={filterInputNumberChange.bind(this, context, field, mode)}
        />
    );
};

export const filterInputNumberChange = (context, field, mode, e) => {
    let realValue = '';
    if (e.value) {
        realValue = e.value;
    }
    context.dt.filter(realValue, field, mode);
    context.setState((prevState) => ({
        filter: {
            ...prevState.filter,
            [field]: realValue,
        },
    }));
};

export const sortFieldDate = (data, field, format = 'DD/MM/YYYY', event) => {
    let comparer = function (a, b) {
        let result = -1;
        if (a[field] && b[field]) {
            let formatedA = moment(a[field], format);
            let formatedB = moment(b[field], format);
            if (moment(formatedB).isBefore(formatedA, 'day')) result = 1;
        } else if (a[field]) {
            result = 1;
        }

        return result * event.order;
    };
    return data.sort(comparer);
};
