import React from 'react'
import {Select} from "antd";
import "antd/dist/antd.css";
import {stringNullOrEmpty} from "./CommonUtils";
import { v4 as uuidv4 } from 'uuid';

const {Option} = Select;

export const renderSelectComponent = (data, id = "code", code = "code", name = "name", nullItem = true, header = true) => {
    let children = [];
    if (header) {
        children.push(
            <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                    style={{
                        borderTop: "1px solid #a6a6a6",
                        backgroundColor: "#928e0a42",
                        textAlign: "center"
                    }}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={"Mã"}><b>Mã</b></div>
                    <div className="p-col-8 noWrap" title={"Tên"}><b>Tên</b></div>
                </div>
            </Option>
        );
    }
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap">{""}</div>
                    <div className="p-col-8 noWrap">{""}</div>
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        children.push(
            <Option className="optionSelectItem" value={String(item[id])} key={String(item[code])} label={item[name]}
                    ftuitem={item}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={item[code]}>{item[code]}</div>
                    <div className="p-col-8 noWrap" title={item[name]}>{item[name]}</div>
                </div>
            </Option>
        );
    });
    return children;
};


export const renderSelectComponentMultiColumn = (data, id = "code", label="name", lstField = ["code", "name"], lstHeader = ["Mã", "Tên"], lstCol = [4, 8], nullItem = true, header = true) => {
    //
    //
    let children = [];
    if (header) {
        children.push(
            <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                    style={{
                        borderTop: "1px solid #a6a6a6",
                        backgroundColor: "#928e0a42",
                        textAlign: "center"
                    }}>
                <div className="p-grid itemProp">
                    {lstHeader.map((item, index) => <div key={"header"+index}
                        className={`${"p-col-" + lstCol[index] + " noWrap " + ((index !== lstField.length - 1) ? "borderRight" : "")}`}
                        title={item}><b>{item}</b>
                    </div>)}
                </div>
            </Option>
        );
    }
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    {lstHeader.map((item, index) => <div key={"nullItem"+index}
                        className={`${"p-col-" + lstCol[index] + " noWrap " + ((index !== lstField.length - 1) ? "borderRight" : "")}`}>
                        <b>{""}</b>
                    </div>)}
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        children.push(
            <Option className="optionSelectItem"  value={String(item[id])} key={String(item[id])} label={String(item[label])}
                    ftuitem={item}>
                <div className="p-grid itemProp">
                    {lstField.map((itemField, index) => <div key={"item"+item[id]+index}
                        className={`${"p-col-" + lstCol[index] + " noWrap " + ((index !== lstField.length - 1) ? "borderRight" : "")}`}
                        title={item[itemField]}>{item[itemField]}</div>)}
                </div>
            </Option>
        );
    });
    return children;
};

export const convertValue = (item) => {
    if (stringNullOrEmpty(item)) {
        return ""
    }
    return String(item);
}

//Neu multiple choose va du lieu luu chuoi string
export const splitSelectItem = (itemValue, separator) => {
    if (stringNullOrEmpty(itemValue)) return [];
    if (typeof itemValue === "string" && itemValue.indexOf(separator) > 0) {
        let arr = itemValue.split(separator);
        if (arr.length > 0) return arr;
        else return [];
    } else return itemValue;
}

export const renderSelectForFilter = (data, id = "code", code = "code", name = "name", nullItem = true) => {
    let children = [];
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={""}>
                <div className={"itemProp"}>{""}</div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        children.push(
            <Option className="optionSelectItem" key={String(item[id])} label={item[name]}>
                <div className={"itemProp"}>{item[name]}</div>
            </Option>
        );
    });
    return children;
};

export const renderCbbHLR = (data, id = "hlrId", code = "hlrCode", name = "hlrName", nullItem = true, header = true) => {
    let children = [];
    if (header) {
        children.push(
            <Option className="optionSelectItem" disabled key={"select"} label="-- Lựa chọn --">
                <div className="p-grid itemProp">
                    <div className="p-col-4 cell" style={{borderRight: "1px solid #a6a6a6", height: "37px"}}>Mã</div>
                    <div className="p-col-8 cell">Tên</div>
                </div>
            </Option>
        );
    }
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 cell" style={{borderRight: "1px solid #a6a6a6", height: "37px"}}>{""}</div>
                    <div className="p-col-8 cell" style={{height: "37px"}}>{""}</div>
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        children.push(
            <Option className="optionSelectItem" value={String(item[id])} key={String(item[code])} label={item[name]}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 cell"
                         style={{borderRight: "1px solid #a6a6a6", height: "37px"}}>{item[code]}</div>
                    <div className="p-col-8 cell">{item[name]}</div>
                </div>
            </Option>
        );
    });
    return children;
};

export const employeeCode = (data, id = "staffCode", code = "staffCode", name = "staffName", nullItem = true, header = true) => {
    let children = [];
    if (header) {
        children.push(
            <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                    style={{
                        borderTop: "1px solid #a6a6a6",
                        backgroundColor: "#928e0a42",
                        textAlign: "center"
                    }}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={"Mã"}><b>Mã</b></div>
                    <div className="p-col-8 noWrap" title={"Tên"}><b>Tên</b></div>
                </div>
            </Option>
        );
    }
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap">{""}</div>
                    <div className="p-col-8 noWrap">{""}</div>
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        children.push(
            <Option className="optionSelectItem" value={String(item[id])} key={String(item[code])} label={item[name]}
                    ftuitem={item}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={item[code]}>{item[code]}</div>
                    <div className="p-col-8 noWrap" title={item[name]}>{item[name]}</div>
                </div>
            </Option>
        );
    });
    return children;
};

export const renderSelectComponentId = (data, id = "id", code = "code", name = "name", nullItem = true, header = true) => {
    let children = [];
    if (header) {
        children.push(
            <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                    style={{
                        borderTop: "1px solid #a6a6a6",
                        backgroundColor: "#928e0a42",
                        textAlign: "center"
                    }}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={"Mã"}><b>Mã</b></div>
                    <div className="p-col-8 noWrap" title={"Tên"}><b>Tên</b></div>
                </div>
            </Option>
        );
    }
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap">{""}</div>
                    <div className="p-col-8 noWrap">{""}</div>
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        children.push(
            <Option className="optionSelectItem" key={String(item[id])} label={item[name]} ftuitem={item}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={item[code]}>{item[code]}</div>
                    <div className="p-col-8 noWrap" title={item[name]}>{item[name]}</div>
                </div>
            </Option>
        );
    });
    return children;
};

export const renderSelectProperties = (data, code, value, type, description, codeLabel, nameLabel) => {
    let children = [];
    children.push(
        <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                style={{
                    borderTop: "1px solid #a6a6a6",
                    backgroundColor: "#928e0a42",
                    textAlign: "center"
                }}>
            <div className="p-grid itemProp">
                <div className="p-col-4 borderRight noWrap" title={"Mã"}><b>Mã</b></div>
                <div className="p-col-8 noWrap" title={"Tên"}><b>Tên</b></div>
            </div>
        </Option>
    );
    if (data && data.length > 0) data.forEach(item => {
        let strKey = item[code] + "-" + item[value] + "-" + item[type] + "-" + item[description];
        children.push(
            <Option className="optionSelectItem" value={strKey} key={strKey} label={item[nameLabel]} ftuitem={item}>
                <div className="p-grid itemProp">
                    <div className="p-col-4 borderRight noWrap" title={item[codeLabel]}>{item[codeLabel]}</div>
                    <div className="p-col-8 noWrap" title={item[nameLabel]}>{item[nameLabel]}</div>
                </div>
            </Option>
        );
    });
    return children;
};

export const renderSelectComponentCustom = (data, key = "code", label = "name", listItems = {}, nullItem = true, header = true) => {
    let children = [];
    const lstItemNames = Object.keys(listItems);
    let lstClassName = [];
    let lstTitle = [];
    // add item to list classname and title for divs list
    let n = lstItemNames.length;
    for (let i = 0; i < n - 1; i++) {
        lstClassName.push("col-md-" + listItems[lstItemNames[i]].col + " borderRight noWrap");
        lstTitle.push(listItems[lstItemNames[i]].title);
    }
    // the last item
    lstClassName.push("col-md-" + listItems[lstItemNames[n - 1]].col + " noWrap");
    lstTitle.push(listItems[lstItemNames[n - 1]].title);
    if (header) {
        let lstDivs = [];
        for (let i = 0; i < n; i++) {
            lstDivs.push(<div className={lstClassName[i]} key={lstItemNames[i]} title={lstTitle[i]}>{lstTitle[i]}</div>);
        }
        children.push(
            <Option className="optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                    style={{
                        borderTop: "1px solid #a6a6a6",
                        backgroundColor: "#928e0a42",
                        textAlign: "center"
                    }}>
                <div className="p-grid itemProp">
                    {lstDivs}
                </div>
            </Option>
        );
    }
    if (nullItem) {
        let lstDivs = [];
        for (let i = 0; i < n; i++) {
            lstDivs.push(<div className={lstClassName[i]} key={lstItemNames[i]}/>);
        }
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    {lstDivs}
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {
        let lstDivs = [];
        for (let i = 0; i < n; i++) {
            lstDivs.push(<div className={lstClassName[i]} key={lstItemNames[i]} title={item[lstItemNames[i]]}>{item[lstItemNames[i]]}</div>);
        }
        children.push(
            <Option className="optionSelectItem" value={String(item[key])} key={String(item[key])} label={item[label]}
                    ftuitem={item}>
                <div className="p-grid itemProp">
                    {lstDivs}
                </div>
            </Option>
        );
    });
    return children;
};


export const renderSelectComponentMultiColumnFilterListField = (data, id = "code", label="name", lstField = ["code", "name"], lstHeader = ["Mã", "Tên"], lstCol = [4, 8], nullItem = true, header = true) => {
    let children = [];
    if (header) {
        children.push(
            <Option className="select-test optionSelectItem optionSelectItemHeader" disabled key={"select"} label="-- Lựa chọn --"
                    style={{
                        borderTop: "1px solid #a6a6a6",
                        backgroundColor: "#928e0a42",
                        textAlign: "center"
                    }}>
                <div className="p-grid itemProp">
                    {lstHeader.map((item, index) => <div key={"header"+index}
                                                         className={`${"p-col-" + lstCol[index] + " noWrap " + ((index !== lstField.length - 1) ? "borderRight" : "")}`}
                                                         title={item}><b>{item}</b>
                    </div>)}
                </div>
            </Option>
        );
    }
    if (nullItem) {
        children.push(
            <Option className="optionSelectItem" key={null} label={" "}>
                <div className="p-grid itemProp">
                    {lstHeader.map((item, index) => <div key={"nullItem"+index}
                                                         className={`${"p-col-" + lstCol[index] + " noWrap " + ((index !== lstField.length - 1) ? "borderRight" : "")}`}>
                        <b>{""}</b>
                    </div>)}
                </div>
            </Option>
        );
    }
    if (data && data.length > 0) data.forEach(item => {

        let lable = "";
        lstField.map(field => lable = lable + item[field] + " ");

        children.push(
            <Option className="optionSelectItem" value={String(item[id])} key={uuidv4()} label={lable}
                    ftuitem={item}>
                <div className="p-grid itemProp">
                    {lstField.map((itemField, index) => <div key={"item"+item[id]+index}
                                                             className={`${"p-col-" + lstCol[index] + " noWrap " + ((index !== lstField.length - 1) ? "borderRight" : "")}`}
                                                             title={item[itemField]}>{item[itemField]}</div>)}
                </div>
            </Option>
        );
    });
    return children;
};



export const filterSelectOption = (input, event) => {
  return String(event.key).toLowerCase().indexOf(input.toLowerCase()) >= 0 || String(event.props.label).toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
