import { Select } from "antd";
import PropTypes from 'prop-types';
import React from 'react';
import { FTUComponent } from "../FTUComponent";
import { stringNullOrEmpty } from "./CommonUtils";
import { renderSelectComponentMultiColumn } from "./RenderSelectItem";


const _ = require('lodash');

export class FTULazySelect extends FTUComponent {
    constructor(props) {
        super(props);
        let {value, ...restProps} = props;
        let renderParams = props.renderParams ? props.renderParams : [];
        this.setParameters(renderParams);
        // if (props.containerId && !restProps.getPopupContainer) {
        //     restProps.getPopupContainer = () => document.getElementById(props.containerId);
        // }
        this.restProps = restProps;
        this.listData = renderParams[0] ? renderParams[0] : [];
        this.state = {
            searchValue: "",
            fullData: this.listData,
            visibleData: [],
            scrollHeight: props.scrollHeight ? props.scrollHeight : 20,
            rowNumber: props.rowNumber ? props.rowNumber : 20,
            valueSearch: ""
        };
    }

    setParameters = (list) => {
        this.renderParams = [
            list[1] ? list[1] : "code",
            list[2] ? list[2] : "name",
            list[3] ? list[3] : ["code", "name"],
            list[4] ? list[4] : ["Mã", "Tên"],
            list[5] ? list[5] : [4, 8],
            stringNullOrEmpty(list[6]) ? true : list[6],
            stringNullOrEmpty(list[7]) ? true : list[7],
            list[8] ? list[8] : [false, false],
            stringNullOrEmpty(list[9]) ? false : list[9],
        ];
    };

    handleScroll = async e => {
        e.persist();
        const {target} = e;
        if (this.state.visibleData.length !== this.listData.length && target.scrollTop + target.offsetHeight > target.scrollHeight - 100) {
            this.setState({scrollHeight: this.state.scrollHeight + this.state.rowNumber});
            await this.getDropdownList();
        }
    };

    fetchData = async value => {
        const fields = this.renderParams[2];
        const valueFilter = value.toLowerCase();
        this.listData = _.filter(this.state.fullData, item => {
            for (let i = 0; i < fields.length; i++) {
                if (String(item[fields[i]]).toLowerCase().indexOf(valueFilter) >= 0) {
                    return true;
                }
            }
            return false;
        });
        if (this.listData.length > 1) {
            const strSearch = value.toLowerCase().trim();
            if (strSearch) {
                let listExact = [];
                for (let i = 0, n = this.listData.length; i < n; ++i) {
                    const item = this.listData[i];
                    for (let field of fields) {
                        if (String(item[field]).toLowerCase() === strSearch) {
                            listExact.push(item);
                            this.listData.splice(i, 1);
                            --i;
                            --n;
                            break;
                        }
                    }
                }
                if (listExact.length) {
                    this.listData = [...listExact, ...this.listData];
                }
            }
        }
        await this.setState({
            scrollHeight: this.state.rowNumber,
            valueSearch: value,
            visibleData: []
        })
        this.getDropdownList();
    };

    componentDidMount() {
        this.loadItemSelected();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.renderParams[0] !== prevProps.renderParams[0]) {
            this.listData = this.props.renderParams[0];
            if (this.listData) {
                this.loadItemSelected();
                this.setState({
                    fullData: this.listData,
                } , () => {
                    this.reloadDropdownList(true)
                });
            }
        }
        if (this.props.value && prevProps.value !== this.props.value) {
            if (this.props.mode !== "multiple") {
                this.setState({
                    visibleData: _.filter(this.state.fullData,
                        (item) => item[this.renderParams[0]] == this.props.value)
                });
            } else {
                let visibleData = this.state.visibleData;
                if (visibleData.length === 0 || _.intersectionWith(visibleData, this.props.value, (it1, it2) => it1[this.renderParams[0]] == it2).length !== this.props.value.length) {
                    this.setState({
                        visibleData: _.intersectionWith(this.state.fullData, this.props.value, (it1, it2) => it1[this.renderParams[0]] == it2)
                    });
                }
            }
        }
    }

    getDropdownList = async () => {
        await this.setState({
            visibleData: _.slice(this.listData, 0, this.state.scrollHeight)
        })
    };

    reloadDropdownList = async (isOpen) => {
        if (isOpen) {
            this.listData = this.state.fullData;
            await this.setState({
                visibleData: _.slice(this.state.fullData, 0, this.state.rowNumber),
                scrollHeight: 20,
                valueSearch: ""
            });
        }
    };

    loadItemSelected = () => {
        if (this.listData?.length && !stringNullOrEmpty(this.props.value)){
            this.setState({
                visibleData: _.filter(this.listData, (item) => item[this.renderParams[0]] == this.props.value)
            })
        }
    }

    render() {
        const value = this.props.value ? this.props.value : undefined;
        return (
            <Select
                mode={"single"}
                style={{width: "100%"}}
                showSearch={true}
                defaultActiveFirstOption={true}
                {...this.restProps}
                value={value}
                onDropdownVisibleChange={this.reloadDropdownList}
                onSearch={this.fetchData}
                filterOption={() => true}
                onPopupScroll={this.handleScroll}
                disabled={this.props.disabled}
            >
                {renderSelectComponentMultiColumn(this.state.visibleData, this.renderParams[0], this.renderParams[1],
                    this.renderParams[2], this.renderParams[3], this.renderParams[4],
                    stringNullOrEmpty(this.state.valueSearch) ? this.renderParams[5] : false,
                    this.renderParams[6], this.renderParams[7], this.renderParams[8])}
            </Select>
        );
    }
}

//Render Params: [data, id, label, lstField, lstHeader, lstCol, nullItem, header, lstStatusNumberFormat, formatLabel]

FTULazySelect.propTypes = {
    onChange: PropTypes.func,
    renderParams: PropTypes.array.isRequired,
    defaultActiveFirstOption: PropTypes.bool,
    allowClear: PropTypes.bool,
    optionLabelProp: PropTypes.string
};

