import React, { Component } from "react";

export class AppFooter extends Component {
 render() {
  return (
   <div className="layout-footer">
    {/* <span className="footer-text" style={{ marginRight: "5px" }}>
     {this.props.text}
    </span>
    <ul className="footer-link">
     <li className="footer-item">
      <a href="" target="_blank" rel="">
       Link 1
      </a>
     </li>
     <li className="footer-item">
      <a href="" target="_blank" rel="">
       Link 2
      </a>
     </li>
     <li className="footer-item">
      <a href="" target="_blank" rel="">
       Link 3
      </a>
     </li>
     <li className="footer-item">
      <a href="" target="_blank" rel="">
       Link 4
      </a>
     </li>
     <li className="footer-item">
      <a href="" target="_blank" rel="">
       Link 5
      </a>
     </li>
    </ul> */}
   </div>
  );
 }
}
