import { stringNullOrEmpty } from "../../../components/category/CommonUtils";

function checkMinLength(password) {
  const minLengthRegex = /^.{8,}$/;
  return minLengthRegex.test(password);
}

function checkMaxLength(password) {
  const maxLengthRegex = /^.{1,32}$/;
  return maxLengthRegex.test(password);
}

function checkSpecialCharacter(password) {
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  return specialCharRegex.test(password);
}

function checkUppercaseCharacter(password) {
  const uppercaseRegex = /[A-Z]/;
  return uppercaseRegex.test(password);
}

function checkLowercaseCharacter(password) {
  const lowercaseRegex = /[a-z]/;
  return lowercaseRegex.test(password);
}

function checkNotEmail(password) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !emailRegex.test(password) && !stringNullOrEmpty(password);
}

function checkNotUsername(password, username) {
  const usernameRegex = new RegExp(username, 'i');
  return !usernameRegex.test(password) && !stringNullOrEmpty(password);
}

export function validatePassword (password , username) {
  const policyChecks = [
      { check: checkMinLength(password), id: 'min-length' },
      { check: checkMaxLength(password), id: 'max-length' },
      { check: checkSpecialCharacter(password), id: 'special-char' },
      { check: checkUppercaseCharacter(password), id: 'uppercase-char' },
      { check: checkLowercaseCharacter(password), id: 'lowercase-char' },
      { check: checkNotEmail(password), id: 'not-email' },
      { check: checkNotUsername(password, username), id: 'not-username' }
  ];

  return policyChecks
}
