export const APP_DOMAIN = window.globalConfig.APP_DOMAIN;
export const THRID_PARTY_CHANGE_PASS = window.globalConfig.thridPartyChangePasswordUrl
export const MASTER_DATA_DOMAIN = window.globalConfig.MASTER_DATA_DOMAIN;
export const URL_EXPORT = window.globalConfig.adminApi;
export const URL_SSO_FRONT = window.globalConfig.ssoFront;
export const URL_SSO_BACK = window.globalConfig.ssoBack;

export const URL_SIMULATOR = "http://localhost:8082"

export const CLIENT_ID = window.globalConfig.clientId;
export const GRANT_TYPE_REFRESH_TOKEN = "refresh_token";

export const GET_COMMON_USER_INFOR = "permission/get-current-user-data";
export const PATH_LOGIN = "/auth/login";
export const PATH_LOGOUT = "/auth/logout";
export const PATH_GET_MENU = "/resource/sitemap";
export const PATH_GET_PERMISSION_BTN = "/resource/sitemapHidden";
export const PATH_REFRESH_TOKEN = "/auth/refresh";
export const PATH_USER_INFOR = "/resource/sitemap";
export const POST_LOG_ACCESS = "/log/access";
export const POST_LOG_ACCESS_PATH = "/log/access-path";

export const GET_BREADCRUMB = "module/get-bread-crumb";

export const GET_TOKEN_BY_RF = '/keycloak/get-token-by-rf-token'

// ? Link Account

/* admin service */
//ip categories
export const GET_ALL_IP_LIST = "/ip/get_all";
export const GET_TREE_GROUP = "/get_group";
export const INSERT_IP_LIST = "/ip/insert";
export const UPDATE_IP_LIST = "/ip/update";
export const DELETE_IP_LIST = "/ip/delete";
//module categories
export const GET_ALL_MODULE = "/module/get_all";
export const GET_ALL_APP = "/app/get_all";
export const INSERT_MODULE = "/module/insert";
export const UPDATE_MODULE = "/module/update";
export const DELETE_MODULE = "/module/delete";
export const GET_CHECK_MODULE = "/module/check_module_permit";
//system policy categories
export const GET_ALL_SYSTEM_POLICY = "/get_sys_policy";
export const UPDATE_SYSTEM_POLICY = "/update_sys_policy";

//applicationList
export const GET_APPLICATION_lIST_DATA = "app/get_all";
export const INSERT_APPLICATION = "app/insert";
export const DELETE_APPLICATION = "app/delete_app";
export const UPDATE_APPLICATION = "app/update";
export const GET_CHECK_APP = "app/check_app";
export const GET_APPLICATION_BY_STATUS = "app/get-all-by-status";
export const GET_APPLICATION_BY_STATUS_MANAGE = "app/get_all_by_status_manage";

export const GET_ALL_ITEM = "app/get-item-app";

//AccessLog
export const GET_USER = "/permission/get_user";
export const GET_DETAIL_PERMISSON = '/permission/get-detail-permission-data-by-res-id'
export const GET_FUNCTION = "/module/get_module_by_type";
export const SEARCH_ACCESS_LOG = "/log/get_access";
export const DELETE_ACCESS_LOG = "/log/delete_access_log";
export const GET_MODULE_BY_APP = "/module/get_module_by_app";

//ListGroupUser
export const GET_PATH_QUERY_LIST_GROUP_GROUP = "/permission/get_group_user";
export const GET_PATH_QUERY_LIST_GROUP = "/permission/get_group";
export const GET_PATH_QUERY_LIST_GROUP_BY_STATUS = "/permission/get-group-by-status";
export const GET_PATH_QUERY_LIST_GROUP_GROUP_BY_SCHEDULE = "/permission/lst-group-by-schedule";
export const GET_PATH_QUERY_LIST_GROUP_BY_MODULE = "/permission/lst-group-by-module";
export const GET_USER_BY_SCHEDULE = "/permission/schedule/get_user_schedule";
export const GET_PATH_QUERY_LIST_GROUP_USER = "/permission/get_user";
export const GET_PATH_QUERY_LIST_GROUP_USER_WITH_PAGE = "/permission/get_user_with_page";
export const GET_USER_BY_IP = "/ip/get-user-by-ip";
export const POST_PATH_LIST_GROUP_USER = "/user-role/create-account";
export const DELETE_USER = "/user-role/delete-account";
export const INACTIVE_ACCOUNT = "/user-role/inactive-account";
export const DELETE_GROUP = "/permission/delete_group";
export const ACTIVE_GROUP = "/permission/enable_group";
export const GET_PATH_USER_BY_GROUP = "/permission/get_user_by_group";
export const GET_PATH_USER_BY_GROUP_WITHOUT_TREE = "/permission/get_user_by_group_without_tree";
export const GET_PATH_MODULE_BY_USER = "/permission/get_module_by_user";
export const GET_PATH_GROUP_BY_USER = "/permission/get_group_by_user";
export const PUT_LIST_GROUP_USER_UPDATE_USER = "/user-role/update-account";
export const GET_PATH_QUERY_MODULE_GET_ALL = "/module/get_all";
export const PUT_LIST_GROUP_GROUP_DISABLE = "/permission/disable_group";
export const PUT_LIST_GROUP_GROUP_ENABLE = "/permission/enable_group";
export const GET_PATH_QUERY_MODULE_GROUP = "/permission/get_module_by_group";
export const PUT_LIST_GROUP_USER_UPDATE_GROUP = "/permission/update_group";
export const POST_PATH_LIST_GROUP_GROUP = "/permission/create_group";
export const GET_PATH_ORG = "/permission/get_org";
export const GET_PATH_SUB_ORG = "/permission/get-substitute-org";
export const GET_PATH_USER_BY_GROUP_WITHOUT_TREE_WITH_PAGE = "/permission/get-user-by-group-without-tree-with-page";
export const GET_USER_BY_GROUP_WITH_PAGE = "/permission/get-user-by-group-with-page";
//ListGroupUser NEW
export const GET_GROUP_BY_CODE = "/user-role/get-group-by-code";
export const GET_GROUP_BY_LIST_CODE = "/user-role/get-group-by-list-code";
export const GET_GROUP_BY_ACCOUNT = "/user-role/get-group-by-account";
export const GET_GROUPS_BY_ACCOUNT = "/user-role/get-groups-by-account";
export const GET_ALL_USER = "/user-role/get-all-account";
export const GET_USER_BY_ROLE = "/user-role/get-account-by-role";
export const GET_USER_NO_ACCOUNT = "/user-role/get-user-no-account";
export const SEARCH_USER_NO_ACCOUNT = "/user-role/search-user-no-account";
export const GET_ALL_ACCOUNT_BY_CONDITION = "/user-role/get-all-account-by-conditon";
export const GET_ACCOUNT_BY_CONDITION = "/user-role/get-account-by-conditon";
export const GET_PASSWORD_STATUS = "/user-role/get-password-status";
export const GET_ALL_CHILDREN_ROLE = "/user-role/get-all-children-role";
export const GET_RESOURCE_TYPE_LIST = "/user-role/get-resource-type-list";
export const GET_DATA_BY_RESOURCE_TYPE = "/user-role/get-data-by-resource-type";
export const GET_USER_INFO_BY_USER_NAME = "/user-role/get-user-info-by-account";
export const IMPORT_EXCEL_API = '/user-role/import-excel'
export const IMPORT_EXCEL_JOBTITLE_ROLE_API = '/job-title-role/import-excel'

export const GET_ORG_TREE_DATA = "/user-role/get-org-tree-data";
export const SAVE_PERMISSION = "/user-role/save-permisson";
export const GET_PERMISSION = "/user-role/get-permisson-data";
export const DELETE_PERMISSION = "/user-role/delete-permission-data";
export const IS_EXIST_PERMISSION = "/user-role/is-exist-permission-data";


export const GET_DEPT = "/apiFakeData/DepAndLinkedAccount/org.json"
export const GET_LINKED_ACCOUNT = "/apiFakeData/DepAndLinkedAccount/LinkedAccount.json"
export const UNLINK_ACCOUNT = "/user-info/unlink-account"
export const GET_LIST_LINK_ACCOUNT ="/apiFakeData/DepAndLinkedAccount/ListLinkAccount.json"
export const GET_LIST_ACCOUNT = "/public/keycloak/get-account-linked"
export const GET_LIST_ACCOUNT_SWITCH = "/public/keycloak/get-account-switch"
export const GET_ACCOUNT_TOKEN = "/public/keycloak/get-account?username="
export const POST_CODE_SWITCH_ACCOUNT = '/public/keycloak/switch-account'

//followChange
export const GET_RIGHT = "/permission/get_right";
export const GET_PATH_LOG = "/log/get_log";
export const DELETE_LOG = "/log/delete_module_log";
export const GET_PATH_QUERY_MODULE_GET_MODULE =
    "/module/get_module_by_type";

//listScheduleAccess
export const GET_DAY = "/schedule/get_day";
export const GET_ALL_SCHEDULE = "/schedule/get_all";
export const POST_SCHEDULE = "/schedule/insert";
export const DELETE_SCHEDULE = "schedule/delete";
export const UPDATE_SCHEDULE = "schedule/update";
export const SEARCH_SCHEDULE = "schedule/search";

//ChangePassword
export const CHANGE_PASSWORD = "permission/change_password";
export const RESET_PASSWORD = "permission/reset-password";
export const RESET_PASSWORD_EMAIL = "/user-role/reset-password";

//hanx them form organization
export const GET_ORGANIZATION_LIST_DATA = "organization/get_all";
export const GET_TREE_SEC_ORGANIZATION_BY_ID = "organization/get_tree_sec_organization_by_id";
export const INSERT_ORGANIZATION = "organization/insert";
export const UPDATE_ORGANIZATION = "organization/update";
export const DELETE_ORGANIZATION = "organization/delete_organization";
export const GET_ORGANIZATION_BY_ID = "organization/get_org_by_id";
export const UPDATE_DEPT_SHORT_NAME = "organization/update_dept_short_name";


export const GET_ALL_POSITION = "group_position/get_all_sec_position";
export const GET_GROUP_BY_POSITION = "group_position/get_group_by_position";
export const UPDATE_GROUP_POSITION = "group_position/update_group_position";
export const GET_USER_INFO_SSO = "/identity-api/auth/getUserInfo";

export const INSERT_APP_API = "app/insert_app_api";
export const UPDATE_APP_API = "app/update_app_api";
export const GET_ALL_API_BY_APP_ID = "app/get_all_app_api_by_app_id";
export const GET_AP_DOMAIN_BY_TYPE = "ap-domain/get_by_type";

export const GET_ALL_DEPARTMENT_ACTIVE = "group_position/get_department_active";
export const GET_ALL_DEPARTMENT = "group_position/get_department";
export const GET_ALL_POSITION_ACTIVE = "group_position/get_all_sec_position_active";
export const DOWNLOAD_TEMPLATE_IMPORT_GROUP_POSITION = "organization/get_template_import_group_position";
export const IMPORT_GROUP_POSITION = "organization/import_group_position";
export const GET_NOTIFY_BY_USER = "notify/get_notify_of_user";
export const SEEN_NOTIFY = "notify/seen_notify";

export const AP_DOMAIN_GET_ALL = "ap-domain/get_all";
export const AP_DOMAIN_INSERT = "ap-domain/insert";
export const AP_DOMAIN_UPDATE = "ap-domain/update";
export const AP_DOMAIN_DETELE = "ap-domain/delete";
export const AP_DOMAIN_GET_BY_TYPE = "ap-domain/get_by_type";
export const AP_DOMAIN_GET_TREE = "ap-domain/get-tree-ap-domain";
export const AP_DOMAIN_GET_DATA_BY_ID = "ap-domain/get-all-by-id";
export const AP_DOMAIN_GET_ALL_AP_DOMAIN = "ap-domain/get-all-ap-domain";
export const DELETE_BY_LIST_IDS = "ap-domain/delete-by-list-ids";
export const GET_VALUE_BY_CODE = "ap-domain/get-value-by-code"

export const TEMPLATE_GET_ALL = "template-email-sms/get-all";
export const TEMPLATE_DELETE = "template-email-sms/delete";
export const TEMPLATE_INSERT = "template-email-sms/insert";
export const TEMPLATE_UPDATE = "template-email-sms/update-template";

export const TEMPLATE_GET_ATTRIBUTE = "adm_template_email_sms/get-ihrp-by-code";
export const TEMPLATE_GET_ATTRIBUTE_PARENT_NOT_NULL = "adm_template_email_sms/get-ihrp-parent-is-not-null"
export const INSERT_AP_DOMAIN_BY_SMS_EMAIL = "adm_template_email_sms/insert-on-ap-domain"
export const DELETE_AP_DOMAIN_BY_SMS_EMAIL = "adm_template_email_sms/delete-on-ap-domain"

export const GET_TEMPLATE_BY_TYPE = "adm_template_email_sms/get-ap-domain-by-type"

export const GET_JOB_TITLE = "/job-title-role/get-job-title";
export const GET_JOB_TITLE_ROLE = "/job-title-role/get-job-title-role";
export const GET_JOB_POSITION = "/job-title-role/get-all-ihrp-position"
export const GET_TYPE_OBJECT = "job-title-role/get-all-type-object"

// export const GET_JOB_TITLE_ON_IHRP = "/job-title-role/get-job-title-on-ihrp"

export const GET_JOB_TITLE_ON_IHRP = "/job-title-role/get-job-title-group-by-company"

export const GET_JOB_TITLE_ROLE_BY_IHRP_CODE = "/job-title-role/get-job-title-role-and-ihrp-code";
export const SAVE_JOB_TITLE_ROLE = "/job-title-role/save-job-title-role";
export const GET_JOB_TITLE_ROLE_BY_CODE = "/job-title-role/get-job-title-role-by-code";
export const GET_ALL_USER_BY_JOB_TITLE = "/ihrp-org/get-all-user-by-job-title";

export const REMOVE_JOB_TITLE_ROLE = "/job-title-role/remove-job-title-role";
export const REMOVE_LIST_JOB_TITLE_ROLE = "/job-title-role/remove-list-job-title-role";
export const UPDATE_JOB_TITLE_ROLE = "/job-title-role/update-job-title-role"

export const MASTER_DATA_RETRY_ALL = "/general/retry-all";
export const MASTER_DATA_RETRY_WITH_ID = "/general/retry/";
export const MASTER_DATA_RETRY_WITH_LIST_ID = "/general/retry-by-list"
export const MASTER_DATA_CANCEL_RETRY_WITH_ID = "/master-data/cancel-retry/";
export const GET_ALL_MASTER_DATA_DISTRIBUTION_HIS = "/master-data-distribution-his/get-all";
export const GET_ALL_MASTER_DATA_RECEIVE_HIS = "/master-data-receive-his/get-all";
export const GET_ALL_MASTER_DATA_TYPE = "/general/get-all-master-type";

export const MASTER_DATA_MONITOR_GET_LIST_HEADER = "/master-data-monitor/get-list-header";
export const MASTER_DATA_MONITOR_GET_DATA_REPORT = "/master-data-monitor/get-data-report";

export const UPLOAD_FILE = "/file/upload";
export const GET_FILE_BY_CONDITION = "/file/get-file-by-condition";

export const GET_S3_IMAGE_LIST = '/file/get-file-on-cloud'

export const DELETE_FILE_BY_ID = '/file/delete-object-s3'

export const DOWNLOAD_FILE = "/file/get-file-base64";
export const PARAM_UPLOAD_FILE = "/file/get-param-upload-file";

//IHRP_ORG
export const GET_ALL_IHRP = "/ihrp-org/get-all-ihrp"
export const GET_ALL_IHRP_ORG = "/ihrp-org/get-ihrp-org-by-type-object"
export const GET_ALL_IHRP_BY_NODE = "/ihrp-org/get-all-ihrp-by-node"
export const GET_IHRP_BY_CODE = "/ihrp-org/get-by-code"
export const GET_IHRP_BY_CODE_AND_LEVEL = "/ihrp-org/get-all-ihrp-org"

// Sec-chanel
export const GET_ALL_SEC_CHANEL = "/sec-chanel/get-all-chanel";

export const GET_ALL_ATTRIBUTE = "/sec-chanel/get-all-attribute";

export const SAVE_CHANEL = "/sec-chanel/save-chanel"

export const SAVE_ATTRIBUTE = "/sec-chanel/update-attribute";

export const GET_ATTRIBUTE_BY_CHANEL = "/sec-chanel/get-attribute-by-chanel";

export const EDIT_CHANEL = "/sec-chanel/update-sec-chanel"

export const DELETE_SEC_ATTRIBUTE = "/sec-chanel/delete-sec-attribute"

export const GET_HOST_BY_CHANEL_TYPE = "/sec-chanel/get-host-by-channel-type"

export const GET_CHANEL_PARENT_IS_NULL = "/sec-chanel/get-chanel-parent-is-null"
export const GET_SEC_CHANNEL_TREE = "/sec-chanel/get-tree-sec-channel"
export const GET_ALL_SEC_CHANNEL_BY_CONDITION = "/sec-chanel/get-all-chanel-by-condition"

export const GET_CHANEL_PARENT = "/sec-chanel/get-chanel-parent"

export const DELETE_CHANNEL = "/sec-chanel/delete-sec-channel"

export const EAP_ON_SEARCH = "/ticket-permission/on-search";
export const EAP_RECEIVE_TICKET = "/ticket-permission/receive-ticket";


export const INSERT_PUBLIC_SMS_EMAIL = "/adm_template_email_sms/insert-template-email-sms";

export const GET_PUBLIC_EMAIL_SMS = "/adm_template_email_sms/get-all-public-template"

export const UPDATE_PUBLIC_EMAIL_SMS = "/adm_template_email_sms/update-template-email-sms";

export const DELETE_PUBLIC_EMAIL_SMS = "/adm_template_email_sms/delete-sms-email";

export const EAP_ON_UPDATE_TICKET_STATUS = "/ticket-permission/on-update-status"

export const USER_INFO = "/user-info/user-info-login"

export const USER_BASIC = "/user-info/user-info-basic"

export const CHANGE_PASS = "/user-info/changePass"

export const SEARCH_UNLINKED_ACCOUNT= "/user-info/get-unlinked-account"

export const REQUEST_OTP = "/user-info/account-linked"

export const VERIFY_OTP = "/user-info/linked"

export const UPDATE_ALIAS = "/user-info/update-alias?alias="

export const UPDATE_OTP = "/user-info/updateTOTP"

export const GET_CHANGEPASS_OTP = "/user-info/send-confirm-otp"

export const GET_ROLE_HISTORY = '/group-log/get-by-condition'

export const GET_USER_ROLE_DETAILS = "/group-log/get-detail-log"

// master data
export const GET_ALL_LOG_SEND = '/mail-history/get-all'

export const GET_ALL_MASTER_DATA_LOG = "/master-data-log-admin/get_all"

export const GET_BY_CONDITION_MASTER_DATA_LOG = "/master-data-log-admin/get_by_condition_es"

export const UPDATE_APPROVE_SCOPE = "/master-data-log-admin/enable"

export const GET_LOGIN_SESSION = "/user-info/get-login-session"

export const UPDATE_STATUS_INTERGRATE = "/master-data-log-admin/update-status"

export const RESOLVE = "/master-data-log-admin/resolve-log"

// ? Notification

export const GET_ALL_NOTIFICATION = "/notification/get-all";

export const GET_ALL_UNREAD_NOTIFICATION = "/notification/get-all-un-read";

export const READ_NOTIFICATION = "/notification/read";

export const READ_ALL_NOTIFICATION = "/notification/read-all";

export const COUNT_UN_READ = "/notification/count-noti-un-read";

export const GET_ROLE_TITLE_CODE_HISTORY = '/job-title-role/get-his-by-condition';

export const GET_DETAIL_ROLE_TITLE_CODE = '/job-title-role/get-detail-history'

export const GET_S3_FILE = 'file/generate-presign-url'

export const GROUP_HISTORY = '/group-log/history-user-of-group'
// ? MD Document

export const DOCUMENT_GET_BY_MODULEID = "/module/get-by-module-id";

export const GET_EXPORT_LOG = "/log/export-internal-log";

export const GET_SWITCH_LOG = "/switch-history/get-by-condition";

export const DELETE_USER_FROM_GROUP = '/user-role/remove-user-from-group'

export const UPDATE_APPROVE_TARGET = "/master-data-log-admin/update-approve-target";

export const UNLINK_OTP = '/user-info/otp-unlink-account';

export const GET_ALL_CREATE_ACCOUNT_EXTERNAL_LOG = '/user-info/get-create-account-external-log';

export const GET_DETAIL_EXTERNAL_LOG = '/user-info/get-detail-external-log';

export const UPLOAD_EXCEL_USER_ROLE = '/user-role/import-excel'

export const API_PARTNER_GET_ALL = '/api-partner/get_all'

export const LINK_ACCOUNT_ACTION = '/user-info/account-link-of-spadmin'

export const GET_LOGIN_LOGIN = '/log/get_login_log'

export const GET_COMPANY_LOGO = '/file/get-all-logo'

export const SAVE_LOGO = '/file/create-logo'

export const UPDATE_LOGO = '/file/update-logo'

export const DELETE_LOGO = '/file/delete-logo';


// Gateway
export const IMPORT_CONTACT_EXCEL_API = '/contact-info/import-excel'

export const GET_CONTACT_TREE_GROUP = '/contact-group/get-tree-group';

export const GET_CONTACT_DATATABLE = '/contact-info/get-by-contact-group-code?contactGroupCode=';

export const SAVE_CONTACT = '/contact-info/save';

export const DELETE_CONTACT = '/contact-info/delete';

export const UPDATE_CONTACT = '/contact-info/update';

export const GET_CONTACT_GROUP = '/contact-group/get-all-by-parent-code'

export const UPDATE_STATUS_CONTACT_GROUP = '/contact-group/update-status'

export const UPDATE_CONTACT_GROUP = "/contact-group/update"

export const GET_FILE_CONTENT = "/file/get-file";

export const GET_ALL_CONTACT = "/contact-info/get-all"

export const ADD_CONTACT_GROUP = "/contact-info/add-contact-info-to-group"

export const GET_ALL_CHANNEL = "/channel/get-all-channel"

export const SAVE_GW_CHANNEL = "/channel/save-channel"

export const DELETE_GW_CHANNEL = "/channel/delete-channel"

export const GET_FILE_BY_ID = "/channel/get-file"

export const UPDATE_CHANNEL = "/channel/update"

export const NOTIFICATION_GET_ALL = '/notification/get-all'

export const GET_EXCLUDE_GROUP_CODE = "/contact-info/get-exclude-group-code"

export const SAVE_NOTIFICATION = "/notification/save"

export const UPDATE_NOTIFICATION = "/notification/update"

export const SEND_NOTIFICATION = "/notification/send"

export const DELETE_NOTIFICATIONS = "/notification/delete"

export const GET_NOTIFICATION_BY_ID = "/notification/get-by-noti-id"

export const GET_GROUP_CODES_BY_ID = "/contact-info/get-group-codes-by-id"

export const GET_ALL_LOG = "/mail-history/get-all"

export const SAVE_GROUP_CONTACT = "/contact-group/save"

// New sso

export const LOGOUT_API_SSP = '/v1/auth/logoutAllSessions'
