import { withTranslation } from 'react-i18next';
import { FTUComponent } from '../../components/FTUComponent';
import { Button, ModalBody } from 'reactstrap';
import React, { Component } from 'react';
import className from './SwitchAccount.module.scss';
import { Utils } from '../../services/common/utils';
import { GET_LIST_ACCOUNT, GET_ACCOUNT_TOKEN } from '../../constants/AppPath';
import { Comment, message } from 'antd';
import Constants from '../../constants/Constants';
import Profile from './Profile';
import { AuthenticationService } from '../../services/access/AuthenticationService';
import Card from 'antd/lib/card/Card';
import SplitPane from 'react-split-pane';
import { ObjectUtils } from '../../utils/ObjectUtils';

class SwitchAccountDetailForm extends FTUComponent {
  constructor(props) {
    super(props);
    this.state = {
      onSelect: null,
      data: null,
    };
  }

  // ? mount
  async componentDidMount() {
    await this.getListLinkedAccount();
  }

  onSubmit = async (e) => {
    if (e.status !== 0) {
      const respond = await Utils.get(
        `${GET_ACCOUNT_TOKEN}${this.state.onSelect.userName}`,
        null,
        null,
        true
      );

      if (respond.data.code === Constants.SERVICE_CODE.SUCC_CODE && !ObjectUtils.checkIsNullObject(respond.data.data)) {
        AuthenticationService.switchAccount(respond.data.data);
      }else {
        this.commonHandleError({
          message : "Tài khoản người dùng chưa thao tác đổi mật khẩu lần đầu"
        })
      }
    }
  };

  async getListLinkedAccount() {
    const data = await Utils.post(GET_LIST_ACCOUNT, null, null, true);
    if (data.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
      
      await this.setState({
        onSelect: data.data.data.userADM,
        data: data.data.data,
        disabled : true,
      });
    }
  }

  onChangeSellectAccount(account) {
    if (account.status !== 0) {
      this.setState({
        onSelect: account,
        disabled : false
      });
    }
  }

  genNoData() {
    return (
      <div class='ant-empty ant-empty-normal'>
        <div class='ant-empty-image'>
          <svg
            class='ant-empty-img-simple'
            width='64'
            height='41'
            viewBox='0 0 64 41'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g transform='translate(0 1)' fill='none' fill-rule='evenodd'>
              <ellipse
                class='ant-empty-img-simple-ellipse'
                cx='32'
                cy='33'
                rx='32'
                ry='7'
              ></ellipse>
              <g class='ant-empty-img-simple-g' fill-rule='nonzero'>
                <path d='M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z'></path>
                <path
                  d='M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z'
                  class='ant-empty-img-simple-path'
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class='ant-empty-description'>{this.trans('common:data.noDataAccountLink')}</div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <ModalBody className='modal-body'>
          <div className={`${className.body}`}>
            <SplitPane
              className='flex-100 SplitPane split-pane vertical'
              minSize={'50%'}
              pane1Style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, flex: 'unset' }}
              pane2Style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1, flex: 'unset' }}
            >
              <div className={className.left + ' flex-100'}>
                <Profile user={this.state.onSelect} details={true} border />
              </div>
              <div className={`${className.right}  flex-100`}>
                <ul className={`${className.list}  flex-100`}>
                  {this.state.data &&
                    this.state.onSelect &&
                    this.state.data.userList.map((element) => {
                      return (
                        <li
                          disabled={true}
                          onClick={() => this.onChangeSellectAccount(element)}
                          onDoubleClick={() => this.onSubmit(element)}
                          key={element.email}
                          className={`${className.item} ${
                            this.state.onSelect.userName === element.userName
                              ? className.selected
                              : ''
                          }`}
                        >
                          <Profile user={element} onClick={this.handleDoubleClick} border />
                        </li>
                      );
                    })}
                  {this.state.data
                    ? this.state.data.userList.length === 0
                      ? this.genNoData()
                      : ''
                    : ''}
                </ul>
              </div>
            </SplitPane>
          </div>
          <div className={className.footer}>
            <Button disabled={this.state.disabled}  onClick={this.onSubmit}>{this.trans('common:button.accept')}</Button>
            <Button onClick={this.props.options.onCancel}>
              {this.trans('common:button.destroy')}
            </Button>
          </div>
        </ModalBody>
      </div>
    );
  }
}
export default withTranslation(['common'])(SwitchAccountDetailForm);
