import axios from 'axios';
import {APP_DOMAIN, MASTER_DATA_DOMAIN, URL_EXPORT} from 'constants/AppPath';
import {authHeader} from 'services/access/AuthenticationService';
import {hideProgress, showProgress} from "../../components/ProgressCustom";
import Constants from "../../constants/Constants";
import {handlerError} from './errorHandler';

export const emptyAxios =  {
    data : {
        code : Constants.SERVICE_CODE.ADMIN_ERROR,
        data : {}
    },
    config: {},
    request: {}
}


async function get(path, data, func, hasDialogProcess = false, domainName, requestOption) {
    let url = Utils._urlRender(path, data, true, domainName);

    const requestOptions = {headers: authHeader(), ...requestOption};
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function getGatewayService(path, data, func, hasDialogProcess = false, domainName, requestOption) {
  let url = Utils._urlRender(path, data, true, window.globalConfig.GATEWAY_DOMAIN);
  const requestOptions = {headers: authHeader(), ...requestOption};
  if (hasDialogProcess) {
      showProgress();
  }
  if (func) {
      return axios.get(url, requestOptions).then(res => {
          if (hasDialogProcess) {
              hideProgress();
          }
          func(res)
      }).catch(handlerError(path , hasDialogProcess));
  } else {
      return axios.get(url, requestOptions).then(res => {
          if (hasDialogProcess) {
              hideProgress();
          }
          return res;
      }).catch(handlerError(path , hasDialogProcess));
  }
}

async function postGatewayService(path, data, func, hasDialogProcess = false, domainName , auth = true) {
  let url = Utils._urlRender(path, null, true, window.globalConfig.GATEWAY_DOMAIN);
  let requestOptions = {
      headers:
          {'Content-Type': 'application/json'}
  }

  if (auth) {
      requestOptions = {
          headers:Object.assign(authHeader() , {'Content-Type': 'application/json'})
      }
  }
  if (hasDialogProcess) {
      showProgress();
  }

  if (func) {
      return axios.post(url, data, requestOptions).then(res => {
          if (hasDialogProcess) {
              hideProgress();
          }
          func(res)
      }).catch(handlerError(path , hasDialogProcess));
  } else {
      return axios.post(url, data, requestOptions).then(res => {
          if (hasDialogProcess) {
              hideProgress();
          }
          return res;
      }).catch(handlerError(path , hasDialogProcess));
  }
};

async function getExpUrl(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, data);
    const requestOptions = {headers: authHeader()};
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function getByUrl(path, data, func, hasDialogProcess = false) {
    let url = _format(path, data);
    const requestOptions = {headers: authHeader()};
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function del(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, data);
    const requestOptions = {headers: authHeader()};
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.delete(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.delete(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function post(path, data, func, hasDialogProcess = false, domainName , auth = true) {
    let url = Utils._urlRender(path, null, true, domainName);
    let requestOptions = {headers:{'Content-Type': 'application/json'}}
    if (auth) {
        requestOptions = {
            headers:Object.assign(authHeader() , {'Content-Type': 'application/json'})
        }
    }
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};



async function put(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, null);
    const requestOptions = {
        headers: Object.assign(authHeader(),
            {'Content-Type': 'application/json'})

    };
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.put(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.put(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function postLogin(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, null);
    let config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.post(url, data, config).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.post(url, data, config).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function postLogout(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, null);
    const requestOptions = {
        headers: Object.assign(authHeader(),
            {'Content-Type': 'application/json'})

    };
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};



function _urlRender(path, data, renderDomain = true, domainName) {
    let tenmien;
    if (domainName) {
      tenmien = domainName;
    } else {
        tenmien = APP_DOMAIN;
    }
    let url = renderDomain ?
        tenmien + (path.indexOf('/') === 0 ? path : ('/' + path))
        :
        (path.indexOf('/') === 0 ? path : ('/' + path));
    if (data) {
        url += "?";
        url = _dataQueryRender(data, url, "").replace(/&$/, "");
    }
    return url;
};

function _dataQueryRender(data, url, prefix) {
    let rUrl = url;
    if (data && data.constructor === Array) {
        for (var key in data) {
            if (data[key] && data[key].constructor === Object) {
                rUrl += _dataQueryRender(data[key], "", (prefix ? prefix + "[" + key + "]" : key));
            } else {
                rUrl += (prefix ? prefix + "[" + key + "]" : key) + "=" + ((data[key] !== undefined && data[key] !== null) ? encodeURI(data[key]) : "") + "&";
            }
        }
    } else if (data) {
        for (var key in data) {
            if (data[key] && data[key].constructor === Object) {
                rUrl += _dataQueryRender(data[key], "", (prefix ? prefix + "." + key : key));
            } else if (data[key] && data[key].constructor === Array) {
                rUrl += _dataQueryRender(data[key], "", (prefix ? prefix + "." + key : key));
            } else {
                rUrl += (prefix ? prefix + "." + key : key) + "=" + ((data[key] !== undefined && data[key] !== null) ? encodeURI(data[key]) : "") + "&";
            }
        }
    }

    return rUrl;
}

function _format(url, data) {
    let result = url;
    for (let k in data) {
        result = result.replace("{" + k + "}", data[k]);
    }
    return result;
}

async function downloadFileByGet(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, data);
    const requestOptions = {
        headers: authHeader(),
        responseType: 'blob'
    };
    if (hasDialogProcess) {
        showProgress();
    }
    if (func) {
        return axios.get(url, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.get(url, requestOptions).then(response => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return response;
        }).catch(handlerError(path , hasDialogProcess));
    }
};


async function downloadFileByPost(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, null);
    const requestOptions = {
        headers: Object.assign(authHeader(),
            {'Content-Type': 'application/json'}),
        responseType: 'blob'

    };
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function postMultipart(path, data, func, hasDialogProcess = false) {
    let url = Utils._urlRender(path, null);
    const requestOptions = {
        headers: Object.assign(authHeader(),{'Content-Type': 'multipart/form-data'})
    };
    if (hasDialogProcess) {
        showProgress();
    }

    if (func) {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            func(res)
        }).catch(handlerError(path , hasDialogProcess));
    } else {
        return axios.post(url, data, requestOptions).then(res => {
            if (hasDialogProcess) {
                hideProgress();
            }
            return res;
        }).catch(handlerError(path , hasDialogProcess));
    }
};

async function postGetAllItem(path) {
    let url = Utils._urlRender(path);
    const requestOptions = {
        headers: {
            'Authorization': 'Basic QVBJLVVTRVI6RlBUQDEyMw=='
        }
    };
    return axios.post(url, null , requestOptions)
}

export const Utils = {
  get,
  getExpUrl,
  getByUrl,
  del,
  post,
  put,
  postLogin,
  postLogout,
  _urlRender,
  _dataQueryRender,
  downloadFileByGet,
  downloadFileByPost,
  postMultipart,
  postGetAllItem,
};
