import { withTranslation } from 'react-i18next';
import { FTUComponent } from '../../../components/FTUComponent';
import React from 'react';
import Datatable, { genNoCol } from 'components/category/Datatable';
import { Column } from 'primereact/column';
import UserInfoService from '../../../services/admin/UserInfoService';
import Constants from '../../../constants/Constants';
import { genColumnDate, genFilterDate } from '../../../components/category/Datatable';
import moment from 'moment/moment';
import { DATE_FORMAT } from '../../../constants/AppProps';

const {
    SERVICE_CODE: { SUCC_CODE },
    DATE_FORMAT_FULL,
} = Constants;

class LoginSession extends FTUComponent {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            rows: 10,
            page: 0,
            data: [],
            totalRecord : 0
        };

        this.userService = new UserInfoService();
    }
    getData = async () => {
        const res = await this.userService.getLogginSession({
            page: this.state.page,
            pageSize: this.state.rows,
        });

        if (res?.data?.code === SUCC_CODE) {
            // const processData = (res.data.data[0].ListSession).map(element => {
            //   element.time = moment(element.time).format(DATE_FORMAT_FULL)
            //   return element
            // })

            this.setState({
                data: res?.data?.data?.ListSession || [],
                totalRecord: res.data.data.totalRecord,
            });
        }
    };
    componentDidMount = () => {
        this.getData();
    };

    onPage = ({ first, rows, page, pageCount }) => {
        this.setState(
            {
                first: first,
                rows: rows,
                page: page,
            },
            async () => {
                await this.getData();
            }
        );
    };

    render = () => {
        return (
            <div className='flex-100 with-filter '>
                <Datatable
                    value={this.state.data}
                    first={this.state.first}
                    rows={this.state.rows}
                    className="login-session vertical"
                    lazy={true}
                    totalRecords={this.state.totalRecord}
                    onPage={this.onPage}>
                    <Column
                        header={this.trans('common:table.STT')}
                        style={{ width: '60px', textAlign: 'center' }}
                        body={(_, e) => {
                            return this.state.first + e.rowIndex + 1;
                        }}
                    />
                    <Column
                        field='time'
                        style={{ width: '100px', textAlign: 'center' }}
                        sortable
                        filterMatchMode='contains'
                        filter={true}
                        header={this.trans('Thời gian đăng nhập')}
                        body={genColumnDate.bind(
                            this,
                            'time',
                            null,
                            DATE_FORMAT_FULL
                        )}
                    />
                    {/* <Column
                        field='type'
                        style={{ width: '100px', textAlign: 'center' }}
                        sortable
                        filterMatchMode='contains'
                        filter={true}
                        header={this.trans('Kiểu phiên đăng nhập')}
                    />
                    <Column
                        field='clientId'
                        style={{ width: '100px', textAlign: 'center' }}
                        sortable
                        filterMatchMode='contains'
                        filter={true}
                        header={this.trans('Client ID')}
                    /> */}
                    <Column
                        field='ipAddress'
                        style={{ width: '150px', textAlign: 'center' }}
                        sortable
                        filterMatchMode='contains'
                        filter={true}
                        header={this.trans('Địa chỉ IP')}
                    />
                </Datatable>
            </div>
        );
    };
}

export default withTranslation(['common'])(LoginSession);
