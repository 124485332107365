export const APP_CODE = "ADMIN";
export const APP_REFRESH_TOKEN_TIME = 300000;
export const TABLE = {
    ROWS: 10,
    SIZE_PER_PAGE: [5, 10, 20, 50, 100]
};
export const ACTION = {
    INSERT: "INSERT",
    UPDATE: "UPDATE",
    VIEW: "VIEW",
    CLONE: "CLONE"
};
export const STATUS = {
    ACTIVE: 1,
    INACTIVE: 0
};
export const SERVICE_CODE = {
    SUCC_CODE: "API000",
    SYSTEM_ERROR: "APP001",
    NOT_FOUND: "APP002",
    DUPLICATE: "APP003",
    LOGIC_ERROR: "AP004",
    NULL_OBJ: "APP100",
    EXIST_CHILD: "APP021",
	MAX_LENGTH: "APP300"
}
export const ENCRYPTION = {
    KEY: "aesEncryptionKey",
    IV: "encryptionIntVec"
}
export const GOOD_BRIGHT_STAR = "8";
export const FORMAT_DATE_PICKER_SIMPLE = [
    'DD/MM/YYYY',
    'DDMMYYYY',
    'D/MM/YYYY',
    'D/M/YYYY',
    'D/M/YY',
    'D/MM/YY',
]
export const FORMAT_DATE_PICKER = ["DD/MM/YYYY HH:mm:ss","DD/MM/YYYY","DDMMYYYY HHmmss","DDMMYYYY HH:mm:ss","D/MM/YYYY", "D/M/YYYY", "D/M/YY", "D/MM/YY", "DD/M/YYYY", "DD/M/YY", "DD/MM/YY"]
export const DATE_FORMAT = {
    DATE_FORMAT: "DD/MM/YYYY",
    JS_FORMAT: "MM-DD-YYYY",
    DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm:ss",
    DATE_TIME_FORMAT_M: "DD/MM/YYYY HH:mm",
    DATE_TIME_FORMAT_HH_MM: "DD/MM/YYYY HH:mm",
    DATE_TIME_FORMAT_HH: "DD/MM/YYYY HH",
    DATE_FORMAT_REV: "YYYY-MM-DD",
    DATE_TIME_FORMAT_REV: "YYYY-MM-DD HH:mm:ss",
    DATE_TIME_FORMAT_HH_NEW: "YYYY-MM-DD HH",
}

export const SERIAL_STATUS = {
    IN_STOCK: "1",
    WAIT: "2",
    SOLD: "3",
}


export const SEC_IP_TAB = {
    INFORMATION_TAB: 0,
    IP_ADDRESS_TAB: 1,
    GRANT_TAB: 2
}

export const SEC_MODULE = {
    MODULE_TYPE: {
        GROUP_MODULE: "1",
        MODULE: "2",
        API: "3",
        ACTION: "4"
    }
}

export const SEC_SYSTEM_POLICY = {
    CHANGE_INFO_CURRENT_USER: "CHANGE_INFO_CURRENT_USER",
    MAX_OPEN_SESSION_USER: "MAX_OPEN_SESSION_USER",
    REQUIRE_STRONG_PASSWORD: "REQUIRE_STRONG_PASSWORD",
    MINIMUM_PASSWORD_LENGTH: "MINIMUM_PASSWORD_LENGTH",
    MAX_LOGIN_FAILURE: "MAX_LOGIN_FAILURE",
    PASSWORD_EXPIRE_DURATION: "PASSWORD_EXPIRE_DURATION",
    AUTHENTICATION_2_FACTOR:"AUTHENTICATION_2_FACTOR",
    HIGH_SECURITY_PASSWORD: "HIGH_SECURITY_PASSWORD",
    DIFFERENT_RECENT_PASSWORD: "DIFFERENT_RECENT_PASSWORD",
    WEB_LOGIN_MANAGEMENT: "WEB_LOGIN_MANAGEMENT",
    EXPIRATION_TIME_LOGIN_SESSION: "EXPIRATION_TIME_LOGIN_SESSION",
    LOCK_TIME_INCORRECT_INPUT: "LOCK_TIME_INCORRECT_INPUT",
    APP_LOGIN_MANAGEMENT: "APP_LOGIN_MANAGEMENT",
    MAX_LOGIN_FAILURE_APP: "MAX_LOGIN_FAILURE_APP",
    MAXIMUM_NUMBER_RE_ENTER_OTP: "MAXIMUM_NUMBER_RE_ENTER_OTP",
    LOCK_TIME_INCORRECT_INPUT_OTP: "LOCK_TIME_INCORRECT_INPUT_OTP",
    WAITING_TIME: "WAITING_TIME",
}

export const CHECK_BOX_STATUS = {
    CHECKED: 1,
    UNCHECKED: 0
}

export const CHECK_BOX_APPLY_TO_SUBORDINATES = {
    CHECKED: 1,
    UNCHECKED: 0
}

export const TEMPLATE_EMAIL_SMS ={
    TYPE_OF_APPDOMAIN:{
        PARAM: "PARAMS_SMS",
        TEMPLATE_CODE: "TEMPLATE_CODE"
    },
    TEMPLATE_TYPE:{
        EMAIL: "Email",
        SMS: "SMS"
    },

}

