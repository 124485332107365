import {Utils} from 'services/common/utils';
import {
    DELETE_APPLICATION,
    INSERT_APPLICATION,
    GET_APPLICATION_lIST_DATA,
    UPDATE_APPLICATION, GET_CHECK_APP,
    GET_AP_DOMAIN_BY_TYPE,
    GET_ALL_API_BY_APP_ID,
    INSERT_APP_API,
    UPDATE_APP_API, GET_APPLICATION_BY_STATUS, GET_APPLICATION_BY_STATUS_MANAGE,
    UPLOAD_FILE, GET_ALL_ITEM
} from "../../constants/AppPath";


export class ApplicationListService {
    getTableData(data) {
        return Utils.get(GET_APPLICATION_lIST_DATA, data, null, true);
    }

    insertApplication(data) {
        return Utils.post(INSERT_APPLICATION, data, null);
    }

    deleteApplication(id) {
        return Utils.del(DELETE_APPLICATION, {"appId": id}, null)
    }

    updateApplication(data) {
        return Utils.put(UPDATE_APPLICATION, data, null);
    }

    getCheckApp(id) {
        return Utils.get(GET_CHECK_APP, {"appId": id}, null);
    }

    getAllMethodAPI() {
        return Utils.get(GET_AP_DOMAIN_BY_TYPE, {"type": "APP_API_METHOD"}, null);
    }

    getAllTypeAPI() {
        return Utils.get(GET_AP_DOMAIN_BY_TYPE, {"type": "APP_API_TYPE"}, null);
    }

    getAllApiByAppId(id) {
        return Utils.get(GET_ALL_API_BY_APP_ID, {"appId": id}, null)
    }

    insertAppAPI(data) {
        return Utils.post(INSERT_APP_API, data, null);
    }

    updateAppAPI(data) {
        return Utils.post(UPDATE_APP_API, data, null);
    }

    // Fix cung status = active
    getAllAppByStatus(data) {
        return Utils.post(GET_APPLICATION_BY_STATUS, data, null);
    }

    getAllAppByStatusManage(data) {
        return Utils.post(GET_APPLICATION_BY_STATUS_MANAGE, data, null);
    }

    uploadFile = (formData) => {
        return Utils.postMultipart(UPLOAD_FILE,
            formData, null, true);
    };

    getAllItem() {
        return Utils.post(GET_ALL_ITEM);
    }

}
