import moment from "moment";

moment.updateLocale('en', {
    week: {dow : 1}
});

export const DateUtils = {
    convertStringToDate,
    convertDateToString,
    changeFormatDateString,
    dateDiff,
    getDayOfWeek,
};

function convertStringToDate (value, format) {
    if(value === null || value === undefined){
        return null;
    }

    return moment(value, format);
}

function convertDateToString (value, format) {
    if(value === null || value === undefined){
        return null;
    }

    return moment(value).format(format);
}

/* 
value 2: giá trị date dạng string,
fromFormart: format hiện tại của value
toFormat: format muốn đổi thành
*/
function changeFormatDateString (value, fromFormart, toFormat) {
    if(value === null || value === undefined){
        return null;
    }
    
    return moment(moment(value, fromFormart)).format(toFormat);
}

/* 
date1 date 2: string,
format: định dạng của date1 và date2 
typeRsult: loại giá trị trả vể, 1 trong các loại sau: days, months, years, hours, minutes, seconds 
isFloat: Lấy cả phần thập phân
Kết quả trả về là date1 - date2
*/
function dateDiff (date1, date2, format, typeRsult, isFloat = false) {
    if(date1 === null || date1 === undefined || date2 === null || date2 === undefined){
        return null;
    }
    
    let valueDate1 = this.convertStringToDate(date1, format);
    let valueDate2 = this.convertStringToDate(date2, format);

    return valueDate1.diff(valueDate2, typeRsult, isFloat);
}

//d: Mon, Tue, Wed, Thu, Fri, Sat, Sun
//weekOfYear: năm trong tuần theo định dạng ww-YYYY
//Trả về ngày dạng chuỗi theo formatDate
function getDayOfWeek (d, weekOfYear, formatDate) {
    let w = String(weekOfYear).split('-')[0];
    let y = String(weekOfYear).split('-')[1];

    let reuslt = moment().year(y).day(d).week(w);

    return convertDateToString (reuslt, formatDate);
}
