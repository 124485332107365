import { ModalBody, ModalFooter, Row } from "reactstrap";
import { Card } from "primereact/card";
import { FTUComponent } from "../../../components/FTUComponent";
import React from "react";
import { Modal, Radio } from "antd";
import { CommonInputText, CommonButton } from "../../../components/common";
import { UserInfoService } from "../../../services/admin/UserInfoService";
import Datatable from "components/category/Datatable";
import { Column } from "primereact/column";
import Constants from "../../../constants/Constants";
import CustomLabel from "../../../components/CustomLabel";
import { withTranslation } from "react-i18next";
import { showSuccessBox } from "../../../components/MessageBox";
import CustomValidTooltip from "../../../components/CustomValidTooltip";
import { stringNullOrEmpty } from "../../../components/category/CommonUtils";
const { SERVICE_CODE } = Constants;

class SearchForm extends FTUComponent {
 constructor(props) {
  super(props);

  this.state = {
   searchForm: {
    searchField: "phone",
    searchString: "",
   },
   searchResult: [],
   requestOTPBy: null,
   selectedLinkedAccount: null,

   page: 0,
   pageSize: 0,
   totalPage: 0,
   totalRecord: 0,

   resendTimeout: 60, // Timeout in seconds
   remainingTime: 60, // Remaining time in seconds
   intervalId: null, // Interval ID for the timer
   resendOTP: false,

   errorMsg: null,
   errors: {},

   isAdmin: null,
  };
  this.UserService = new UserInfoService();
 }

 componentWillUnmount() {
  // Clear the interval when the component is unmounted to avoid memory leaks
  clearInterval(this.state.intervalId);
 }
 componentDidMount() {
  this.setState({ isAdmin: this.props.options.isAdmin });
 }
 onCancel = () => {
  this.props.options.hideDialog();
 };

 genUserTypeCol = (data) => {
  const userTypeStrings = {
   employee: this.trans("employee"),
  };
  return userTypeStrings[data.userType] || "";
 };

 isValid = () => {
  const { searchString } = this.state.searchForm;
  let isValid = true;
  let errors = {};
  if (stringNullOrEmpty(searchString)) {
   errors["searchInput"] = this.trans("common:msg.emptyMessage");
   isValid = false;
  }
  if (Object.keys(errors).length > 0) {
   this.setState({
    errors: errors,
   });
  }
  return isValid;
 };
 onChangeSearchField = (e) => {
  this.setState({
   searchForm: {
    ...this.state.searchForm,
    searchField: e.target.value,
   },
  });
 };
 onChangeInput = (e) => {
  this.setState({
   searchForm: {
    ...this.state.searchForm,
    searchString: e.target.value.trim(),
   },
   errors: "",
   isOTPModalOpen: false,
   otp: "",
  });
 };

 handleOTPChange = (e) => {
  const input = e.target.value;
  //Loại bỏ ký tự không phải là số
  const numericInput = input.replace(/\D/g, "");

  // Nhập tối đa 6 ký tự
  const maxLength = 6;
  const truncatedInput = numericInput.slice(0, maxLength);

  // Lưu vào state
  this.setState({ errorMsg: null, otp: truncatedInput });
 };

 linkAction = (rowData) => {
  // !this.props.disableLink
  return (
   <>
    {true && (<CommonButton
    label={this.trans("common:button:link")}
    disabled={!rowData.status}
    onClick={() => {
     this.handleLink(rowData);
    }}
   ></CommonButton>)}
   </>
  );
 };
 handleSearch = async () => {
  if (!this.isValid()) {
   return;
  }
  const { searchField, searchString } = this.state.searchForm;
  const payload = {
   [searchField]: searchString,
   page: this.state.page,
   totalPage: this.state.totalPage,
   totalRecord: this.state.totalRecord,
   pageSize: this.state.pageSize,
   username: this.props.options.userName
  };

  const radioOptions = ["phone", "idNo", "taxNo", "contractNo"];
  radioOptions.forEach((option) => {
   if (!payload.hasOwnProperty(option)) {
    payload[option] = null;
   }
  });

  const res = await this.UserService.searchUnlinkedAccount(payload);
  if (res && res.data) {
   if (res.data.code === SERVICE_CODE.SUCC_CODE) {
    this.setState({
     searchResult: res.data.data,
     totalRecord: res.data.total,
    });
   } else {
   }
  }
 };

 handleLink = async (rowData) => {

  if (this.props.options.checkDuplicated) {
    const duplicateUser = this.props.options.checkDuplicated(rowData)

    if (duplicateUser) {
      return;
    }
  }

  await this.setState({ selectedLinkedAccount: rowData });
  if (this.props.options.addLinkedAccountToList) {
   this.props.options.addLinkedAccountToList(this.state.selectedLinkedAccount);
  } else if (!this.state.isAdmin) {
    const res = await this.UserService.postRequestOTP({
      userName : rowData?.userName
    });
   if (res && res.data) {
    if (res.data.code === SERVICE_CODE.SUCC_CODE) {
     this.setState({
      requestOTPBy: res.data.data.message,
      isOTPModalOpen: true,
      remainingTime: this.state.resendTimeout,
      intervalId: setInterval(this.updateTimer, 1000),
     });
    } else {
    }
   }
  }
 };

 handleOTPVerification = async () => {

  const { otp, selectedLinkedAccount } = this.state;
  if (!otp) {
   return;
  }
  const payload = {
   ...selectedLinkedAccount,
   otp: otp,
  };

  const res = await this.UserService.verifyOTP(payload);

  if (res && res.data) {
   if (res.data.data === "SUCCESS") {
    this.setState({ isOTPModalOpen: false });
    showSuccessBox(`Liên kết thành công`, null, async () => {
     await this.props.options.trigger();
    //  await this.props.options.hideDialog();
    });
   } else {
    this.setState({ otp: "", errorMsg: `Mã xác thực không đúng.` });
   }
  }
 };

 updateTimer = () => {
  this.setState(
   (prevState) => ({
    remainingTime: prevState.remainingTime - 1,
   }),
   () => {
    if (this.state.remainingTime === 0) {
     clearInterval(this.state.intervalId);
    }
   }
  );
 };

 genComanyLabel = (row) => {
  return row.companyName ? `${row.companyCode} ― ${row.companyName}` : ''
 }

 handleResendOTP = async () => {
  const res = await this.UserService.postRequestOTP({
    userName : this.state.selectedLinkedAccount?.userName
  });
  if (res && res.data) {
   if (res.data.code === SERVICE_CODE.SUCC_CODE) {
    this.setState({
      errorMsg: null,
      remainingTime: this.state.resendTimeout,
      intervalId: setInterval(this.updateTimer, 1000),
    });
   } else {
    this.setState({ errorMsg: `${res.data.data}` });
   }
  }
 };

 onClose = () => {
  clearInterval(this.state.intervalId)
  this.setState({
    totalRecords: 0,
    rows: 10,
    resendTimeout: 60, // Timeout in seconds
    remainingTime: 60, // Remaining time in seconds
    intervalId: null,
    isOTPModalOpen : false,
    otp : ''
  })
}
 render = () => {

  const {
   isOTPModalOpen,
   otp,
   searchForm,
   searchResult,
   totalRecord,
   requestOTPBy,
   remainingTime,
   errorMsg,
   errors,
   resendOTP,
   isAdmin,
  } = this.state;




  return (
   <div id="searchDialog">
    <ModalBody>
     <Card>
      <div id="searchForm" className="col-md-12">
       <div className="row" id="searchField">
        <div className="col-md-2">
         <CustomLabel required>{this.trans("field")}</CustomLabel>
        </div>
        <div className="col-md-10">
         <Radio.Group onChange={this.onChangeSearchField} value={searchForm.searchField}>
          <Radio value="phone">{this.trans("phone")}</Radio>
          <Radio value="idNo">{this.trans("idNo")}</Radio>
          <Radio value="taxNo">{this.trans("taxNo")}</Radio>
          <Radio value="email">{this.trans("Email")}</Radio>
         </Radio.Group>
        </div>
       </div>
       <div className="row" id="searchInput">
        <div className="col-md-2">
         <CustomLabel required>{this.trans("searchInput")}</CustomLabel>
        </div>
        <div className="col-md-10">
         <CommonInputText
          value={searchForm.searchString}
          onChange={this.onChangeInput}
          maxLength="128"
         />
         <CustomValidTooltip>{errors["searchInput"]}</CustomValidTooltip>
        </div>
       </div>
       <Row className="flex-row-reverse" id="searchbutton">
        <div className="- col-md-fixed w-100">
         <CommonButton
          className="col-md-fixed- col-md-fixed w-100"
          label={this.trans("common:app.search")}
          onClick={this.handleSearch}
         ></CommonButton>
        </div>
       </Row>
      </div>
     </Card>
     <div id="resultField" className="row col-md-12">
      <Datatable totalRecords={totalRecord} value={searchResult} selectionMode="single">
       <Column
        field="userName"
        header={this.trans("username")}
        style={{
         height: "2.5em",
         width: "25%",
         textAlign: "left",
        }}
       ></Column>
       <Column
        field="fullName"
        header={this.trans("fullName")}
        style={{
         width: "30%",
         textAlign: "left",
        }}
       ></Column>
       <Column
        field="userType"
        header={this.trans("userType")}
        body={this.genUserTypeCol}
        style={{
         width: "15%",
         textAlign: "left",
        }}
       ></Column>
       <Column
        header={this.trans("companyName")}
        style={{
         height: "2.5em",
         width: "25%",
         textAlign: "left",
        }}
        body={this.genComanyLabel}
       ></Column>
       <Column
        field="status"
        header={this.trans("status")}
        body={this.props.options.genStatusCol}
        style={{
         width: "15%",
         textAlign: "left",
        }}
       ></Column>
       <Column
        header={this.trans("actionUserInfo")}
        body={this.linkAction}
        style={{
         width: "15%",
         textAlign: "left",
        }}
       ></Column>
      </Datatable>
     </div>
     <Modal
      visible={isOTPModalOpen}
      onCancel={this.onClose}
      title={this.trans("EnterOTP")}
      footer={[
       <CommonButton
        label={this.trans("send")}
        type="primary"
        onClick={this.handleOTPVerification}
       />,
      ]}
      zIndex={10001}
     >
      <div
       style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
       }}
      >
       <CustomLabel>
        {this.trans("OTPSentTo")}
        <strong>{requestOTPBy}</strong>
       </CustomLabel>
       <CommonInputText value={otp} onChange={this.handleOTPChange} />
       <div hidden={!errorMsg} style={{ color: "red" }}>
        {errorMsg}
       </div>
       {remainingTime > 0 ? (
        <div>
         <i>{this.trans("OTPResendCode1")}</i>
         <p>{this.trans("OTPResendCode2") + remainingTime + this.trans("OTPResendCode3")}</p>
        </div>
       ) : (
        <div>
          <button
           style={{
            border: "none",
            textDecoration: "underline",
            padding: "0",
            cursor: "pointer",
            background: "none",
           }}
           onClick={this.handleResendOTP}
          >
           {this.trans("resend")}
          </button>
        </div>
       )}
      </div>
     </Modal>
    </ModalBody>
    <ModalFooter>
     <CommonButton
      label={this.trans("common:button:cancel")}
      icon="pi pi-times"
      onClick={this.onCancel}
     />
    </ModalFooter>
   </div>
  );
 };
}
export default withTranslation(["listGroupUserNew", "common"])(SearchForm);
