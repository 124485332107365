import React from "react";
import { Select } from "antd";
import "./CommonSelect.scss";

const CommonSelect = (props) => {
 return (
  <Select
   className="c_select"
   allowClear={true}
   showSearch={true}
   placeholder=""
   {...props}
  />
 );
};

export default CommonSelect;
