import 'antd/dist/antd.css';
import { showErrorBox } from 'components/MessageBox';
import 'components/ftuPrimeReactGrowl/Growl.css';
import { Button } from 'primereact/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { resErrorTable, showConfirm, showMessageBox, showWarningBox } from 'components/MessageBox';
import { showSuccessToaster, showWarningToaster } from './MessageBox';

const _ = require('lodash');

export function FTUTrans(props) {
    const { t } = useTranslation(props.ns);

    if (props.vari) {
        return t(props.name, props.vari);
    } else {
        return t(props.name);
    }
}

export class FTUConfirmMessage extends React.Component {
    render() {
        return (
            <div>
                <span>{this.props.message}</span>
                <br></br>
                <div>
                    <center>
                        <Button label={this.props.labelAccept} onClick={this.props.acceptOnClick} />
                        &nbsp;
                        <Button label={this.props.labelCancel} onClick={this.props.cancelOnClick} />
                    </center>
                </div>
            </div>
        );
    }
}

export class FTUComponent extends React.Component {
    trans(name, vari) {
        let value;
        if (vari) {
            value = this.props.t(name, vari);
        } else {
            value = this.props.t(name);
        }

        return value;
    }

    txtHandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    copyToClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            await showSuccessToaster(this.trans('common:msg.copySuccess'));
        } catch (error) {

            await showWarningToaster(this.trans('common:error.common'));
        }
    };

    checkAPIResponseZeroResult = (response) => {
        try {
            if (Array.isArray(response?.data)) {
                //
                if (response.data.length < 1) {
                    showWarningToaster(this.trans('common:error.zeroResult'));
                    return;
                }
            }
        } catch (error) {

        }
        try {
            if (response.data.data.listResult) {
                //
                if (response.data.data.listResult.length < 1) {
                    showWarningToaster(this.trans('common:error.zeroResult'));
                    return;
                }
            }
        } catch (error) {

        }
        try {
            if (response.data.data.length < 1) {
                showWarningToaster(this.trans('common:error.zeroResult'));
                return;
            }
        } catch (error) {

        }
        try {
            if (response.data.data.groups.length < 1) {
                showWarningToaster(this.trans('common:error.zeroResult'));
                return;
            }
        } catch (error) {

        }
    };

    focusFirstElement = (id) => {
        const element = document.getElementById(id);
        if (!element) {
            return false;
        }
        this.focusEl(element);
    };

    focusInvalidInput = (errors) => {
        const id = Object.keys(errors)[0];
        const element = document.getElementById(id);
        if (element) {
            if (element.tabIndex === -1) {
                this.focusEl(element);
            } else {
                element.focus();
            }
        }
    };

    focusEl = (element) => {
        let listFocus = element.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        listFocus = _.filter(listFocus, (el) => !el.disabled && !el.hidden && el.tabIndex !== -1);
        if (listFocus && listFocus.length) {
            listFocus[0].focus();
        }
    };

    formatInput = (str, filter) => {
        if (filter === 'number') {
            str = str.toString().trim();
            str = str.replace(/[^0-9]/g, ''); //Loại bỏ ký tự không phải số.
            return _.toInteger(str);
        }
        if (filter.includes('alpha')) {
            // Xóa space
            // str = str.replace(/\s+/g, '');
            //Bỏ dấu tiếng Việt.
            str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
            str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
            str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
            str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
            str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
            str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
            str = str.replace(/đ/g, 'd');
            str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
            str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
            str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
            str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
            str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
            str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
            str = str.replace(/Đ/g, 'D');
            // Combining Diacritical Marks
            str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
            str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
            if (filter === 'alpha_number')
                //Bỏ ký tự không phải chữ cái và số.
                str = str.replace(/[^0-9a-z_]/gi, '');
            else if (filter === 'alpha')
                //Bỏ ký tự không phải chữ cái.
                str = str.replace(/[^a-z]/gi, '');
        }
        return str;
    };

    isValid = (formData) => {
        let isValid = true;
        let errors = {};

        Object.keys(formData).forEach((field) => {

            //if (!formData[field]) {
            if (
                formData[field] === undefined ||
                formData[field] === null ||
                formData[field] === ''
            ) {
                isValid = false;
                errors[field] = this.trans('common:msg.emptyMessage');
            }
        });

        if (Object.keys(errors).length > 0) {
            const firstFieldError = document.getElementById(Object.keys(errors)[0]);

            if (typeof firstFieldError?.focus === 'function') {
                firstFieldError.focus();
            }
        }

        return { isValid: isValid, errors: errors };
    };

    onChangeTxtField = (name, e) => {
        try {
            const filter = e.target.getAttribute('filter');
            if (filter) e.target.value = this.formatInput(e.target.value, filter);
            this.onChangeValue(this, name, e.target);
        } catch (error) {

        }
    };
    onChangeTxtFieldPlain = (name, e) => {
        try {
            this.onChangeValue(this, name, e);
        } catch (error) {

        }
    };


    onChangeChkField = (name, e) => {
        this.onChangeValue(this, name, e.checked ? 1 : 0);
    };

    onChangeNumberField = (name, e) => {
        e.target.value = this.formatInput(e.target.value, 'number');
        this.onChangeValue(this, name, e.target);
    };

    onChangeNumber = (name, event) => {
        this.onChangeValue(this, name, event);
    };
    onChangeSelect = (name) => (value) => {
        this.onChangeValue(this, name, value);
    };

    onChangeSelectDetailCustom = (context, value, option, stateProp) => {
        if (option && option.props.ftuitem) {
            context.setState((prevState) => ({
                data: {
                    ...prevState.data,
                    [stateProp]: option.props.ftuitem,
                },
                errors: {
                    ...prevState.errors,
                    [stateProp]: null,
                },
            }));
        }
    };

    onChangeSelectDetail = (name, detail, optionRender, data, value) => {
        this.onChangeValue(this, name, value);
        let detailValue = value;
        if (data) {
            data.forEach((item) => {
                if (item[optionRender.code] === value) {
                    detailValue = item[optionRender.name];
                    return;
                }
            });
        }
        this.onChangeValue(this, detail, detailValue);
    };

    onChangeCheckBox = (name) => (value) => {
        if (value.target.checked) {
            this.onChangeValue(this, name, '1');
        } else {
            this.onChangeValue(this, name, '0');
        }
    };

    async onChangeValue(context, name, value) {
        let realValue = '';
        /*if (value === undefined || value === null || value === "") {
            return;
        }*/
        if (value === undefined || value === null || typeof value.value === 'undefined') {
            if (value === 'null') {
                realValue = '';
            } else {
                realValue = value;
            }
        } else {
            realValue = value.value;
        }

        await context.setState((prevState) => ({
            data: {
                ...prevState.data,
                [name]: realValue,
            },
            errors: {
                ...prevState.errors,
                [name]: '',
            },
        }));
        if (context.props.options) {
            if (context.props.options.dataNew) {
                context.props.options.dataNew = context.state.data;
            }
        }
    }

    onChangeNestedObject = (name, child) => (value) => {
        if (value === 'null') {
            value = '';
        }
        value = { [child]: value };
        this.onChangeValue(this, name, value);
    };

    /**========================================================================
     **                           onChangeDate
     *?  Convert data Date -> kiểu String
     *?  và cập nhật vào state
     *========================================================================**/
    onChangeDate = async (name, value, event) => {
        if (value === undefined || value === '') {
            value = null;
        }
        await this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [name]: value,
            },
            errors: {
                ...prevState.errors,
                [name]: '',
            },
        }));
        if (this.props.options) {
            if (this.props.options.dataNew) {
                this.props.options.dataNew = this.state.data;
            }
        }
    };

    onChangeDateString = async (name, value, valueString) => {
        if (valueString === undefined || valueString === '') {
            valueString = null;
        }
        await this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [name]: valueString,
            },
            errors: {
                ...prevState.errors,
                [name]: '',
            },
        }));
        if (this.props.options) {
            if (this.props.options.dataNew) {
                this.props.options.dataNew = this.state.data;
            }
        }
    };

    filterSelectOption = (input, event) => {
        return (
          String(event.key).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          String(event.props.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      };

    /**========================================================================
     *?  hiển thị dialog báo lỗi
     *========================================================================**/
    commonHandleError = (error) => {
        if (error?.message || error?.data?.message) {
            showErrorBox(error?.message || error?.data?.message);
        }
    };

    showError(ms) {
        console.log("showError ms: ", ms);
        this.growl.show({
            severity: 'error',
            sticky: true,
            summary: <FTUTrans ns='common' name='msg.error' />,
            detail: ms,
        });
    }

    showWarn(ms) {
        this.growl.show({
            severity: 'warn',
            sticky: true,
            summary: <FTUTrans ns='common' name='msg.warning' />,
            detail: ms,
        });
    }

    showSuccess(ms) {
        this.growl.show({
            severity: 'success',
            sticky: true,
            summary: <FTUTrans ns='common' name='msg.message' />,
            detail: ms,
        });
    }

    showInfo(ms) {
        this.growl.show({
            severity: 'info',
            sticky: true,
            summary: <FTUTrans ns='common' name='msg.message' />,
            detail: ms,
        });
    }

    showConfirm(ms, acceptFunc, cacelFunction) {
        let ownAcceptFunc = () => {
            if (acceptFunc) {
                acceptFunc();
            }
            this.growl.clear();
        };
        let ownCancleFunc = () => {
            if (cacelFunction) {
                cacelFunction();
            }
            this.growl.clear();
        };
        let labelCancel = <FTUTrans ns='common' name='msg.btnClose' />;
        let labelAccept = <FTUTrans ns='common' name='msg.btnConfirm' />;
        const detail = (
            <FTUConfirmMessage
                labelAccept={labelAccept}
                labelCancel={labelCancel}
                acceptOnClick={ownAcceptFunc}
                message={ms}
                cancelOnClick={ownCancleFunc}
            />
        );
        this.growl.show({
            closable: false,
            severity: 'info',
            sticky: true,
            detail: detail,
            summary: '',
        });
    }

    getDataFromApDomain = (context, service, type, stateName) => {
        service
            .getApDomainData(type)
            .then(async (res) => {
                await context.setState({
                    [stateName]: res && res.data && res.data.body ? res.data.body : [],
                });
            })
            .catch((e) => {
                console.warn('error loading data');
                this.setState({
                    [stateName]: [],
                });
            });
    };

    setFocus = (input) => {
        this.focus = input;
        if (this.focus) {
            /*this.focus.element.focus()*/
        }
    };

    getSelectedData = (dt, data, key) => {
        let dataSelected = {};
        if (dt && dt.processData() && dt.processData().length) {
            const listData = dt.processData();
            if (data) {
                // TH thêm bản ghi
                const index = _.findIndex(listData, { [key]: data[key] });
                if (index !== -1) {
                    dataSelected = listData[index];
                    dt.state.first = index - (index % dt.state.rows); //set lại trang của table
                } else {
                    dataSelected = listData[0];
                    dt.state.first = 0; //set lại trang của table (nhảy về trang đầu)
                }
            } else {
                // TH xóa + mặc định khi mở form
                dataSelected = listData[0];
                dt.state.first = 0; //set lại trang của table (nhảy về trang đầu)
            }
        } else if (dt) {
            dt.state.first = 0;
        }
        return dataSelected;
    };

    _resolveWaitRedux(func) {
        setTimeout(() => {
            if (this.props.user && this.props.user.username) {
                func();
            } else {
                this._resolveWaitRedux(func);
            }
        }, 200);
    }

    waitRedux() {
        return new Promise((resolve) => {
            this._resolveWaitRedux(resolve);
        });
    }

    wait = (ms) => new Promise((res) => setTimeout(res, ms));

    promisedSetState = (newState) => new Promise((resolve) => this.setState(newState, resolve));
}
