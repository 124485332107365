import {Utils} from 'services/common/utils';
import {
    DELETE_FILE_BY_ID,
    DELETE_LOGO,
    DOWNLOAD_FILE,
    GET_AP_DOMAIN_BY_TYPE,
    GET_COMPANY_LOGO,
    GET_FILE_BY_CONDITION, GET_S3_FILE, GET_S3_IMAGE_LIST, IMPORT_EXCEL_API, PARAM_UPLOAD_FILE,
    SAVE_LOGO,
    UPDATE_LOGO,
    UPLOAD_FILE
} from "../../constants/AppPath";


export class FileService {
    uploadFile = (formData) => {
        return Utils.postMultipart(UPLOAD_FILE,
            formData, null, true);
    };

    getFileByCondition(data) {
        return Utils.post(GET_FILE_BY_CONDITION, data, null);
    }

    downloadFile(data) {
        return Utils.get(DOWNLOAD_FILE, data, null, false, null, {responseType: "blob"});
    }

    downloadS3File(data) {
        return Utils.get(GET_S3_FILE, data, null, false, null);
    }

    getParamUploadFile(data) {
        return Utils.get(PARAM_UPLOAD_FILE, data, null, false, null, null);
    }


    postS3GetFile(payload) {
        return Utils.post(GET_S3_IMAGE_LIST , payload , null , true)
    }

    postExcelFile (payload) {
        return Utils.post(IMPORT_EXCEL_API , payload , null , true)
    }

    onDelete (payload) {
        return Utils.get(DELETE_FILE_BY_ID , payload , null , true)
    }

    onGetCompanyLogo (payload) {
        return Utils.post(GET_COMPANY_LOGO , payload , null , true)
    }

    onUploadLogo (payload  , params = {desc : '' , companyCode : ''  , isMobile : 0}) {
        return Utils.postMultipart(`${SAVE_LOGO}?companyCode=${params?.companyCode}&desc=${params.desc}&isMobile=${params.isMobile}` , payload , null , true)
    }

    onUpdateLogo (payload  , params = {desc : '' , companyCode : ''  , isMobile : 0}) {
        return Utils.postMultipart(`${UPDATE_LOGO}?companyCode=${params?.companyCode}&desc=${params.desc}&isMobile=${params.isMobile}` , payload , null , true)
    }

    onDeleteLogo (payload) {
        return Utils.del(DELETE_LOGO , payload , null , true)
    }
}
