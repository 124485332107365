import {Button} from 'primereact/button';
import React from 'react';
import Constants from 'constants/Constants';

const CommonButton = (props) => {

    let btn = null;

    let listPermissionButton = JSON.parse(localStorage.getItem("permissionBtn") || '[]') || [];
    if (props?.action && !(Array.from(listPermissionButton).includes(Constants.BUTTON + props?.nameScreen + props?.action))) {
        return <div></div>;
    }

    if (props.code) {


        if (Constants.CHECK_PERMISSION_BUTTON === false) {
            btn =
                <Button
                    {...props}
                    className={props.className === undefined ? 'buttonStyle' : props.className}
                >
                    {props.children}
                </Button>
        }
    }

    return <Button {...props} />
};

export default CommonButton;
