import { withTranslation } from 'react-i18next';
import { FTUComponent, FTUTrans } from '../../../components/FTUComponent';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import React, { Component } from 'react';
import className from './SwitchAccount.module.scss';
import { Utils } from '../../../services/common/utils';
import {
    GET_LIST_ACCOUNT_SWITCH,
    POST_CODE_SWITCH_ACCOUNT
} from '../../../constants/AppPath';
import { Comment } from 'antd';
import Constants from '../../../constants/Constants';
import Profile from './Profile';
import { AuthenticationService } from '../../../services/access/AuthenticationService';
import { isNullOrEmpty } from '../../../utils/StringUtils';
import { showErrorBox, showSuccessBox, showWarningBox } from '../../../components/MessageBox';


const { SERVICE_CODE: {
    SUCC_CODE
} } = Constants
class AccountDetailForm extends FTUComponent {
    constructor(props) {
        super(props);
        this.state = {
            onSelect: null,
            data: null,
        };
    }

    // ? mount
    async componentDidMount() {
        await this.getListLinkedAccount();
    }


    async postSwitchCode(code, redirect_url) {
        const payload = {
            code,
            username: this.state.onSelect.userName,
            redirectUrl : redirect_url
        }
        const res = await Utils.post(POST_CODE_SWITCH_ACCOUNT, payload, null, true, null, false);
        if (res.data.code === SUCC_CODE) {
            window.location.replace(`${redirect_url}?code=${res.data.data}`)
        } else {
            this.commonHandleError(res)
        }

    }


    onSubmit = async (e) => {
        if (e.status !== 0) {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');
            const redirect_url = params.get('redirect_url');


            switch (true) {
                case isNullOrEmpty(code) || isNullOrEmpty(redirect_url):
                    showErrorBox(this.trans('common:error.common'));
                    break;
                default:
                    await this.postSwitchCode(code, redirect_url)
                    break;
            }
        }
    };
    async getListLinkedAccount() {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const redirect_url = params.get('redirect_url');

        if (code && redirect_url) {
            const data = await Utils.post(
                GET_LIST_ACCOUNT_SWITCH,
                {
                    code,
                    redirectUrl : redirect_url
                },
                null,
                true,
                false,
                false
            );
            if (data.data && data.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
                await this.setState({
                    onSelect: data.data.data.userADM,
                });
                await this.setState({
                    data: data.data.data,
                });
            }else if (data.data.code === 'APP110')  {
                showErrorBox(
                    data.data.message,
                    <FTUTrans ns='common' name='msg.title' />,
                    () => {
                        window.location.replace(window.location.origin)
                    }
                );
            }else if (redirect_url) {
                showErrorBox(
                    this.trans('common:error.invalidCode'),
                    <FTUTrans ns='common' name='msg.title' />,
                    () => {
                        window.location.replace(redirect_url)
                    }
                );
            }
        }
    }

    onChangeSellectAccount(account) {
        if (account.status !== 0) {
            this.setState({
                onSelect: account,
            });
        }
    }

    onRedirect = () => {
        const params = new URLSearchParams(window.location.search);
        const redirect_url = params.get('redirect_url');

        window.location.replace(redirect_url)
    }

    genNoData() {
        return (
            <div class='ant-empty ant-empty-normal'>
                <div class='ant-empty-image'>
                    <svg
                        class='ant-empty-img-simple'
                        width='64'
                        height='41'
                        viewBox='0 0 64 41'
                        xmlns='http://www.w3.org/2000/svg'>
                        <g
                            transform='translate(0 1)'
                            fill='none'
                            fill-rule='evenodd'>
                            <ellipse
                                class='ant-empty-img-simple-ellipse'
                                cx='32'
                                cy='33'
                                rx='32'
                                ry='7'></ellipse>
                            <g
                                class='ant-empty-img-simple-g'
                                fill-rule='nonzero'>
                                <path d='M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z'></path>
                                <path
                                    d='M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z'
                                    class='ant-empty-img-simple-path'></path>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class='ant-empty-description'>
                    {this.trans('common:data.noDataAccountLink')}
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <ModalHeader>
                    <img
                        alt={'Logo'}
                        src={'/assets/layout/images/logo_dxg.svg'}
                    />
                </ModalHeader>
                <div>
                    <ModalBody className='modal-body'>
                        <div className={className.body}>
                            <div className={className.left}>
                                <Profile
                                    user={this.state.onSelect}
                                    details={true}
                                />
                            </div>
                            <div className={className.right}>
                                <ul className={className.list}>
                                    {this.state.data &&
                                        this.state.onSelect &&
                                        this.state.data.userList.map(
                                            (element) => {
                                                return (
                                                    <li
                                                        onClick={() => this.onChangeSellectAccount(element)
                                                        }
                                                        onDoubleClick={() => this.onSubmit(element)}
                                                        key={element.email}
                                                        className={`${className.item
                                                            } ${this.state.onSelect
                                                                .userName ===
                                                                element.userName
                                                                ? className.selected
                                                                : ''
                                                            }`}>
                                                        <Profile
                                                            user={element}
                                                        />
                                                    </li>
                                                );
                                            }
                                        )}
                                    {this.state.data
                                        ? this.state.data.userList.length === 0
                                            ? this.genNoData()
                                            : ''
                                        : ''}
                                </ul>
                            </div>
                        </div>
                        <div className={className.footer}>
                            <Button onClick={this.onSubmit}>
                                {this.trans('common:button.accept')}
                            </Button>
                            <Button onClick={this.onRedirect}>
                                {this.trans('common:button.destroy')}
                            </Button>
                        </div>
                    </ModalBody>
                </div>
            </>
        );
    }
}
export default withTranslation(['common'])(AccountDetailForm);
