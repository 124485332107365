import {Utils} from 'services/common/utils';
import {
    GET_AP_DOMAIN_BY_TYPE,
    GET_NOTIFY_BY_USER,
    SEEN_NOTIFY
} from "../../constants/AppPath";
import Constants from "../../constants/Constants";

export class NotifyHistoryService {

    getNotifyByUser() {
        return Utils.get(GET_NOTIFY_BY_USER, null, null, true);
    }

    seenNotify(data){
        return Utils.post(SEEN_NOTIFY,data, null, true);
    }

    getNotifyType(){
        return Utils.get(GET_AP_DOMAIN_BY_TYPE, {"type": Constants.NOTIFY_TYPE_AP_DOMAIN}, null);
    }
}
