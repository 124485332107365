import {PATH_GET_MENU, PATH_GET_PERMISSION_BTN} from 'constants/AppPath';
import {GET_COMMON_USER_INFOR} from 'constants/AppPath';
import {APP_CODE} from 'constants/AppProps';
import {Utils} from 'services/common/utils';

export const UserService = {
    getMenu,
    getUserInfor,
    getPermissionButton
};

async function getMenu() {
    let pMenu = await Utils.get(PATH_GET_MENU, {"appCode": APP_CODE}, null);
    if (pMenu && pMenu.data && pMenu.data.length > 0) {
        return pMenu.data[0].items;
    }
    return null;
}

async function getPermissionButton() {
    let result = await Utils.get(PATH_GET_PERMISSION_BTN, {"appCode": APP_CODE}, null);
    return result?.data;
}

async function getUserInfor() {
    let uInfo = await Utils.get(GET_COMMON_USER_INFOR, null, null, true);
    if (uInfo && uInfo.data)
        return uInfo.data;

    return null;
}