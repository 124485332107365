import { Utils } from 'services/common/utils';
import {
    AP_DOMAIN_GET_ALL,
    AP_DOMAIN_INSERT,
    AP_DOMAIN_DETELE,
    AP_DOMAIN_UPDATE,
    AP_DOMAIN_GET_BY_TYPE,
    AP_DOMAIN_GET_TREE,
    AP_DOMAIN_GET_DATA_BY_ID,
    AP_DOMAIN_GET_ALL_AP_DOMAIN,
    DELETE_BY_LIST_IDS,
    GET_VALUE_BY_CODE,
    GET_AP_DOMAIN_BY_TYPE,
} from '../../constants/AppPath';

export class ApDomainService {
    getAll() {
        return Utils.get(AP_DOMAIN_GET_ALL, null, null, true);
    }

    getByType(type) {
        return Utils.get(AP_DOMAIN_GET_BY_TYPE + '?type=' + type, null, null, true);
    }

    async getByCode(type) {
        type = type.toString()
        return Utils.get(GET_VALUE_BY_CODE + '?code=' + type, null, null, true);
    }

    insert(data) {
        return Utils.post(AP_DOMAIN_INSERT, data, null);
    }

    update(data) {
        return Utils.post(AP_DOMAIN_UPDATE, data , null , true);
    }

    delete(data) {
        return Utils.post(AP_DOMAIN_DETELE, data, null);
    }

    getTreeApDomain(data) {
        return Utils.post(AP_DOMAIN_GET_TREE, data, null);
    }

    findApDomainById(data) {
        return Utils.post(AP_DOMAIN_GET_DATA_BY_ID, data, null);
    }

    deleteByListId(data) {
        return Utils.post(DELETE_BY_LIST_IDS, data, null);
    }
}
