import React, { Suspense } from 'react';
import classNames from 'classnames';
import AppTopbar from 'containers/app/AppTopbar';
import { AppFooter } from 'containers/app/AppFooter';
import { AppMenu } from 'containers/app/AppMenu';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'layout/layout.scss';
import './App.scss';
import { FTUComponent } from 'components/FTUComponent';
import { withTranslation } from 'react-i18next';
import { AuthenticationService } from 'services/access/AuthenticationService';
import ContainerRouter from 'routes/ContainerRouter';
import { connect } from 'react-redux';
import { UserService } from '../../services/access/User';
import { BreadCrumb } from 'primereact/breadcrumb';
import { getCompanyLogo, objectNullOrEmpty, stringNullOrEmpty } from '../../components/category/CommonUtils';
import { showConfirm, showWarningBox } from '../../components/MessageBox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';
import IconSVG from './IconSVG';
import AppLeftShowMenu from './AppLeftShowMenu';
import { Utils } from '../../services/common/utils';
import { URL_SIMULATOR } from '../../constants/AppPath';

import Constant from '../../constants/Constants';
import UserInfoService from '../../services/admin/UserInfoService';
import _ from 'lodash';
import { Row } from 'reactstrap';
import { Tooltip } from 'antd';

const {
  SERVICE_CODE: { SUCC_CODE },
} = Constant;class App extends FTUComponent {
  constructor(props) {
    super(props);
    this.state = {
      //layoutMode: 'static',
      layoutMode: 'overlay',
      //layoutColorMode: 'dark',
      layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      appLgn: 'vi',
      menu: null,
      breadCrumb: null,
      tMenu: [],
      indexTMenu: 0,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.removeTicketFromUrl();
    pushHistory = pushHistory.bind(this);
  }

  logout = (e) => {
    AuthenticationService.logOutAll();
    if (this._isMounted) {
      this.setState({
        redirect: true,
      });
    }
  };

  changePassword = (e) => {
    window.open('http://10.14.121.6:7180/auth/realms/customer/account/password', '_blank').focus();
  };

  removeTicketFromUrl() {
    let url = new URL(window.location.href);
    let urlReturn = url.href;
    if (url.search.includes('?ticket=')) {
      let index = url.search.indexOf('?ticket=');
      let valueReplace = url.search.slice(index, url.search.length);
      urlReturn = url.href.replaceAll(valueReplace, '');
      window.open(urlReturn, '_self');
    }
    if (url.search.includes('&ticket=')) {
      let index = url.search.indexOf('&ticket=');
      let valueReplace = url.search.slice(index, url.search.length);
      urlReturn = url.href.replaceAll(valueReplace, '');
      window.open(urlReturn, '_self');
    }
  }

  componentDidMount = async () => {
    
    await this.createMenu();
    const pathName = this.props.location ? this.props.location.pathname : '';
    await AuthenticationService.logAccessPath(pathName);
    document.title = this.trans('app.titleAdmin');
    AuthenticationService.restoreUserInfo();
    if (pathName && pathName !== '/admin/') {
      await this.getBreadCrumb();
    }

  };

  changeAccount = (data) => {
    AuthenticationService.switchAccount(data, false);
  };

  getBreadCrumb = async () => {
    const search = this.props.location.search;
    let params = {};
    search
      .substr(1)
      .split('&')
      .map((param) => {
        const [key, value] = param.split('=');
        params[key] = value;
      });
    this.state.tMenu = [];
    this.breadCrumbs = null;
    if (stringNullOrEmpty(params.urlPathId)) {
      //Redirect to notfound page.
      this.state.breadCrumb = null;
      await showWarningBox(this.trans('common:msg.pageNotFound'));
      this.props.history.push('/admin/');
    } else {
      const res = await AuthenticationService.getBreadcrumb({
        path:   this.props.location.pathname.replace("/admin", ""),
        urlPathId: params.urlPathId,
      });
      if (res && res.data && res.data.data && res.data.data.length > 1) {
        this.breadCrumbs = res.data.data.slice(1).map((item) => item.moduleId);
        if (!objectNullOrEmpty(this.state.menu)) {
          this.genTMenu(this.state.menu);
        }
        const breadCrumb = res.data.data.slice(1).map((item, index) => ({
          label: item.moduleName,
          command: async (e) => {
            const event = e.originalEvent || e;
            if (event) {
              if (this.op) {
                // this.op.show(e, target);
                this.op.toggle(event);
                // this.op.show(e);
              }
              this.setState({ indexTMenu: index });
            }
          },
        }));
        breadCrumb[breadCrumb.length - 1].className = 'breadcrumbActive';
        breadCrumb[breadCrumb.length - 1].command = undefined;
        this.setState({ breadCrumb: breadCrumb });
        document.title = 'ADM - ' + breadCrumb[breadCrumb.length - 1].label;
      } else {
        //Redirect to notfound page.
        this.state.breadCrumb = null;
        // 26-10 disable show
        // await showWarningBox(this.trans("common:msg.pageNotFound"));
        this.props.history.push('/admin/');
      }
    }
  };

  genTMenu = (menu) => {
    const _ = require('lodash');
    if (this.breadCrumbs && menu && menu.length > 0) {
      let tMenu = [];
      for (const id of this.breadCrumbs) {
        if (tMenu.length) {
          tMenu.push(_.cloneDeep(tMenu[tMenu.length - 1].items.find((item) => item.id === id)));
        } else {
          tMenu.push(_.cloneDeep(menu.find((item) => item.id === id)));
        }
      }
      this.setState({ tMenu });
    }
  };

  onWrapperClick(event) {
    if (!this.menuClick && (this.state.overlayMenuActive || this.state.mobileMenuActive)) {
      //HaiLQ3: ghi log truy cập chức năng
      // if (event.item && event.item.id) {
      //     AuthenticationService.logAccess(event.item.id);
      // }
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;
    if (this.isDesktop()) {
      if (this.state.layoutMode === 'overlay') {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === 'static') {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }
    // if (this.state.layoutMode === "overlay") {
    //  this.setState({
    //   overlayMenuActive: !this.state.overlayMenuActive,
    //  });
    // } else if (this.state.layoutMode === "static") {
    //  this.setState({
    //   staticMenuInactive: !this.state.staticMenuInactive,
    //  });
    // }
    // const mobileMenuActive = this.state.mobileMenuActive;
    // this.setState({
    //  mobileMenuActive: !mobileMenuActive,
    // });
    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items || !event.item.items.length) {
      //HaiLQ3: ghi log truy cập chức năng
      //AuthenticationService.logAccess(event.item.id);
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  async createMenu() {
    //lay thong tin user
    // let uInfor = await UserService.getUserInfor();
    // if (uInfor && uInfor.body) {
    //     this.props.dispatch(
    //         fetchUser(uInfor.body)
    //     );
    // }
    //lay menu
    let menu = await UserService.getMenu();
    this.genTMenu(menu);
    await this.setState({
      menu: menu,
    });
    
    localStorage.setItem('currentMenu', JSON.stringify(menu));
    let listPermissionButton = await UserService.getPermissionButton();
    localStorage.setItem('permissionBtn', JSON.stringify(listPermissionButton));
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.location &&
      this.props.location &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({ breadCrumb: null });
      if (this.props.location.pathname && this.props.location.pathname !== '/admin/') {
        this.getBreadCrumb();
      }
      AuthenticationService.logAccessPath(this.props.location.pathname);
    }
    if (this.state.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  }

  changeLanguage = () => {
    const { i18n } = this.props;
    if (this.state.appLgn === 'vi') {
      i18n.changeLanguage('en');
      this.setState({
        appLgn: 'en',
      });
    } else {
      i18n.changeLanguage('vi');
      this.setState({
        appLgn: 'vi',
      });
    }
  };

  render() {
    let userStorage = JSON.parse(localStorage?.getItem('userBasicInfo'));
    let logoSrc = userStorage?.jobTitles?.logoCompany
    let logoSrcFallback = '/assets/layout/images/logo_dxg.svg';
    const logo =
      (this.state.layoutColorMode === 'dark'
        ? '/assets/layout/images/logo_dxg.svg'
        : '/assets/layout/images/logo_dxg.svg');
    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive':
        this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active':
        this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive,
    });
    const sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
      'layout-sidebar-light': this.state.layoutColorMode === 'light',
    });
    const home = {
      icon: 'pi pi-home',
      command: (event) => {
        this.props.history.push('/admin/');
      },
    };
    const tMenu =
      this.state.tMenu && !stringNullOrEmpty(this.state.indexTMenu)
        ? this.state.tMenu[this.state.indexTMenu]
        : null;

    let documentLink = '';
    let documentId = '';
    try {
      let documentItem = this.state?.menu?.find((item) => _.includes(item.to, 'documents'));
      documentLink = documentItem.to.substring(1);
      // 
      // 
      // 
      documentId = String(documentItem.id);
      // 
    } catch (error) {}

    var regex = new RegExp(/(?![\?urlPathId])\w+/);
    let moduleId = regex.exec(this.props.location.search);
    // 
    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <AppTopbar
          onChangeLgn={this.changeLanguage}
          path="/" 
          appLgn={this.state.appLgn === 'vi' ? 'en' : 'vi'}
          onToggleMenu={this.onToggleMenu}
        />

        <div className='flex-100-row flex-row align-items-start w-100'>
          {this.state.menu ? (
            <>
              <AppLeftShowMenu
                logout={this.logout}
                className={''}
                items={this.state.menu}
                onToggleMenu={this.onToggleMenu}
                logoutText={this.trans('common:app.logout')}
              />
            </>
          ) : (
            <div
              style={{
                margin: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {/*<ProgressSpinner animationDuration={"0.5s"}/>*/}
            </div>
          )}
          <div className='layout-wrapper-content'>
            <div
              ref={(el) => (this.sidebar = el)}
              className={sidebarClassName}
              onClick={this.onSidebarClick}
            >
              <div>
                <div className='layout-logo'>
                          <img 
                            src={logoSrc}
                            className='small-company-logo'
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=logoSrcFallback;
                            }}
                        />
                  <button className='p-link layout-menu-button' onClick={this.onToggleMenu}>
                    <span>{IconSVG.LeftArrow}</span>
                  </button>
                </div>

                {/*<AppProfile />*/}
                {this.state.menu ? (
                  <>
                    <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick} />
                  </>
                ) : (
                  <div
                    style={{
                      margin: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {/*<ProgressSpinner animationDuration={"0.5s"}/>*/}
                  </div>
                )}
              </div>
              {/* <div onClick={this.changePassword} className={'layout-sidebar-logout'}>*/}
              {/*    <div>*/}
              {/*        <span>*/}
              {/*            <svg width="17" height="18" viewBox="0 0 17 18" fill="none"*/}
              {/*                 xmlns="http://www.w3.org/2000/svg">*/}
              {/*                <path*/}
              {/*                    d="M11.8485 13.8542C11.728 14.5853 11.1152 15.1298 10.3719 15.1399C10.2362 15.1399 10.1006 15.1449 9.95999 15.1449C9.7189 15.1449 8.89016 15.1449 8.65409 15.1449C8.2573 15.1449 3.89875 15.1399 3.50195 15.1399C3.50195 11.0409 3.50195 6.94182 3.50195 2.84277C3.72797 2.84277 7.90571 2.84782 8.13173 2.84782C8.56871 2.84782 9.59333 2.86294 10.0303 2.84782C10.4271 2.83773 10.8088 2.88311 11.1654 3.0747C11.291 3.18562 11.4166 3.30158 11.5421 3.41251C11.7882 3.75031 11.8887 4.12845 11.8787 4.54189C11.8686 4.93515 11.8837 13.6374 11.8485 13.8542Z"*/}
              {/*                    fill="#FFA39E"/>*/}
              {/*                <path*/}
              {/*                    d="M7.46428 16.3455C7.4241 16.9757 7.09762 17.3034 6.47983 17.3337C5.85702 17.1622 5.25932 16.9152 4.65157 16.7135C4.58628 16.6933 4.52098 16.6782 4.45569 16.658C4.28994 16.5723 4.10912 16.5219 3.9283 16.4665C3.27033 16.2244 2.61236 15.9824 1.93932 15.7757C1.77357 15.69 1.59275 15.6396 1.41696 15.5892C1.13066 15.4783 0.849393 15.3673 0.623372 15.1455C0.367214 14.8934 0.241647 14.5808 0.176352 14.2379C0.171329 14.001 0.171329 13.759 0.166307 13.522C0.166307 12.6296 0.166307 11.7372 0.166307 10.8498C0.166307 10.6128 0.166307 10.3708 0.166307 10.1339C0.166307 9.24145 0.166307 8.34904 0.166307 7.46167C0.166307 7.2247 0.166307 6.98269 0.166307 6.74572C0.166307 5.85331 0.166307 4.9609 0.166307 4.07353C0.161284 3.77102 0.221556 3.49371 0.327033 3.22649C0.357169 3.21137 0.367214 3.18112 0.372237 3.15087C0.512872 2.94415 0.688667 2.76768 0.889574 2.62147C0.919711 2.62147 0.944824 2.61139 0.959892 2.58113C1.33659 2.445 1.7133 2.30887 2.08498 2.17274C3.03929 1.83998 3.9936 1.51226 4.94791 1.17949C5.35977 1.03328 5.76661 0.882023 6.17847 0.730767C6.41454 0.655139 6.65563 0.634971 6.89169 0.735809C7.27844 0.912274 7.45926 1.21479 7.45926 1.6383C7.45424 2.00132 7.46428 2.36433 7.46428 2.72735C7.46428 2.76264 7.46428 2.80298 7.46428 2.83827C7.46428 6.93731 7.46428 11.0364 7.46428 15.1354C7.46428 15.5438 7.46428 15.9421 7.46428 16.3455ZM5.47027 8.98431C5.47027 9.41287 5.47027 9.83639 5.47027 10.265C5.47027 10.4818 5.58077 10.6128 5.75154 10.6128C5.91729 10.6128 6.06797 10.4515 6.06797 10.2599C6.06797 9.41287 6.06797 8.56584 6.06797 7.71376C6.06797 7.53226 5.90725 7.36587 5.76159 7.37091C5.57575 7.37596 5.47529 7.502 5.47529 7.71376C5.46525 8.14232 5.47027 8.56584 5.47027 8.98431Z"*/}
              {/*                    fill="url(#paint0_linear_667_2843)"/>*/}
              {/*                <path*/}
              {/*                    d="M5.46987 8.98429C5.46987 8.56582 5.46987 8.1423 5.46987 7.72382C5.46987 7.50702 5.57539 7.38602 5.75627 7.38097C5.90701 7.37593 6.06277 7.53727 6.06277 7.72382C6.0678 8.57086 6.0678 9.41789 6.06277 10.27C6.06277 10.4616 5.91203 10.6229 5.74622 10.6229C5.57539 10.6229 5.46484 10.4868 5.46484 10.275C5.46484 9.84141 5.46987 9.41285 5.46987 8.98429Z"*/}
              {/*                    fill="white"/>*/}
              {/*                <path*/}
              {/*                    d="M16.7878 9.07165L13.7876 6.01125C13.7447 5.96769 13.6809 5.9551 13.6242 5.97811C13.5682 6.00205 13.5319 6.05789 13.5319 6.11986V7.8073H9.78466C9.70192 7.80731 9.63477 7.87603 9.63477 7.9607V10.4152C9.63477 10.4998 9.70192 10.5686 9.78466 10.5686H13.5319V12.2496C13.5319 12.3115 13.5685 12.3674 13.6245 12.3913C13.6431 12.3993 13.6626 12.403 13.6818 12.403C13.7208 12.403 13.7591 12.3873 13.7879 12.3582L16.7881 9.28887C16.8163 9.26004 16.8319 9.22106 16.8319 9.18026C16.8318 9.13946 16.816 9.1005 16.7878 9.07165Z"*/}
              {/*                    fill="#FF5F52"/>*/}
              {/*                <defs>*/}
              {/*                    <linearGradient id="paint0_linear_667_2843" x1="10.7723" y1="8.99617"*/}
              {/*                                    x2="-0.21278" y2="8.99617" gradientUnits="userSpaceOnUse">*/}
              {/*                        <stop stopColor="#FFA39B"/>*/}
              {/*                        <stop offset="1" stopColor="#FF1B09"/>*/}
              {/*                    </linearGradient>*/}
              {/*                </defs>*/}
              {/*            </svg>*/}
              {/*        </span>*/}
              {/*        <span>*/}
              {/*            {"Đổi mật khẩu"}*/}
              {/*        </span>*/}

              {/*    </div>*/}
              {/*</div> */}

              <div onClick={this.logout} className={'layout-sidebar-logout'}>
                <div>
                  <span>
                    <svg
                      width='17'
                      height='18'
                      viewBox='0 0 17 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11.8485 13.8542C11.728 14.5853 11.1152 15.1298 10.3719 15.1399C10.2362 15.1399 10.1006 15.1449 9.95999 15.1449C9.7189 15.1449 8.89016 15.1449 8.65409 15.1449C8.2573 15.1449 3.89875 15.1399 3.50195 15.1399C3.50195 11.0409 3.50195 6.94182 3.50195 2.84277C3.72797 2.84277 7.90571 2.84782 8.13173 2.84782C8.56871 2.84782 9.59333 2.86294 10.0303 2.84782C10.4271 2.83773 10.8088 2.88311 11.1654 3.0747C11.291 3.18562 11.4166 3.30158 11.5421 3.41251C11.7882 3.75031 11.8887 4.12845 11.8787 4.54189C11.8686 4.93515 11.8837 13.6374 11.8485 13.8542Z'
                        fill='#FFA39E'
                      />
                      <path
                        d='M7.46428 16.3455C7.4241 16.9757 7.09762 17.3034 6.47983 17.3337C5.85702 17.1622 5.25932 16.9152 4.65157 16.7135C4.58628 16.6933 4.52098 16.6782 4.45569 16.658C4.28994 16.5723 4.10912 16.5219 3.9283 16.4665C3.27033 16.2244 2.61236 15.9824 1.93932 15.7757C1.77357 15.69 1.59275 15.6396 1.41696 15.5892C1.13066 15.4783 0.849393 15.3673 0.623372 15.1455C0.367214 14.8934 0.241647 14.5808 0.176352 14.2379C0.171329 14.001 0.171329 13.759 0.166307 13.522C0.166307 12.6296 0.166307 11.7372 0.166307 10.8498C0.166307 10.6128 0.166307 10.3708 0.166307 10.1339C0.166307 9.24145 0.166307 8.34904 0.166307 7.46167C0.166307 7.2247 0.166307 6.98269 0.166307 6.74572C0.166307 5.85331 0.166307 4.9609 0.166307 4.07353C0.161284 3.77102 0.221556 3.49371 0.327033 3.22649C0.357169 3.21137 0.367214 3.18112 0.372237 3.15087C0.512872 2.94415 0.688667 2.76768 0.889574 2.62147C0.919711 2.62147 0.944824 2.61139 0.959892 2.58113C1.33659 2.445 1.7133 2.30887 2.08498 2.17274C3.03929 1.83998 3.9936 1.51226 4.94791 1.17949C5.35977 1.03328 5.76661 0.882023 6.17847 0.730767C6.41454 0.655139 6.65563 0.634971 6.89169 0.735809C7.27844 0.912274 7.45926 1.21479 7.45926 1.6383C7.45424 2.00132 7.46428 2.36433 7.46428 2.72735C7.46428 2.76264 7.46428 2.80298 7.46428 2.83827C7.46428 6.93731 7.46428 11.0364 7.46428 15.1354C7.46428 15.5438 7.46428 15.9421 7.46428 16.3455ZM5.47027 8.98431C5.47027 9.41287 5.47027 9.83639 5.47027 10.265C5.47027 10.4818 5.58077 10.6128 5.75154 10.6128C5.91729 10.6128 6.06797 10.4515 6.06797 10.2599C6.06797 9.41287 6.06797 8.56584 6.06797 7.71376C6.06797 7.53226 5.90725 7.36587 5.76159 7.37091C5.57575 7.37596 5.47529 7.502 5.47529 7.71376C5.46525 8.14232 5.47027 8.56584 5.47027 8.98431Z'
                        fill='url(#paint0_linear_667_2843)'
                      />
                      <path
                        d='M5.46987 8.98429C5.46987 8.56582 5.46987 8.1423 5.46987 7.72382C5.46987 7.50702 5.57539 7.38602 5.75627 7.38097C5.90701 7.37593 6.06277 7.53727 6.06277 7.72382C6.0678 8.57086 6.0678 9.41789 6.06277 10.27C6.06277 10.4616 5.91203 10.6229 5.74622 10.6229C5.57539 10.6229 5.46484 10.4868 5.46484 10.275C5.46484 9.84141 5.46987 9.41285 5.46987 8.98429Z'
                        fill='white'
                      />
                      <path
                        d='M16.7878 9.07165L13.7876 6.01125C13.7447 5.96769 13.6809 5.9551 13.6242 5.97811C13.5682 6.00205 13.5319 6.05789 13.5319 6.11986V7.8073H9.78466C9.70192 7.80731 9.63477 7.87603 9.63477 7.9607V10.4152C9.63477 10.4998 9.70192 10.5686 9.78466 10.5686H13.5319V12.2496C13.5319 12.3115 13.5685 12.3674 13.6245 12.3913C13.6431 12.3993 13.6626 12.403 13.6818 12.403C13.7208 12.403 13.7591 12.3873 13.7879 12.3582L16.7881 9.28887C16.8163 9.26004 16.8319 9.22106 16.8319 9.18026C16.8318 9.13946 16.816 9.1005 16.7878 9.07165Z'
                        fill='#FF5F52'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_667_2843'
                          x1='10.7723'
                          y1='8.99617'
                          x2='-0.21278'
                          y2='8.99617'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stopColor='#FFA39B' />
                          <stop offset='1' stopColor='#FF1B09' />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span>{this.trans('common:app.logout')}</span>
                </div>
              </div>
            </div>

            <div className='layout-main'>
              <Suspense fallback={'Đang tải dữ liệu...'}>
                {/*<AppBreadcrumb appRoutes={routes}/>*/}
                {this.props.location.pathname !== '/admin/' ? (
                  <Row className='breadcrumb-wrapper'>
                    <BreadCrumb
                      className={'breadcrumb center-row-y'}
                      model={this.state.breadCrumb}
                      home={home}
                    ></BreadCrumb>
                    {/* {moduleId[0] !== documentId ? ( */}
                    <div className='d-flex p-0 text-left flex-grow-1 flex-row-reverse m-auto'>
                      <Tooltip
                        placement='leftBottom'
                        title={this.trans(`common:msg.toolTipDocument`)}
                      >
                        <a
                          className='center-row-y center-row-pi'
                          href={"/admin/" + documentLink + `&moduleId=` + moduleId[0]}
                        >
                          {IconSVG.QuestionMark}
                        </a>
                      </Tooltip>
                    </div>
                    {/* ) : (
                                    <></>
                                )} */}
                  </Row>
                ) : (
                  <></>
                )}
                <OverlayPanel ref={(el) => (this.op = el)} id='overlay_panel' className={'tMenu'}>
                  {tMenu ? (
                    <>
                      <AppMenu model={tMenu.items} onMenuItemClick={this.onMenuItemClick} />
                    </>
                  ) : (
                    <div
                      style={{
                        margin: '20px',
                        flexDirection: 'column',
                        display: 'flex',
                      }}
                    >
                      <p>Loading...</p>
                      <ProgressSpinner
                        animationDuration={'0.5s'}
                        style={{ width: '40px', height: '40px' }}
                      />
                    </div>
                  )}
                </OverlayPanel>
                <div className='layout-content'>
                  <ContainerRouter />
                </div>

              </Suspense>
              <AppFooter text={this.trans('common:app.footer')} />
            </div>
          </div>
        </div>

        <div className='layout-mask'></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
  example: state.example.exampleInfo,
});

export default withTranslation('common')(connect(mapStateToProps)(App));

export function pushHistory(path) {
  this.props.history.push(path);
}
